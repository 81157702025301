<template>
	<mdb-modal v-if="hasErrors" :show="showErrorPopup" @close="HIDE_ERROR_POPUP" full-height position="top" size="sm" color="danger">
		<mdb-modal-body>
			<error-render v-for="(error, index) in errors" :key="index" :error="error" @delete="FLUSH_ERROR(index)"></error-render>
		</mdb-modal-body>
	</mdb-modal>
</template>

<script>
import { mdbModal, mdbModalBody } from 'mdbvue'
import { mapState, mapMutations } from 'vuex'

import ErrorRender from './ErrorRender.vue'

export default {
	name: 'TheErrors',
	components: { mdbModal, mdbModalBody, ErrorRender },
	computed: {
		hasErrors () {
			return this.errors.length > 0
		},
		...mapState([ 'errors', 'showErrorPopup' ])
	},
	methods: {
		...mapMutations([ 'FLUSH_ERROR', 'HIDE_ERROR_POPUP' ])
	}
}
</script>

<style scoped>

</style>
