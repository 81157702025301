import { api } from '@/helpers/core'

export default {
	getBindings () {
		return api.get('/settings/bindings/')
	},
	updateBinding (binding) {
		return api.post('/settings/bindings/', binding)
	},
	deleteBinding (binding) {
		return api.delete('/settings/bindings/', { data: binding })
	},
	beginBindingTransaction () {
		return api.post('/settings/bindings/begin')
	},
	commitBindingTransaction ({ rootId, rootPath }) {
		return api.post('/settings/bindings/commit', { rootId, path: rootPath })
	},
	rollbackBindingTransaction ({ rootId, rootPath }) {
		return api.post('/settings/bindings/rollback', { rootId, path: rootPath })
	},
	getMetaBindings () {
		return api.get('/settings/bindings/meta')
	},
	updateMetaBinding (binding) {
		return api.post('/settings/bindings/meta', binding)
	},
	deleteMetaBinding (binding) {
		return api.delete('/settings/bindings/meta', { data: binding })
	}
}
