<template>
	<mdb-row class="property-wrapper bg-primary">
		<mdb-col col="12" class="property">
			<span class="align-middle">{{ property.name }}</span>
		</mdb-col>
	</mdb-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'PropertyRender',
	props: {
		property: {
			type: Object,
			required: true
		}
	},
	computed: {
		color () {
			return (this.entityDefinitions[this.property.definitionName] || {}).color || 'black'
		},
		style () {
			return { 'background-color': this.color }
		},
		...mapState('schema', [ 'entityDefinitions' ])
	}
}
</script>

<style scoped>
.property-wrapper {
	/* background-color: #2c3e50; */
	cursor: move;
}
.property i {
	cursor: pointer;
}
.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}
</style>
