<template>
  <svg v-if="svgIcon" :x="x - nodeWidth / 2" :y="y - nodeHeight / 2"
       :viewBox="svgIcon.attrs.viewBox" :width="nodeWidth" :height="nodeHeight"
       :class="nodeClass(['node-svg'])" :style="nodeStyle" :stroke="_color" v-html="svgIcon.data"
       @mousedown.stop="$emit('dragstart', $event)" @touchstart.stop="$emit('dragstart', $event)"
       @contextmenu.prevent="$emit('menu')">
    <title>{{ name }}</title>
  </svg>
  <circle v-else :cx="x" :cy="y" :r="nodeSize / 2"
          :class="nodeClass()" :style="nodeStyle" :stroke="_color"
          @mousedown.stop="$emit('dragstart', $event)" @touchstart.stop="$emit('dragstart', $event)"
          @contextmenu.prevent="$emit('menu')">
    <title>{{ name }}</title>
  </circle>
</template>
<script>
import svgExport from "@/helpers/svgExport";
import {mapGetters, mapState} from "vuex";
import * as icons from "@/helpers/icons";

export default {
  name: 'GraphNode',
  props: {
    id: [Number, String],
    name: String,
    _cssClass: [String, Array],
    _color: String,
    _size: Number,
    _width: Number,
    _height: Number,
    hostId: Number,
    typeId: Number,
    entitySize: Number,
    hubSize: Number,
    nodeSym: String,
    x: Number,
    y: Number
  },
  computed: {
    svgIcon () {
      let definition = null
      let icon = null
      if (!this.hostId) {
        definition = this.usedDefinitions[this.typeId]
      }
      if (definition) {
        switch (definition.name) {
          case 'phone':
            icon = icons.phone
            break
          case 'bank_card':
          case 'card':
          case 'credit_card':
            icon = icons.card
            break
          case 'wallet':
            icon = icons.wallet
            break
          case 'email':
            icon = icons.email
            break
          case 'method':
            icon = icons.merchant
            break
          case 'ip':
            icon = icons.ip
            break
        }
      }
      return icon || this.nodeSvg
    },
    nodeStyle () {
      return (this._color) ? 'fill: ' + this._color : ''
    },
    nodeSvgEl () {
      return svgExport.svgElFromString(this.nodeSym)
    },
    nodeSvg () {
      return svgExport.toObject(this.nodeSvgEl)
    },
    nodeWidth () {
      let size = this._width || this._size
      return size || (this.hostId ? this.hubSize : this.entitySize)
    },
    nodeHeight () {
      let size = this._height || this._size
      return size || (this.hostId ? this.hubSize : this.entitySize)
    },
    nodeSize() {
      let size = this._size
      return size || (this.hostId ? this.hubSize : this.entitySize)
    },
    ...mapState('graph', [ 'opened' ]),
    ...mapGetters('graph', [ 'usedDefinitions' ])
  },
  methods: {
    nodeClass (classes = []) {
      let cssClass = this._cssClass || []
      if (!Array.isArray(cssClass)) cssClass = [cssClass]
      cssClass.push('node')
      classes.forEach(c => cssClass.push(c))
      if (this.opened[this.id]) cssClass.push('selected')
      if (this.fx || this.fy) cssClass.push('pinned')
      return cssClass
    }
  }
}
</script>
