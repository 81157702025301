import Vue from 'vue'

import call from '@/helpers/call'

import { _call, fromEntries } from '@/helpers/javascript'

// initial state
const state = {
	rules: {},
	rulesets: {},
	fields: {},
	rulesetName: null
}

// getters
const getters = {}

// actions
const actions = {
	fetchRules ({ commit }, { after } = {}) {
		call('rules', 'getRules', {
			resolve (data) {
				commit('SET_RULES', data)
				_call(after)
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		})
	},

	updateRule ({ commit }, rule) {
		call('rules', 'updateRule', {
			resolve (data) {
				commit('SET_RULE', data)
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, rule)
	},

	deleteRule ({ commit, dispatch }, rule) {
		call('rules', 'deleteRule', {
			resolve () {
				commit('DELETE_RULE', rule)
				dispatch('fetchRulesets')
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, rule)
	},

	fetchRulesets ({ commit }, { after } = {}) {
		call('rules', 'getRulesets', {
			resolve (data) {
				commit('SET_RULESETS', data)
				_call(after)
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		})
	},

	createRuleset ({ commit }, { ruleset, after }) {
		call('rules', 'createRuleset', {
			resolve (data) {
				commit('SET_RULESET', data)
				_call(after, data)
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, ruleset)
	},

	deleteRuleset ({ commit }, ruleset) {
		call('rules', 'deleteRuleset', {
			resolve () {
				commit('DELETE_RULESET', ruleset)
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, ruleset)
	},

	includeRule ({ commit }, { rulesetName, name }) {
		call('rules', 'include', {
			resolve (data) {
				commit('SET_RULESET_RULES', { rulesetName, rules: data })
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, { rulesetName, name })
	},

	replaceRule ({ commit }, { rulesetName, name, value }) {
		call('rules', 'replace', {
			resolve (data) {
				commit('SET_RULESET_RULES', { rulesetName, rules: data })
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, { rulesetName, name, value })
	},

	fetchFields ({ commit }) {
		call('rules', 'getFields', {
			resolve (data) {
				commit('SET_FIELDS', data)
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		})
	},

	updateField ({ commit }, field) {
		call('rules', 'updateField', {
			resolve (data) {
				commit('SET_FIELD', data)
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, field)
	},

	deleteField ({ commit }, field) {
		call('rules', 'deleteField', {
			resolve () {
				commit('DELETE_FIELD', field)
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, field)
	}
}

// mutations
const mutations = {
	SET_RULES (state, rules) {
		state.rules = fromEntries(rules.map(r => [r.name, r]))
	},

	SET_RULE (state, rule) {
		Vue.set(state.rules, rule.name, rule)
	},

	DELETE_RULE (state, rule) {
		Vue.delete(state.rules, rule.name)
	},

	SET_RULESETS (state, rulesets) {
		state.rulesets = fromEntries(rulesets.map(r => [r.name, r]))
	},

	SET_RULESET (state, ruleset) {
		Vue.set(state.rulesets, ruleset.name, ruleset)
	},

	SET_RULESET_RULES (state, { rulesetName, rules }) {
		Vue.set(state.rulesets, rulesetName, Object.assign({}, state.rulesets[rulesetName], { rules }))
	},

	DELETE_RULESET (state, ruleset) {
		Vue.delete(state.rulesets, ruleset.name)
	},

	SET_FIELDS (state, fields) {
		state.fields = fromEntries(fields.map(f => [f.name, f]))
	},

	SET_FIELD (state, field) {
		Vue.set(state.fields, field.name, field)
	},

	DELETE_FIELD (state, field) {
		Vue.delete(state.fields, field.name)
	},

	SET_RULESET_NAME (state, name) {
		state.rulesetName = name
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
