<template>
	<mdb-row><!-- --></mdb-row>
</template>

<script>
export default {
	name: 'TheFooter'
}
</script>

<style scoped>
div.row {
	height: 10px;
}
</style>
