import Vue from 'vue'

import call from '@/helpers/call'
import { isRangeType } from "@/helpers/common";
import { _call, fromEntries } from '@/helpers/javascript'

import filterValues from './filterValues'

// initial state
const state = {
	missingFilterValuesSet: false,
	filterAux: false,
	projectionAux: false,
	exportData: true,
	exportDownloadUrl: null,
	sortDesc: true,
	sortColumn: 'id',
	rows: [],
	limit: -1,
	offset: 0,
	txId: null,
	txRules: {}
}

// getters
const getters = {
	entityDefinitions (state, getters, rootState) {
		return rootState.schema.entityDefinitions
	},
	all (state, getters, rootState) {
		return rootState.properties.allProps
	},
	filter (state, getters, rootState) {
		return rootState.properties.filterProps
	},
	projection (state, getters, rootState) {
		return rootState.properties.projectionProps
	},
	baseFilters (state, getters) {
		return getters.filter.base.map(f => getters.all[f])
	},
	auxFilters (state, getters) {
		return getters.filter.aux.map(f => getters.all[f])
	},
	allFilters (state, getters) {
		return getters.baseFilters.concat(getters.auxFilters)
	},
	baseProjection (state, getters) {
		return getters.projection.base.map(f => getters.all[f])
	},
	auxProjection (state, getters) {
		return getters.projection.aux.map(f => getters.all[f])
	},
	projectionLabels (state, getters) {
		return getters.baseProjection.concat(getters.auxProjection).map(f => f.name)
	},
	queryObject (state, getters) {
		let { limit, offset, sortColumn, sortDesc } = state
		let sortBy = Object.keys(getters.all).length && getters.all[sortColumn].path
		let pq = getters['filterValues/propertiesFilterQuery']
		let filter
		if (state.filterAux) {
			let query = fromEntries(getters.allFilters.filter(f => f.name in pq).map(f => [ f.path, pq[f.name] ]))
			filter = Object.assign(query, getters['filterValues/entityFilterQuery'])
		} else {
			filter = fromEntries(getters.baseFilters.filter(f => f.name in pq).map(f => [ f.path, pq[f.name] ]))
		}
		let projection = getters.baseProjection.concat(getters.auxProjection).map(f => f.path)
		return {
			filter,
			projection,
			ruleName: state.filterValues.ruleFilterValue,
			limit,
			offset,
			sortBy,
			sortDesc
		}
	},
	exportQueryObject (state, getters) {
		let { filter, projection, ruleName, sortBy, sortDesc } = getters.queryObject
		return { filter, projection, ruleName, sortBy, sortDesc, labels: getters.projectionLabels }
	},
	isNonEmpty (state, getters) {
		let query = getters.queryObject
		return (Object.values(query.filter).length > 0 || query.ruleName) && query.projection.length > 0
	},
	isExportable (state, getters) {
		let query = getters.exportQueryObject
		return state.exportData && (Object.values(query.filter).length > 0 || query.ruleName) && query.projection.length > 0 && state.rows.length > 0
	}
}

// actions
const actions = {
	setMissingFilterValues ({ getters, state, commit }) {
		getters.baseFilters.concat(getters.auxFilters).forEach(f => {
			if (!state.filterValues.propertiesFilterValues[f.name]) {
				let value
				if (isRangeType(getters.entityDefinitions[f.definitionName] && getters.entityDefinitions[f.definitionName].type)) {
					value = { from: { value: null, isNull: false }, to: { value: null, isNull: false }, path: f.path }
				} else {
					value = { isNull: false, value: null, path: f.path }
				}
				commit('filterValues/SET_PROPERTY_FILTER_VALUE', { name: f.name, value })
			}
		})
		if (getters.baseFilters.length || getters.auxFilters.length) {
			commit('MARK_MISSING_FILTER_VALUES_SET')
		}
	},
	fetchData ({ commit, dispatch, getters }) {
		if (getters.isNonEmpty) {
			let query = getters.queryObject
			call('monitoring', 'get', {
				resolve (data) {
					commit('SET_DATA', data)
					dispatch('lists/setTxEntities', { rows: data, projection: getters.projection.base.concat(getters.projection.aux).map(p => getters.all[p].definitionName) }, { root: true })
				},
				reject (error) {
					commit('ADD_ERROR', error, { root: true })
				}
			}, query)
		}
	},
	exportData ({ commit, getters }, callback) {
		if (getters.isExportable) {
			let query = getters.exportQueryObject
			commit('SET_EXPORT_DATA', false)
			call('monitoring', 'export', {
				resolve (data) {
					_call(callback, data)
					commit('SET_EXPORT_DATA', true)
				},
				reject (error) {
					commit('SET_EXPORT_DATA', true)
					commit('ADD_ERROR', error, { root: true })
				}
			}, query)
		}
	},
	fetchTxRules ({ commit }, id) {
		call('monitoring', 'txRules', {
			resolve (data) {
				commit('SET_TX_RULES', { id, rules: data })
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, id)
	},
	setSortColumn ({ commit, dispatch, state }, column) {
		if (state.sortColumn !== column) {
			commit('SET_SORT_COLUMN', column)
			commit('SET_SORT_DESC', false)
		} else {
			commit('SET_SORT_DESC', !state.sortDesc)
		}
		dispatch('fetchData')
	}
}

// mutations
const mutations = {
	SET_DATA (state, data) {
		state.rows = data
	},
	TOGGLE_FILTER_AUX (state) {
		state.filterAux = !state.filterAux
	},
	TOGGLE_PROJECTION_AUX (state) {
		state.projectionAux = !state.projectionAux
	},
	SET_EXPORT_DATA (state, flag) {
		state.exportData = flag
	},
	SET_EXPORT_DOWNLOAD_URL (state, url) {
		state.exportDownloadUrl = url
	},
	SET_TX_ID (state, id) {
		state.txId = id
	},
	SET_TX_RULES (state, { id, rules }) {
		Vue.set(state.txRules, id, rules)
	},
	SET_SORT_COLUMN (state, value) {
		state.sortColumn = value
	},
	SET_SORT_DESC (state, value) {
		state.sortDesc = value
	},
	MARK_MISSING_FILTER_VALUES_SET (state) {
		state.missingFilterValuesSet = true
	}
}

const modules = {
	filterValues
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
	modules
}
