import Vue from 'vue'

import { constants, i18n, router, securityProvider } from '@/modules'
import store from '@/store'

import { mdbCol, mdbContainer, mdbRow } from 'mdbvue'
import verticalAligner from '@/components/common/VerticalAligner'

import App from "@/App";

Vue.config.productionTip = false
Vue.config.devtools = true

// TODO вынести инициализирующий вызов router и i18n из модулей сюда
Vue.use(constants)
Vue.use(securityProvider, { router, store })

Vue.component('mdb-container', mdbContainer)
Vue.component('mdb-row', mdbRow)
Vue.component('mdb-col', mdbCol)
Vue.component('vertical-aligner', verticalAligner)

new Vue({
  i18n,
  router,
  store,
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  async created () {
    await this.$store.dispatch('fetchUser')
  },
  watch: {
    async user () {
      if (this.$store.getters.authorized) {
        await Promise.all([
          this.$store.dispatch('schema/fetchEntities'),
          this.$store.dispatch('properties/fetchProperties'),
          this.$store.dispatch('lists/fetchLists'),
          this.$store.dispatch('graph/fetchDefinitions'),
          this.$store.dispatch('fetchVersion')
        ])
      }
    }
  },
  render: h => h(App),
}).$mount("#app")
