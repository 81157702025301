import auth from './auth'
import properties from './properties'
import graph from './graph'
import monitoring from './monitoring'
import rules from './rules'
import bindings from './bindings'
import schema from './schema'
import lists from './lists'

export default {
	auth,
	properties,
	graph,
	monitoring,
	rules,
	bindings,
	schema,
	lists
}
