<template>
	<mdb-col v-bind="colBind">
		<mdb-row>
			<edit-table ref="table" class="my-1 mr-2" :columns="columns" :data="data" @delete="deleteProperty" @update="updateProperty" @sort="changeSort">
        <template v-slot:__path="{ field, row, index, value }">
          <path-input :value="value" @input="change({ field, row, index, value: $event })"/>
        </template>
			</edit-table>
		</mdb-row>
	</mdb-col>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import EditTable from '@/components/common/EditTable'
import PathInput from '@/components/common/PathInput'

export default {
	name: 'EditProperties',
	components: { PathInput, EditTable },
	props: {
    col: [String, Object]
  },
  data () {
    return {
      sort: {
        field: 'name',
        direction: 'asc'
      }
    }
  },
	computed: {
		columns () {
			return [
        this.setSort({
          label: 'Name',
          field: 'name',
          id: true
        }),
				this.setSort({
					label: 'Path',
					field: 'path'
				})
			]
		},
		data () {
			return Object.values(this.allProps)
		},
		colBind () {
			if (!this.col) {
				return {}
			} else if (this.col.constructor === Object) {
				return this.col
			} else {
				return { col: this.col }
			}
		},
    ...mapState('properties', [ 'allProps' ])
	},
	methods: {
    setSort (col) {
      return this.sort.field === col.field ? Object.assign(col, { sort: this.sort.direction }) : col
    },
    changeSort ({ field, sort }) {
      if (this.sort.field !== field || this.sort.direction !== sort) {
        this.sort.field = field;
        this.sort.direction = sort;
      }
    },
		change ({ field, row, index, value }) {
			this.$refs.table.change({ field, row, index, value })
		},
		...mapActions('properties', [ 'updateProperty', 'deleteProperty' ])
	}
}
</script>

<style scoped>

</style>
