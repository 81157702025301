import { api } from '@/helpers/core'

export default {
	get () {
		return api.get('/settings/lists/')
	},

	add (name) {
		return api.post('/settings/lists/', { name })
	},

	remove (name) {
		return api.post('/settings/lists/delete', { name })
	},

	getLists (entityId) {
		return api.get('/settings/lists/getLists/' + entityId)
	},

	getEntities (listName) {
		return api.get('/settings/lists/getEntities/' + listName)
	},

	addToList ({ id, listName, reason }) {
		return api.post('/settings/lists/addToList', { id, listName, reason })
	},

	removeFromList ({ id, listName }) {
		return api.post('/settings/lists/removeFromList', { id, listName, reason: '' })
	},

	addEntity ({ id, listName, definitionName, value, reason }) {
		return api.post('/settings/lists/addEntity', { id, listName, definitionName, value, reason })
	},

	removeEntity ({ id, listName }) {
		return api.post('/settings/lists/removeEntity', { id, listName, reason: '' })
	},

	upload ({ listName, definitionName, reason, files }) {
		let data = new FormData()
		data.append('listName', listName)
		data.append('definitionName', definitionName)
		data.append('reason', reason)
		files.forEach(file => data.append('files', file))
		return api.post('/settings/lists/upload', data)
	}
}
