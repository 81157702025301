export function isMatchType (type) {
	return type === 'String'
}

export function isBooleanType (type) {
	return type === 'Boolean'
}

export function isRangeType (type) {
	return [ 'Integer', 'Long', 'Amount', 'Timestamp' ].includes(type)
}

export function isTimestampType (type) {
	return type === 'Timestamp'
}
