<template>
	<mdb-row class="pb-3 pb-sm-0">
		<div class="d-none d-sm-block mb-sm-3 profile-height w-100">
			<mdb-container class="position-absolute right w-auto">
				<mdb-row>
					<the-profile></the-profile>
				</mdb-row>
			</mdb-container>
		</div>
		<the-profile class="d-sm-none"></the-profile>
	</mdb-row>
</template>

<script>
import TheProfile from './TheProfile.vue'

export default {
	name: 'TheHeader',
	components: { TheProfile }
}
</script>

<style scoped>
.profile-height {
	min-height: 48px;
}
</style>
