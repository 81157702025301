<template>
	<tbl striped bordered class="bg-white w-100">
		<tbl-head class="mdb-color darken-3">
			<tr>
				<th class="text-white c-pointer py-1" v-for="column in baseColumns" :key="column" @click="setSortColumn(column)">
          {{column}}
          <template v-if="column === sortColumn">
            <mdb-icon :icon="getIcon(sortDesc ? 'up' : 'down')"/>
          </template>
        </th>
				<template v-if="projectionAux">
					<th class="text-white c-pointer py-1 fs-1" v-for="column in auxColumns" :key="column" @click="setSortColumn(column)">
            {{column}}
            <template v-if="column === sortColumn">
              <mdb-icon :icon="getIcon(sortDesc ? 'up' : 'down')"/>
            </template>
          </th>
				</template>
			</tr>
		</tbl-head>
		<tbl-body>
			<monitoring-row v-for="(row, index) in rows" :key="index" :row="row" @show-details="showDetails" :base-projection="baseProjection" :aux-projection="auxProjection" :projection-aux="projectionAux"></monitoring-row>
		</tbl-body>
	</tbl>
</template>

<script>
import { mdbIcon, Tbl, TblBody, TblHead } from 'mdbvue'
import { mapActions, mapState } from 'vuex'

import MonitoringRow from './MonitoringRow.vue'

export default {
	name: 'MonitoringTable',
	components: { MonitoringRow, mdbIcon, Tbl, TblBody, TblHead },
	props: {
		rows: Array,
		baseProjection: Array,
		auxProjection: Array,
		projectionAux: Boolean
	},
  computed: {
		baseColumns () {
			return this.baseProjection.map(f => f.name)
		},
		auxColumns () {
			return this.auxProjection.map(f => f.name)
		},
		showDetails () {
			return this.$listeners['show-details']
		},
    ...mapState('monitoring', [ 'sortColumn', 'sortDesc' ])
	},
  methods: {
    getIcon (direction) {
      return 'sort-' + direction
    },
    ...mapActions('monitoring', [ 'setSortColumn' ])
  }
}
</script>

<style scoped>
>>> table {
	border-width: 4px;
}
</style>
