export default {
	get () {
		return [
			[ '79992345678', 12345681, 'ADF456H12C', 1245.56, 1554775036695 ],
			[ '79991234567', 12345680, 'ADF456H12C', 1245.56, 1554775036695 ],
			[ '79993456789', 12345679, 'ADF456H12C', 1245.56, 1554775036695 ],
			[ '79990123456', 12345678, 'ADF456H12C', 1245.56, 1554775036695 ]
		]
	},
	txRules () {
		return []
	}
}
