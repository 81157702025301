<template>
	<mdb-row class="rule-wrapper mdb-color bg-white">
		<mdb-col col="12" class="rule">
			<span class="align-middle">{{ rule.name }}</span>
			<mdb-badge @click.native="$emit('delete')">x</mdb-badge>
		</mdb-col>
	</mdb-row>
</template>

<script>
import { mdbBadge } from 'mdbvue'

export default {
	name: 'RuleView',
	components: { mdbBadge },
	props: {
		rule: {
			type: Object,
			required: true
		}
	}
}
</script>

<style scoped>
.rule-wrapper {
	cursor: move;
}
.rule .badge {
	cursor: pointer;
}
.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}
</style>
