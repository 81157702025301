export default {
	get () {
		return []
	},

	getLists () {
		return []
	},

	getEntities () {
		return []
	}
}
