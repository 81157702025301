import { auth } from '@/helpers/core'

export default {
	get () {
		return auth.get('/')
	},
	logIn ({ login, password }) {
		return auth.post('/', { login, password })
	},
	logOut () {
		return auth.post('/logout', { })
	}
}
