<template>
	<div class="d-inline-block h-100" :class="alignClass"></div>
</template>

<script>
export default {
	name: 'VerticalAligner',
	props: {
		align: {
			default: 'middle'
		}
	},
	computed: {
		alignClass () {
			return 'align-' + this.align
		}
	}
}
</script>
