import TheHeader from './TheHeader.vue'
import TheBrand from './TheBrand.vue'
import TheMenu from './TheMenu.vue'
import TheFooter from './TheFooter.vue'
import TheErrors from './TheErrors.vue'

export default {
	name: 'Layout',
	components: { TheBrand, TheHeader, TheMenu, TheFooter, TheErrors },
	computed: {
		viewHeight () {
			return Math.max(window.innerHeight, this.$el.clientHeight) - this.$refs.header.$el.clientHeight - this.$refs.footer.$el.clientHeight
		}
	},
	mounted () {
		if (this.$route.query && this.$route.query.colorify) {
			this.$el.parentElement.style.cssText = 'background: rgb(17, 145, 158) !important'
		}
	},
	watch: {
		$route (newValue) {
			if (newValue.query && newValue.query.colorify) {
				this.$el.parentElement.style.cssText = 'background: rgb(17, 145, 158) !important'
			}
		}
	},
	render () {
		return (
			<div id="app" class="fluid text-white">
				<mdb-container fluid>
					<mdb-row>
						<mdb-col col="12" sm="3_67" md="3" lg="2_67" xl="1_67" id="left-column">
							<the-brand/>
							<the-menu/>
						</mdb-col>
						<mdb-col col="12" sm="8_33" md="9" lg="9_33" xl="10_33" id="center-column">
							<the-header ref="header"/>
							<router-view/>
							<the-footer ref="footer"/>
						</mdb-col>
					</mdb-row>
				</mdb-container>
				<the-errors/>
			</div>
		)
	}
}
