import api from '@/api'
import mock from '@/mock'

const isMock = process.env.NODE_ENV === 'serverless'

const endpoints = isMock ? mock : api

export default function (module, method, { resolve, reject }, payload = null) {
	let endpoint = endpoints[module][method]
	if (isMock) {
		console.log('called /' + module + '/' + method + ' with payload ' + JSON.stringify(payload))
		resolve(endpoint(payload))
	} else {
		endpoint(payload)
			.then(response => resolve(response.data))
			.catch(error => reject(error))
	}
}
