<template>
	<mdb-row class="wrapper-margin">
		<mdb-col col="12" class="px-0">
			<mdb-row class="mx-0 mb-2" v-for="ruleset in nonEmptyRulesets" :key="ruleset.name">
				<mdb-col col="12" lg="6" class="px-0 mb-1 mb-lg-0 chart-padding-first">
					<mdb-line-chart class="bg-white" :key="updatedKey" :data="conversionData(ruleset.name)" :options="options" :width="width" :height="height"></mdb-line-chart>
				</mdb-col>
				<mdb-col col="12" lg="6" class="px-0 chart-padding-second">
					<mdb-line-chart class="bg-white" :key="updatedKey" :data="totalData(ruleset.name)" :options="options" :width="width" :height="height"></mdb-line-chart>
				</mdb-col>
			</mdb-row>
		</mdb-col>
	</mdb-row>
</template>

<script>
import { mdbLineChart } from 'mdbvue'
import { mapState, mapActions, mapMutations } from 'vuex'
import Vue from 'vue'
import call from '@/helpers/call'
import { makeArray, pad } from '@/helpers/javascript'

export default {
	name: 'DashboardIndex',
	components: { mdbLineChart },
	computed: {
    nonEmptyRulesets () {
      return Object.keys(this.rulesets).filter(this.hasData).map(m => this.rulesets[m])
    },
		options () {
			return {
				responsive: false,
				maintainAspectRatio: false,
				scales: {
					xAxes: [{
						gridLines: {
							display: true,
							color: 'rgba(0, 0, 0, 0.1)'
						}
					}],
					yAxes: [{
						gridLines: {
							display: true,
							color: 'rgba(0, 0, 0, 0.1)'
						}
					}]
				}
			}
		},
		...mapState('rules', [ 'rulesets' ])
	},
	data () {
		return {
			height: 300,
			width: 600,
			updatedKey: null,
			rawData: {}
		}
	},
	async mounted () {
		window.addEventListener('resize', this.resize)
		this.resize()
		this.resize()
		await this.fetchRulesets({ after: this.fetchData })
	},
	destroyed () {
		window.removeEventListener('resize', this.resize)
	},
	watch: {
		$route () {
			this.fetchRulesets({ after: this.fetchData })
		}
	},
	methods: {
		resize () {
			if (window.innerWidth < 992) {
				this.width = this.$el.clientWidth
			} else {
				this.width = (this.$el.clientWidth - 15) / 2
			}
			this.resetUpdatedKey()
		},
		resetUpdatedKey () {
			this.updatedKey = Math.floor(Math.random() * 100000000)
		},
		fetchData () {
			let vm = this
			vm.rawData = {}
			Object.values(this.rulesets).forEach(r => {
				call('dashboard', 'getData', {
					resolve (data) { Vue.set(vm.rawData, r.name, data) },
					reject (error) { vm.ADD_ERROR(error) }
				}, r.name)
			})
		},
		hasData (merchantId) {
			return (this.rawData[merchantId] || []).length > 0
		},
		conversionData (merchantId) {
			let rawData = this.rawData[merchantId] || []
			let labels = []
			let data = []

			let minDate
			let maxDate
			let days

			let successData
			let failedData
			if (rawData.length) {
				minDate = this.date(Math.min(...rawData.map(d => d.date)))
				maxDate = this.date(Math.max(...rawData.map(d => d.date)))
				days = (maxDate - minDate) / (1000 * 3600 * 24) + 1
				if (days === 1) {
					labels.push(this.formatDate(new Date(minDate.getTime() - 1000 * 3600 * 24)))
				}
				labels.push(this.formatDate(minDate))
				if (days === 1) {
					labels.push(this.formatDate(new Date(minDate.getTime() + 1000 * 3600 * 24)))
				} else {
					labels = labels.concat(makeArray('', days - 2)).concat(this.formatDate(maxDate))
				}
				data = [ ...Array(days).keys() ].map(() => [])
				rawData.forEach(i => data[Math.floor((i.date - minDate) / (1000 * 3600 * 24))].push(i))
				successData = data.map(d => d.filter(i => i.status !== 'Deny').length)
				failedData = data.map(d => d.filter(i => i.status === 'Deny').length)
				if (days === 1) {
					successData = [ 0, ...successData, 0 ]
					failedData = [ 0, ...failedData, 0 ]
				}
			} else {
				labels.push(this.formatDate(new Date()))
				successData = [ 0 ]
				failedData = [ 0 ]
			}
			return {
				labels: labels,
				datasets: [
					{
						label: this.rulesets[merchantId].name + ' success',
						backgroundColor: 'rgba(0, 255, 132, 0.1)',
						borderColor: 'rgba(0, 255, 132, 1)',
						borderWidth: 0.7,
						data: successData
					},
					{
						label: this.rulesets[merchantId].name + ' failed',
						backgroundColor: 'rgba(255, 99, 132, 0.1)',
						borderColor: 'rgba(255, 99, 132, 1)',
						borderWidth: 0.7,
						data: failedData
					}
				]
			}
		},
		totalData (merchantId) {
			let rawData = this.rawData[merchantId] || []
			let labels = []
			let data = []

			let minDate
			let maxDate
			let days

			let successData
			if (rawData.length) {
				minDate = this.date(Math.min(...rawData.map(d => d.date)))
				maxDate = this.date(Math.max(...rawData.map(d => d.date)))
				days = (maxDate - minDate) / (1000 * 3600 * 24) + 1
				if (days === 1) {
					labels.push(this.formatDate(new Date(minDate.getTime() - 1000 * 3600 * 24)))
				}
				labels.push(this.formatDate(minDate))
				if (days === 1) {
					labels.push(this.formatDate(new Date(minDate.getTime() + 1000 * 3600 * 24)))
				} else {
					labels = labels.concat(makeArray('', days - 2)).concat(this.formatDate(maxDate))
				}
				data = [ ...Array(days).keys() ].map(() => [])
				rawData.forEach(i => data[Math.floor((i.date - minDate) / (1000 * 3600 * 24))].push(i))
				successData = data.map(d => d.filter(i => i.status !== 'Deny').reduce((sum, item) => sum + item.amount, 0))
				if (days === 1) {
					successData = [ 0, ...successData, 0 ]
				}
			} else {
				labels.push(this.formatDate(new Date()))
				successData = [ 0 ]
			}
			return {
				labels: labels,
				datasets: [
					{
						label: this.rulesets[merchantId].name + ' total',
						backgroundColor: 'rgba(0, 99, 132, 0.1)',
						borderColor: 'rgba(0, 99, 132, 1)',
						borderWidth: 0.7,
						data: successData
					}
				]
			}
		},
		date (long) {
			let date = new Date(long)
			date.setHours(0)
			date.setMinutes(0)
			date.setSeconds(0)
			date.setMilliseconds(0)
			return date
		},
		formatDate (date) {
			let day = date.getDate()
			let month = date.getMonth()
			let year = date.getFullYear()
			return pad(day, 2) + '.' + pad(month + 1, 2) + '.' + year
		},
		...mapActions('rules', [ 'fetchRulesets' ]),
		...mapMutations([ 'ADD_ERROR' ])
	}
}
</script>

<style scoped>
.wrapper-margin {
	margin-right: calc(-15px + 0.5rem);
}
@media (min-width: 992px) {
	.chart-padding-first {
		padding-right: 7px !important;
	}

	.chart-padding-second {
		padding-left: 8px !important;
	}
}
</style>
