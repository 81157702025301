import Vue from 'vue'
import Router from 'vue-router'

import Layout from '@/components/layout'

import { TheLogin } from '@/components/login'
import { GraphIndex } from '@/components/graph'
import { MonitoringIndex } from '@/components/monitoring'
import { PropertiesIndex } from '@/components/properties'
import { RulesIndex, RulesPage, RulesFields, RulesetsPage } from '@/components/rules'
import { ListsIndex } from '@/components/lists'
import { DashboardIndex } from '@/components/dashboard'
import { BindingsIndex, EntitiesIndex, RelationsIndex, SchemaIndex } from '@/components/schema'

Vue.use(Router)

export default new Router({
    // mode: 'history',
    routes: [
        {
            path: '/',
            component: Layout,
            children: [
                {
                    path: 'graph/:filter?/:value?/',
                    name: 'graph',
                    component: GraphIndex,
                    meta: { requiresAuth: true },
                    props: true
                },
                {
                    path: '',
                    name: 'monitoring',
                    component: MonitoringIndex,
                    props: true,
                    alias: 'monitoring',
                    meta: { requiresAuth: true }
                },
                {
                    path: 'parameters',
                    name: 'Parameters',
                    component: PropertiesIndex,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'rules',
                    component: RulesIndex,
                    meta: { requiresAuth: true },
                    children: [
                        {
                            path: '/rulesets',
                            component: RulesetsPage,
                            name: 'RulesetsPage',
                            alias: ''
                        },
                        {
                            path: 'edit',
                            component: RulesPage,
                            name: 'RulesPage'
                        },
                        {
                            path: '/fields',
                            component: RulesFields,
                            name: 'RulesFields'
                        }
                    ]
                },
                {
                    path: 'lists',
                    component: ListsIndex,
                    name: 'ListsIndex',
                    meta: { requiresAuth: true }
                },
                {
                    path: 'dashboard',
                    component: DashboardIndex,
                    name: 'DashboardIndex',
                    meta: { requiresAuth: true }
                },
                {
                    path: 'schema',
                    component: SchemaIndex,
                    meta: { requiresAuth: true },
                    children: [
                        {
                            path: '/entities',
                            component: EntitiesIndex,
                            name: 'EntitiesIndex',
                            alias: ''
                        },
                        {
                            path: '/relations',
                            component: RelationsIndex,
                            name: 'RelationsIndex'
                        },
                        {
                            path: '/bindings',
                            component: BindingsIndex,
                            name: 'BindingsIndex'
                        }
                    ]
                },
                {
                    path: 'login',
                    name: 'login',
                    component: TheLogin,
                    meta: { requiresRole: ['unauthorized', undefined], redirect: to => to.query.redirect
                            ? decodeURIComponent(to.query.redirect) : '/'}
                }
            ]
        }
    ]
})
