import { isRangeType } from '@/helpers/common'

export function toQuery ({ isNull, value, from, to }) {
    if (isNull) {
        return { match: null }
    } else if (from || to) {
        return { from: from && from.value || null, to: to && to.value || null }
    } else {
        return { match: value }
    }
}

export function toFilterValue (entityDefinition, name, value, isNull, from, to) {
    if (isRangeType(entityDefinition.type)) {
        if (isNull) {
            console.error('Попытка установить значение null для диапазонного типа:',
                { name, type: entityDefinition.type })
        } else if (value || value === 0) {
            console.error('Попытка установить точное значение для диапазонного типа:',
                { name, type: entityDefinition.type, value })
        } else {
            return { from, to }
        }
    } else {
        if (from || to) {
            console.error('Попытка установить диапазонное значение для неподдерживаемого типа:',
                { name, type: entityDefinition.type, from, to })
        } else if (isNull) {
            return { isNull }
        } else {
            return { value }
        }
    }
}
