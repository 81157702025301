const properties = {
	all: [
		{ name: 'id', path: 'tx', definitionName: 'tx' },
		{ name: 'Карта', path: 'tx.src_card', definitionName: 'bank_card' },
		{ name: 'Телефон', path: 'tx.dst_phone', definitionName: 'phone' },
		{ name: 'Сумма', path: 'tx.amount', definitionName: 'amount' },
		{ name: 'Время завершения', path: 'tx.inspected_at', definitionName: 'inspected_at' }
	],
	Filter: { base: [ 'id', 'Телефон' ], aux: [ 'Карта' ] },
	Projection: { base: [ 'id', 'Сумма', 'Карта' ], aux: [ 'Телефон', 'Время завершения' ] }
}

export default {
	get () {
		return properties
	},
	replace ({ property }) {
		return properties[property]
	},
	update (property) {
		return property
	},
	delete () {
		return { ok: true }
	}
}
