export const messages = {
	'graph': {
		'node-types': {
			'filter-label': 'Node type',
			'select-label': 'Node types'
		}
	},
	'profile': {
		'log-in': 'Log in',
		'log-out': 'Log out',
		'login-label': 'Login',
		'password-label': 'Password'
	},
	'lists': {
		'list-label': 'List',
		'list-select': 'List',
		'definition-label': 'Type',
		'definition-select': 'Type',
		'value-label': 'Value',
		'added-label': 'Added',
		'reason-label': 'Reason',
		'reason-default': 'Added via control panel'
	},
	'node-details-popup': {
		'graph': 'Graph',
		'transactions': 'Transactions'
	},
	'monitoring': {
		'fired-rule-filter': 'Fired rule',
		'reset-rule-filter': 'Reset filter'
	},
	'rules': {
		'fields': {
			'name-label': 'Name',
			'code-label': 'Code',
			'type-label': 'Type',
			'type-select': 'Type',
			'entity': 'Entity',
			'function': 'Expression',
			'aggregate': 'Aggregate'
		},
		'rulesets': {
			'new': 'New ruleset'
		}
	},
	'bindings': {
		'commit': 'Commit bindings',
		'rollback': 'Rollback bindings',
		'edit': 'Edit bindings'
	},
	'common': {
		'confirm-removal': 'Confirm removal?',
		'update': 'Update',
		'delete': 'Delete',
		'save': 'Save',
		'reset': 'Reset',
		'new': 'New',
		'add': 'Add',
		'remove': 'Remove',
		'show': 'Show',
		'hide': 'Hide'
	}
}
