import { quadtree } from 'd3-quadtree'
import constant from 'd3-force/src/constant'
import { jiggle } from './index'

function x (d) {
	return d.x + d.vx
}

function y (d) {
	return d.y + d.vy
}

export default function (radius) {
	var nodes
	var radii
	var strength = 1
	var iterations = 1

	if (typeof radius !== 'function') radius = constant(radius == null ? 1 : +radius)

	function force () {
		var i
		var n = nodes.length
		var tree
		var node
		var xi
		var yi
		var ri
		var ri2

		for (var k = 0; k < iterations; ++k) {
			tree = quadtree(nodes, x, y).visitAfter(prepare)
			for (i = 0; i < n; ++i) {
				node = nodes[i]
				ri = radii[node.index]
				ri2 = ri * ri
				xi = node.x + node.vx
				yi = node.y + node.vy
				tree.visit(apply)
			}
		}

		function apply (quad, x0, y0, x1, y1) {
			var data = quad.data
			var rj = quad.r
			var r = ri + rj
			if (data) {
				if (data.index > node.index) {
					var x = xi - data.x - data.vx
					var y = yi - data.y - data.vy
					var l = x * x + y * y
					if (l < r * r) {
						if (x === 0) {
							x = jiggle()
							l += x * x
						}
						if (y === 0) {
							y = jiggle()
							l += y * y
						}
						l = (r - (l = Math.sqrt(l))) / l * strength
						node.vx += (x *= l) * (r = (rj *= rj) / (ri2 + rj))
						node.vy += (y *= l) * r
						data.vx -= x * (r = 1 - r)
						data.vy -= y * r
					}
				}
				return
			}
			return x0 > xi + r || x1 < xi - r || y0 > yi + r || y1 < yi - r
		}
	}

	function prepare (quad) {
		if (quad.data) {
			quad.r = radii[quad.data.index]
			return quad.r
		}
		for (var i = quad.r = 0; i < 4; ++i) {
			if (quad[i] && quad[i].r > quad.r) {
				quad.r = quad[i].r
			}
		}
	}

	function initialize () {
		if (!nodes) return
		var i
		var n = nodes.length
		var node
		radii = new Array(n)
		for (i = 0; i < n; ++i) {
			node = nodes[i]
			radii[node.index] = +radius(node, i, nodes)
		}
	}

	force.initialize = function (_) {
		nodes = _.filter((n) => !n.hostId)
		initialize()
	}

	force.iterations = function (_) {
		if (arguments.length) {
			iterations = +_
			return force
		} else {
			return iterations
		}
	}

	force.strength = function (_) {
		if (arguments.length) {
			strength = +_
			return force
		} else {
			return strength
		}
	}

	force.radius = function (_) {
		if (arguments.length) {
			radius = typeof _ === 'function' ? _ : constant(+_)
			initialize()
			return force
		} else {
			return radius
		}
	}

	return force
}
