<template>
    <mdb-row>
		<menu-item href="/monitoring" col="12">Monitoring</menu-item>
		<menu-item href="/graph" col="12">Graph</menu-item>
		<menu-item href="/parameters" col="12">Parameters</menu-item>
		<menu-item href="/rules" col="12">Rules</menu-item>
		<menu-item href="/lists" col="12">Lists</menu-item>
		<menu-item href="/dashboard" col="12">Dashboard</menu-item>
    <!-- <menu-item href="/schema" col="12">Schema</menu-item> -->
	</mdb-row>
</template>

<script>
import MenuItem from '@/components/common/MenuItem'

export default {
	name: 'TheMenu',
	components: { MenuItem }
}
</script>

<style scoped>
.menu-link {
	color: #ffffff !important;
}
</style>
