<template>
	<mdb-modal v-if="modal" @close="close" size="lg">
		<mdb-modal-header class="text-dark pb-0">
			<mdb-modal-title class="font-weight-bold">{{ usedDefinitions[source.typeId].label }}: {{ source.name }}, {{ usedDefinitions[target.typeId].label }}: {{ target.name }}</mdb-modal-title>
		</mdb-modal-header>
		<mdb-modal-body class="px-0 pb-0 pt-3 overflow-auto">
			<monitoring-table @show-details="showDetails" :rows="rows" :base-projection="baseProperties" :aux-projection="auxProperties" :projection-aux="aux"></monitoring-table>
			<mdb-icon class="position-absolute left-top text-primary c-pointer" :icon="showProjectionAuxIcon" @click.native="aux = !aux"></mdb-icon>
		</mdb-modal-body>
		<mdb-modal-footer class="pt-0">
		</mdb-modal-footer>
	</mdb-modal>
</template>

<script>
import { mdbIcon, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter } from 'mdbvue'
import { mapState, mapGetters, mapActions } from 'vuex'

import MonitoringTable from '@/components/monitoring/MonitoringTable.vue'
export default {
	name: 'TxDataPopup',
	components: { mdbIcon, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, MonitoringTable },
	data () {
		return {
			aux: false,
			source: {},
			target: {},
			txIds: [],
			modal: false
		}
	},
	computed: {
		showProjectionAuxIcon () {
			if (this.aux) {
				return 'caret-square-left'
			} else {
				return 'caret-square-right'
			}
		},
		baseProperties () {
			return this.graphProps.base.map((g) => this.allProps[g])
		},
		auxProperties () {
			return this.graphProps.aux.map((g) => this.allProps[g])
		},
		showDetails () {
			return this.$listeners['show-details']
		},
		...mapState('graph', [ 'rows' ]),
    ...mapState('properties', [ 'allProps', 'graphProps' ]),
		...mapGetters('graph', [ 'usedDefinitions' ])
	},
	methods: {
		open ({ link }) {
			this.source = link.sourceHost || link.source
			this.target = link.targetHost || link.target
			this.txIds = link.txIds
			this.txData({ txIds: this.txIds, properties: this.baseProperties.concat(this.auxProperties) })
			this.modal = true
		},
		close () {
			this.modal = false
		},
		...mapActions('graph', [ 'txData' ])
	}
}
</script>

<style scoped>

</style>
