<template>
	<mdb-row class="justify-content-center bg-white">
		<edit-table :columns="columns" :data="data" @update="updateRule" @delete="deleteRule" @sync="fetchRules"></edit-table>
	</mdb-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import EditTable from '@/components/common/EditTable'

export default {
	name: 'RulesPage',
	components: { EditTable },
	async mounted () {
		await this.fetchRules()
	},
	computed: {
		columns () {
			return [
				{
					label: 'Name',
					field: 'name',
          id: true,
					sort: 'asc'
				},
				{
					label: 'Code',
					field: 'code'
				}
			]
		},
		data () {
			return Object.values(this.rules)
		},
		...mapState('rules', [ 'rules' ])
	},
	methods: {
		...mapActions('rules', [ 'fetchRules', 'updateRule', 'deleteRule' ])
	},
	watch: {
		async $route () {
			await this.fetchRules()
		}
	}
}
</script>

<style scoped>

</style>
