import Vue from 'vue'

import {fromEntries} from '@/helpers/javascript'

import call from '@/helpers/call'

// initial state
const state = {
	allProps: {},
	filterProps: { base: [], aux: [] },
	projectionProps: { base: [], aux: [] },
	graphProps: { base: [], aux: [] }
}

// getters
const getters = {}

// actions
const actions = {
	fetchProperties ({ commit }) {
		call('properties', 'get', {
			resolve(data) {
				commit('SET_PROPERTIES', data)
			},
			reject(error) {
				commit('ADD_ERROR', error, {root: true})
			}
		})
	},

	replaceProperty ({ commit, dispatch }, { name, role, newIndex }) {
		call('properties', 'replace', {
			resolve (data) {
				commit('SET_PROPERTIES_SECTION', { section: data, role })
				dispatch('monitoring/setMissingFilterValues', null, { root: true })
				dispatch('monitoring/fetchData', null, { root: true })
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, { name, property: role, value: newIndex })
	},

	updateProperty ({ commit, dispatch }, property) {
		call('properties', 'update', {
			resolve (data) {
				commit('SET_PROPERTY', data)
				commit('monitoring/filterValues/DROP_PROPERTY_FILTER_VALUE', property.name, { root: true })
				dispatch('monitoring/setMissingFilterValues', null, { root: true })
				dispatch('monitoring/fetchData', null, { root: true })
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, property)
	},

	deleteProperty ({ commit, dispatch }, property) {
		call('properties', 'delete', {
			resolve () {
				commit('DELETE_PROPERTY', property)
				commit('monitoring/filterValues/DROP_PROPERTY_FILTER_VALUE', property.name, { root: true })
				dispatch('monitoring/setMissingFilterValues', null, { root: true })
				dispatch('monitoring/fetchData', null, { root: true })
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, property)
	}
}

// mutations
const mutations = {
	SET_PROPERTIES (state, properties) {
		state.filterProps = properties.Filter
		state.projectionProps = properties.Projection
		state.graphProps = properties.Graph
		state.allProps = fromEntries(properties.all.map(p => [p.name, p]))
	},

	SET_PROPERTIES_SECTION (state, { role, section }) {
		switch (role) {
			case 'Filter':
				state.filterProps = section
				break
			case 'Projection':
				state.projectionProps = section
				break
			case 'Graph':
				state.graphProps = section
				break
			default:
		}
	},

	SET_PROPERTY (state, property) {
		Vue.set(state.allProps, property.name, property)
	},

	DELETE_PROPERTY (state, property) {
		Vue.delete(state.allProps, property.name)
		Vue.set(state.filterProps, 'base', state.filterProps.base.filter(p => p !== property.name))
		Vue.set(state.filterProps, 'aux', state.filterProps.aux.filter(p => p !== property.name))
		Vue.set(state.projectionProps, 'base', state.projectionProps.base.filter(p => p !== property.name))
		Vue.set(state.projectionProps, 'aux', state.projectionProps.aux.filter(p => p !== property.name))
		Vue.set(state.graphProps, 'base', state.graphProps.base.filter(p => p !== property.name))
		Vue.set(state.graphProps, 'aux', state.graphProps.aux.filter(p => p !== property.name))
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
