<template>
	<mdb-row class="justify-content-center bg-white">
		<edit-table ref="table" :columns="columns" :data="data" @update="updateField" @delete="deleteField" @sync="fetchFields">
			<template v-slot:__type="{ field, row, index, value }">
				<mdb-select v-if="isNew(row)" :btn-save="false" :options="typeOptions(value)"
                    @change="change({ field, row, index, value: $event })"/>
				<div v-else v-html="typeLabel(value)"></div>
			</template>
		</edit-table>
	</mdb-row>
</template>

<script>
import { mdbSelect } from 'mdbvue'
import { mapState, mapActions } from 'vuex'

import EditTable from '@/components/common/EditTable'

export default {
	name: 'RulesFields',
	components: { mdbSelect, EditTable },
	async mounted () {
		await this.fetchFields()
	},
	computed: {
		columns () {
			return [
				{
					label: this.$t('rules.fields.name-label', this.locale),
					field: 'name',
          id: true,
					sort: 'asc'
				},
				{
					label: this.$t('rules.fields.code-label', this.locale),
					field: 'code'
				},
				{
					label: this.$t('rules.fields.type-label', this.locale),
					field: 'type'
				}
			]
		},
		data () {
			return Object.values(this.fields)
		},
		types () {
			return [
				{ value: 'Entity', text: this.$t('rules.fields.entity', this.locale) },
				{ value: 'Function', text: this.$t('rules.fields.function', this.locale) },
				{ value: 'Aggregate', text: this.$t('rules.fields.aggregate', this.locale) }
			]
		},
		...mapState([ 'locale' ]),
		...mapState('rules', [ 'fields' ])
	},
	methods: {
		typeOptions (value) {
			return [{ text: this.$t('rules.fields.type-select', this.locale), value: null, disabled: true, selected: !value }]
				.concat(this.types.map(t => Object.assign({}, t, { selected: t.value === value })))
		},
		typeLabel (value) {
			return this.types.filter(t => t.value === value).map(t => t.text)[0]
		},
		change ({ field, row, index, value }) {
			this.$refs.table.change({ field, row, index, value })
		},
		isNew (row) {
			return this.$refs.table && this.$refs.table.isNew(row)
		},
		...mapActions('rules', [ 'fetchFields', 'updateField', 'deleteField' ])
	},
	watch: {
		$route () {
			this.fetchFields()
		}
	}
}
</script>

<style scoped>

</style>
