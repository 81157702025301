<template>
	<mdb-col v-bind="colBind" class="bg-white mx-2 my-1 pb-2">
		<slot v-for="property in keys" :name="'_' + property" :property="property" :entity="entityEdited">
			<mdb-input :key="property" :label="property" v-model="entityEdited[property]" :disabled="disabled" :class="getPropertyClass(property)"></mdb-input>
		</slot>
		<slot name="default"></slot>
		<form @submit.prevent="$emit('submit', entityEdited)" @reset.prevent="$emit('reset')">
			<slot name="submit">
				<mdb-btn block :waves="false" color="primary" class="mb-1" type="submit" :disabled="disabled">{{ submit }}</mdb-btn>
			</slot>
			<slot name="reset">
				<mdb-btn block :waves="false" color="primary" class="mb-1" type="reset" :disabled="disabled">{{ reset }}</mdb-btn>
			</slot>
		</form>
	</mdb-col>
</template>

<script>
import { mdbInput, mdbBtn } from 'mdbvue'

import { copy } from '@/helpers/javascript'

export default {
	name: 'EditForm',
	components: { mdbInput, mdbBtn },
	props: {
		disabled: {
			type: Boolean,
			default: false
		},
		entity: {
			type: Object,
			required: true
		},
		properties: {
			type: [Array, Object],
			default: null
		},
		excludeProperties: {
			type: Array,
			default: null
		},
		submit: String,
		reset: String,
		col: [String, Object]
	},
	data () {
		return {
			entityEdited: this.entity ? copy(this.entity) : {}
		}
	},
	computed: {
		keys () {
			return (this.properties ? (this.properties.constructor === Array ? this.properties
				: Object.keys(this.properties)) : Object.keys(this.entity))
				.filter(p => !(this.excludeProperties || []).includes(p))
		},
		colBind () {
			if (!this.col) {
				return {}
			} else if (this.col.constructor === Object) {
				return this.col
			} else {
				return { col: this.col }
			}
		}
	},
	watch: {
		entity: {
			handler (entity) {
				this.entityEdited = copy(entity)
			},
			deep: true
		}
	},
	methods: {
		getPropertyClass (property) {
			return (this.properties && this.properties.constructor === Object) ? this.properties[property] : ''
		}
	}
}
</script>

<style scoped>

</style>
