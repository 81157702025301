import { api } from '@/helpers/core'

export default {
	getRules () {
		return api.get('/settings/rules/')
	},
	updateRule (rule) {
		return api.post('/settings/rules/', rule)
	},
	deleteRule (rule) {
		return api.post('/settings/rules/delete', rule)
	},
	getRulesets () {
		return api.get('/settings/rulesets/')
	},
	createRuleset (ruleset) {
		return api.post('/settings/rulesets/', ruleset)
	},
	deleteRuleset (ruleset) {
		return api.post('/settings/rulesets/delete', ruleset)
	},
	include ({ rulesetName, name }) {
		return api.post('/settings/rulesets/include', { rulesetName, name })
	},
	replace ({ rulesetName, name, value }) {
		return api.post('/settings/rulesets/replace', { rulesetName, name, value })
	},
	getFields () {
		return api.get('/settings/fields/')
	},
	updateField (field) {
		return api.post('/settings/fields/', field)
	},
	deleteField (field) {
		return api.post('/settings/fields/delete', field)
	}
}
