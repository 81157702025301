import Vue from 'vue'

export function fromEntries (arr) {
	return Object.assign({}, ...Array.from(arr, ([k, v]) => ({[k]: v})))
}

export function intKeys (object) {
	return Object.keys(object).map(k => parseInt(k))
}

export function intKeyedEntries (object) {
	return Object.entries(object).map(e => [ parseInt(e[0]), e[1] ])
}

export function subtract (minuend, subtrahend) {
	return minuend.filter((i) => !subtrahend.includes(i))
}

export function arrayEquals (left, right) {
	return subtract(left, right).length === 0 && subtract(right, left).length === 0
}

export function makeArray (item, length) {
	let arr = []
	for (let i = 0; i < length; i++) {
		arr.push(item)
	}
	return arr
}

export function copy (object) {
	return Vue.util.extend({}, object)
}

export function _call (fun, ...args) {
	if (fun && fun.constructor === Function) {
		return fun(...args)
	}
}

export function pad (num, length) {
	return (makeArray('0', length - 1).join('') + num).slice(-length)
}

export function formatNumber (number) {
	let pieces = ('' + number).split('.')
	let ret = pieces[0].split('').reverse().join('').match(/(.{1,3})/g).join(' ').split('').reverse().join('')
	if (pieces[1]) {
		ret += '.' + pieces[1]
	}
	return ret
}

export function formatCurrency (sum) {
	let pieces = ('' + sum).split('.')
	let ret = pieces[0].split('').reverse().join('').match(/(.{1,3})/g).join(',').split('').reverse().join('')
	if (pieces[1]) {
		ret += '.' + pieces[1]
	}
	return ret
}

export function formatDate (date) {
	let day = date.getDate()
	let month = date.getMonth()
	let year = date.getFullYear()
	let hours = date.getHours()
	let minutes = date.getMinutes()
	let seconds = date.getSeconds()
	return pad(day, 2) + '.' + pad(month + 1, 2) + '.' + year + ' ' + pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2)
}

export function formatValue (type, value) {
	switch (type) {
	case 'Integer':
	case 'Long':
		return formatNumber(value)
	case 'Amount':
		return formatCurrency(value)
	case 'Timestamp':
		return formatDate(new Date(value))
	default:
		return value
	}
}

export function isInt (value) {
	return '' + parseInt(value) === '' + value
}
