import {_call} from '@/helpers/javascript'
import throttle from 'lodash.throttle'

export default function (alphaMin, exposeEntitiesCallback) {
	let entityNodes = []
	let progress = true
	let exposeEntities = throttle(() => {
		_call(exposeEntitiesCallback, entityNodes)
	}, 250, { leading: true })
	function f (alpha) {
		if (progress) {
			if (alpha < alphaMin) {
				entityNodes.forEach((node) => {
					node.fx = node.x
					node.fy = node.y
				})
				progress = false
			}
			exposeEntities()
		} else {
			if (alpha > alphaMin) {
				progress = true
			}
		}
	}

	f.initialize = function (nodes) {
		entityNodes = nodes.filter((n) => !n.hostId)
		progress = false
	}

	f.reset = function () {
		progress = false
	}

	return f
}
