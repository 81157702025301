<template>
	<mdb-col class="bg-white text-dark" v-bind="colBind">
		<data-table ref="delegate" :data="{ columns, rows }" :pagination="pagination" :searching="search" nulls-last refresh striped bordered class="align-self-center w-100" v-on="on">
      <template v-slot:pre-fieldset>
        <slot name="pre-fieldset"></slot>
      </template>
      <template v-slot:pre-th>
        <slot name="pre-th"></slot>
      </template>
      <template v-slot:post-th>
        <slot name="post-th">
          <th></th>
        </slot>
      </template>
      <template v-slot:pre-th-foot>
        <slot name="pre-th-foot"></slot>
      </template>
      <template v-slot:post-th-foot>
        <th></th>
      </template>
      <template v-slot:pre-td="{ row }">
        <slot name="pre-td" :row="row"></slot>
      </template>
			<template v-for="column in columns" v-slot:[`_`+column.field]="{ field, row, index, value }">
        <div v-if="column.readonly || column.id" :key="column.field">
          <slot :name="'__' + field" :field="field" :row="row" :index="index" :value="value">
            <div v-html="value"></div>
          </slot>
        </div>
        <div v-else :key="column.field">
          <slot :name="'__' + field" :field="field" :row="row" :index="index" :value="value">
            <input class="w-100" :value="value" @change="change({ field, row, index, value: $event.target.value })"/>
          </slot>
        </div>
			</template>
      <template v-slot:post-td="{ row }">
        <td>
          <mdb-btn @click.native.prevent="remove(row)" color="danger" size="sm" rounded>Remove</mdb-btn>
        </td>
      </template>
			<tr slot="last-row">
				<slot name="pre-td" :row="newRow"></slot>
				<td v-for="(column, index) in columns" :key="index">
					<div v-if="column.readonly" :key="column.field">
						<slot :name="'__' + column.field" :field="column.field" :row="newRow" :index="index" :value="newRow[index]">
							<div v-html="newRow.row[index]"></div>
						</slot>
					</div>
					<div v-else :key="column.field">
						<slot :name="'__' + column.field" :field="column.field" :row="newRow" :index="index" :value="newRow[index]">
							<input class="w-100" :value="newRow[index]" @change="change({ field: column.field, row: newRow, index, value: $event.target.value })"/>
						</slot>
					</div>
				</td>
				<td><mdb-btn @click.native.prevent="add" color="success" size="sm" rounded>Add</mdb-btn></td>
			</tr>
      <template v-slot:post-table>
        <slot name="post-table"></slot>
      </template>
		</data-table>
	</mdb-col>
</template>

<script>
import { mdbBtn } from 'mdbvue'
import { mapState } from 'vuex'
import DataTable from '@/components/common/DataTable.vue'

export default {
	name: 'EditTable',
	components: { mdbBtn, DataTable },
	props: {
		col: [String, Object],
		columns: {
			type: Array,
			required: true
		},
		data: {
			type: Array,
			required: true
		},
		pagination: {
			type: Boolean,
			default: true
		},
		search: {
			type: Boolean,
			default: true
		}
	},
	data () {
		return {
			newRowRow: {},
		}
	},
  mounted () {
    this.initNewRowRow()
  },
  computed: {
    idColumn () {
      return this.columns.findLast(c => c.field === 'id' || c.id).field || 'id'
    },
		rows () {
			return this.data // .concat({ id: null })
		},
		colBind () {
			if (!this.col) {
				return {}
			} else if (this.col.constructor === Object) {
				return this.col
			} else {
				return { col: this.col }
			}
		},
		newRow () {
			let row = this.columns.map(c => this.newRowRow[c.field] || null)
			row.row = this.newRowRow
			row.key = null
			return row
		},
		on () {
			return Object.assign({}, this.proxyListener('sync'), this.proxyListener('pages'), this.proxyListener('sort'))
		},
		...mapState([ 'locale' ])
	},
	watch: {
		rows () {
      this.initNewRowRow()
		}
	},
	methods: {
    proxyListener (key) {
      let l = this.$listeners[key]
      return l && { [key]: l }
    },
		change ({ field, row, value }) {
      let targetValue = value.target ? value.target.value : value
			if (targetValue !== '' && targetValue !== row.row[field]) {
				if (this.isNew(row)) {
          this.$set(this.newRowRow, field, targetValue)
				} else {
					this.$emit('update', Object.assign({}, row.row, {[field]: targetValue}))
				}
			}
		},
		isNew (row) {
			return row.key === null
		},
		remove (row) {
			if (!this.isNew(row) && confirm(this.$t('common.confirm-removal', this.locale))) {
				this.$emit('delete', row.row)
			}
		},
		add () {
			this.$emit('update', this.newRow.row)
		},
    initNewRowRow () {
      this.newRowRow = { [this.idColumn]: null }
    }
	}
}
</script>

<style scoped>
>>> .md-form {
	margin-top: 0;
	margin-bottom: 0;
}
>>> input.select-dropdown {
	margin-bottom: 0;
}
</style>
