import { api } from '@/helpers/core'

export default {
	get (queryObject) {
		return api.post('/monitoring/data/', queryObject)
	},
	export (queryObject) {
		return api.post('/monitoring/data/export', queryObject)
	},
	txRules (id) {
		return api.get('/monitoring/rules/' + id)
	}
}
