<template>
	<mdb-col class="pb-1" xl="1_67" lg="3" md="4" sm="6" col="12">
		<null-filter :disabled="disabled" v-model="isFromNull"/>
		<label :for="kind + '-' + name + '-from'" class="filter small">{{ label }}</label>
		<null-filter :disabled="disabled" v-model="isToNull"/><br/>
		<template v-if="isTimestamp">
			<date-picker v-model.lazy="fromDate" :disabled="disabled || isFromNull" class="d-inline-block from" input-class="form-control pb-0 pt-0 d-inline-block text-center from"></date-picker>
			<date-picker v-model.lazy="toDate" :disabled="disabled || isToNull" class="d-inline-block to" input-class="form-control pb-0 pt-0 d-inline-block text-center to"></date-picker>
		</template>
		<template v-else>
			<input type="text" :id="kind + '-' + name + '-from'" v-model.lazy="from" :disabled="disabled || isFromNull" class="form-control pb-0 pt-0 d-inline-block text-center from">
			<input type="text" :id="kind + '-' + name + '-to'" v-model.lazy="to" :disabled="disabled || isToNull" class="form-control pb-0 pt-0 d-inline-block text-center to">
		</template>
	</mdb-col>
</template>

<script>
import { isTimestampType } from '@/helpers/common'
import { pad } from '@/helpers/javascript'

import DatePicker from '@/components/common/DatePicker'
import NullFilter from './NullFilter'

export default {
	name: 'RangeFilter',
	components: { DatePicker, NullFilter },
	props: {
    type: String,
		kind: String,
		name: String,
    disabled: Boolean,
    label: String,
    value: Object
	},
	computed: {
		from: {
			get () {
				return this.value && this.value.from && this.value.from.value || null
			},
			set (value) {
        let from = { isNull: false, value }
        this.$emit('input', Object.assign({}, this.value, { from }))
			}
		},
		to: {
			get () {
				return this.value && this.value.to && this.value.to.value || null
			},
			set (value) {
        let to = { isNull: false, value }
        this.$emit('input', Object.assign({}, this.value, { to }))
			}
		},
		fromDate: {
			get () {
				return this.value && this.value.from && this.value.from.value
            && this.formatDate(new Date(this.value.from.value)) || null
			},
			set (value) {
        let from = { isNull: false, value: (new Date(value)).getTime() }
        this.$emit('input', Object.assign({}, this.value, { from }))
			}
		},
		toDate: {
			get () {
				return this.value && this.value.to && this.value.to.value
            && this.formatDate(new Date(this.value.to.value)) || null
			},
			set (value) {
        let to = { isNull: false, value: (new Date(value)).getTime() }
        this.$emit('input', Object.assign({}, this.value, { to }))
			}
		},
		isFromNull: {
      get () {
        return this.value && this.value.from && this.value.from.isNull
      },
      set (isNull) {
        let from = { isNull }
        this.$emit('input', Object.assign({}, this.value, { from }))
      }
		},
		isToNull: {
      get () {
        return this.value && this.value.to && this.value.to.isNull
      },
      set (isNull) {
        let to = { isNull }
        this.$emit('input', Object.assign({}, this.value, { to }))
      }
		},
    isTimestamp () {
      return isTimestampType(this.type)
    }
	},
	methods: {
		formatDate (date) {
			if (date) {
				let day = date.getDate()
				let month = date.getMonth()
				let year = date.getFullYear()
				return year + '-' + pad(month + 1, 2) + '-' + pad(day, 2)
			}
		}
	}
}
</script>

<style scoped>
>>> input {
	padding-left: 0.2rem;
	padding-right: 0.2rem;
	height: calc(1em + 0.75rem + 1px);
}
>>> input.from, >>> .mdb-vue-date.from {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	width: 48%;
}
>>> input.to, >>> .mdb-vue-date.to {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	width: 48%;
}
>>> .mdb-vue-date.from input.from, >>> .mdb-vue-date.to input.to {
	width: 100%;
}
</style>
