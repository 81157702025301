<template>
	<tr>
		<td class="py-2 text-left fs-1 font-weight-bold">
      {{ name }}
    </td>
		<td class="py-2 text-left fs-1 font-weight-bold">
      <mdb-switch v-if="initialized" :on-label="null" :off-label="null" :checked="presence" @getValue="toggle"/>
    </td>
		<td class="py-2 text-left fs-1 font-weight-bold">
      <mdb-input :label="$t('lists.reason-label', locale)" :value="reason" :disabled="!presence" @change="updateReason"/>
    </td>
		<td class="py-2 text-left fs-1 font-weight-bold">
      {{ added }}
    </td>
	</tr>
</template>

<script>
import { mdbInput, mdbSwitch } from 'mdbvue'
import { mapState } from 'vuex'
import { formatValue } from '@/helpers/javascript'

export default {
	name: 'EntityList',
	components: { mdbInput, mdbSwitch },
	props: {
		name: {
			type: String,
			required: true
		},
		initialized: {
			type: Boolean,
			required: true
		}
	},
	data () {
		return {
			previousPresence: null
		}
	},
	computed: {
		entityList () {
			return this.entityLists[this.name]
		},
		presence () {
			return !!this.entityList
		},
		reason () {
			return this.entityList && this.entityList.reason
		},
		added () {
			return this.entityList && this.entityList.added && formatValue('Timestamp', this.entityList.added)
		},
		...mapState([ 'locale' ]),
		...mapState('lists', [ 'entityLists' ])
	},
	created () {
		this.previousPresence = this.presence
	},
	methods: {
		toggle (value) {
			if (this.initialized) {
				if (value) {
					this.$emit('add', this.name, this.reason || this.$t('lists.reason-default', this.locale))
				} else {
					this.$emit('remove', this.name)
				}
			}
		},
		updateReason (value) {
			if (this.previousPresence) {
				if (this.presence && value !== this.reason && value !== '') {
					this.$emit('update-reason', this.name, value)
				}
			} else {
				this.previousPresence = this.presence
			}
		}
	}
}
</script>

<style scoped>
.md-form {
	margin-top: 0;
	margin-bottom: 0;
}
</style>
