<template>
	<label class="no-select px-1 mb-0 text-white small" :class="labelClass" :style="labelStyle">
		<input type="checkbox" class="d-none" :disabled="disabled" :value="value" @change="$emit('input', $event.target.value === 'false')"/>
		null
	</label>
</template>

<script>
export default {
	name: 'NullFilter',
	props: {
		disabled: Boolean,
		value: Boolean
	},
	computed: {
		labelClass () {
			if (this.value) {
				return [ 'bg-primary' ]
			} else {
				return [ 'bg-dark' ]
			}
		},
		labelStyle () {
			if (!this.value) {
				return { opacity: 0.5 }
			} else {
        return {}
      }
		}
	}
}
</script>

<style scoped>

</style>
