export const messages = {
	'graph': {
		'node-types': {
			'filter-label': 'Выбор типа узла',
			'select-label': 'Выбор типа узлов графа'
		}
	},
	'profile': {
		'log-in': 'Войти',
		'log-out': 'Выйти',
		'login-label': 'Логин',
		'password-label': 'Пароль'
	},
	'lists': {
		'list-label': 'Список',
		'list-select': 'Выбор списка',
		'definition-label': 'Тип',
		'definition-select': 'Выбор типа',
		'value-label': 'Значение',
		'added-label': 'Дата добавления',
		'reason-label': 'Причина добавления',
		'reason-default': 'Добавлено через админку'
	},
	'node-details-popup': {
		'graph': 'Граф',
		'transactions': 'Транзакции'
	},
	'monitoring': {
		'fired-rule-filter': 'Фильтр по сработавшему правилу',
		'reset-rule-filter': 'Сбросить фильтр'
	},
	'rules': {
		'fields': {
			'name-label': 'Название',
			'code-label': 'Код',
			'type-label': 'Тип',
			'type-select': 'Выбор типа',
			'entity': 'Свойство',
			'function': 'Выражение',
			'aggregate': 'Агрегат'
		},
		'rulesets': {
			'new': 'Создать набор правил'
		}
	},
	'bindings': {
		'commit': 'Сохранить схему привязок',
		'rollback': 'Откатить схему привязок',
		'edit': 'Редактировать схему привязок'
	},
	'common': {
		'confirm-removal': 'Подтвердите удаление?',
		'update': 'Обновить',
		'delete': 'Удалить',
		'save': 'Сохранить',
		'reset': 'Сбросить выбор',
		'new': 'Добавить',
		'add': 'Добавить',
		'remove': 'Удалить',
		'show': 'Показать',
		'hide': 'Скрыть'
	}
}
