import Vue from 'vue'
import Vuex from 'vuex'

import packageInfo from '@/../package'

import call from '@/helpers/call'

import graph from './modules/graph'
import properties from './modules/properties'
import monitoring from './modules/monitoring'
import rules from './modules/rules'
import bindings from './modules/bindings'
import schema from './modules/schema'
import lists from './modules/lists'

Vue.use(Vuex)

const state = {
	showErrorPopup: true,
	errors: [],
	user: { pending: true },
	locale: null,
	backendVersion: null,
	frontendVersion: packageInfo.version
}

const getters = {
	authorized (state) {
		return !state.user.pending && state.user.role !== 'unauthorized'
	}
}

const actions = {
	logIn ({ dispatch, commit }, { login, password }) {
		call('auth', 'logIn', {
			resolve () {
				dispatch('fetchUser')
			},
			reject (error) {
				commit('ADD_ERROR', error)
			}
		}, { login, password })
	},

	logOut ({ dispatch, commit }) {
		call('auth', 'logOut', {
			resolve () {
				dispatch('fetchUser')
			},
			reject (error) {
				commit('ADD_ERROR', error)
			}
		})
	},

	fetchUser ({ commit }) {
		call('auth', 'get', {
			resolve (data) {
				commit('SET_USER', data)
			},
			reject (error) {
				commit('ADD_ERROR', error)
			}
		})
	},

	fetchVersion ({ commit }) {
		call('schema', 'getVersion', {
			resolve (data) {
				commit('SET_BACKEND_VERSION', data.version)
			},
			reject (error) {
				commit('ADD_ERROR', error)
			}
		})
	}
}

const mutations = {
	SET_LOCALE (state, locale) {
		state.locale = locale
	},

	SET_BACKEND_VERSION (state, backendVersion) {
		state.backendVersion = backendVersion
	},

	SET_USER (state, user) {
		state.user = user
	},

	ADD_ERROR (state, error) {
		console.error(error)
		state.showErrorPopup = true
		state.errors.push(error)
	},

	FLUSH_ERROR (state, index) {
		state.errors.splice(index, 1)
	},

	HIDE_ERROR_POPUP (state) {
		state.showErrorPopup = false
	}
}

const strict = process.env.NODE_ENV !== 'production'

const modules = {
	graph,
	properties,
	monitoring,
	rules,
	bindings,
	schema,
	lists
}

export default new Vuex.Store({
	state,
	getters,
	actions,
	mutations,
	strict,
	modules
})
