import call from '@/helpers/call'

import { _call, fromEntries } from '@/helpers/javascript'

// initial state
const state = {
	bindings: {},
	rootBindingId: null
}

// getters
const getters = {
	bindingsEditMode (state) {
		return !!state.rootBindingId
	}
}

// actions
const actions = {
	fetchBindings ({ getters, commit }, { after } = {}) {
		if (!getters.bindingsEditMode) {
			call('bindings', 'getBindings', {
				resolve (data) { commit('SET_BINDINGS', data); commit('UNSET_BINDINGS_ROOT_ID'); _call(after) },
				reject (error) { commit('ADD_ERROR', error, { root: true }) }
			})
		} else {
			commit('ADD_ERROR', { message: 'Попытка загрузить схему привязок в режиме редактирования' }, { root: true })
		}
	},

	updateBinding ({ getters, commit }, binding) {
		if (getters.bindingsEditMode) {
			call('bindings', 'updateBinding', {
				resolve (data) { commit('SET_BINDINGS', data) },
				reject (error) { commit('ADD_ERROR', error, { root: true }) }
			}, binding)
		} else {
			commit('ADD_ERROR', { message: 'Попытка редактирования схемы привязок вне режиме редактирования' }, { root: true })
		}
	},

	deleteBinding ({ getters, state, commit }, binding) {
		if (getters.bindingsEditMode) {
			if (!Object.keys(state.bindings).filter(k => k.startsWith(state.rootBindingId) && k !== binding.id).length) {
				alert('Удаление последней привязки идентификатора транзакции запрещено, при необходимости используйте редактирование')
			} else {
				call('bindings', 'deleteBinding', {
					resolve (data) { commit('SET_BINDINGS', data) },
					reject (error) { commit('ADD_ERROR', error, { root: true }) }
				}, binding)
			}
		} else {
			commit('ADD_ERROR', { message: 'Попытка редактирования схемы привязок вне режиме редактирования' }, { root: true })
		}
	},

	beginBindingTransaction ({ getters, commit }) {
		if (!getters.bindingsEditMode) {
			call('bindings', 'beginBindingTransaction', {
				resolve (data) { commit('SET_BINDINGS', data); commit('SET_BINDINGS_ROOT_ID') },
				reject (error) { commit('ADD_ERROR', error, { root: true }) }
			})
		} else {
			commit('ADD_ERROR', { message: 'Попытка начала нового сеанса редактирования схемы привязок во время активного сеанса редактирования' }, { root: true })
		}
	},

	commitBindingTransaction ({ state, getters, commit }, { rootPath }) {
		if (getters.bindingsEditMode) {
			call('bindings', 'commitBindingTransaction', {
				resolve (data) { commit('SET_BINDINGS', data); commit('UNSET_BINDINGS_ROOT_ID') },
				reject (error) { commit('ADD_ERROR', error, { root: true }) }
			}, { rootId: state.rootBindingId, rootPath })
		} else {
			commit('ADD_ERROR', { message: 'Попытка завершить несуществующий или уже завершённый сеанс редактирования схемы привязок' }, { root: true })
		}
	},

	rollbackBindingTransaction ({ state, getters, commit }, { rootPath }) {
		if (getters.bindingsEditMode) {
			call('bindings', 'rollbackBindingTransaction', {
				resolve (data) { commit('SET_BINDINGS', data); commit('UNSET_BINDINGS_ROOT_ID') },
				reject (error) { commit('ADD_ERROR', error, { root: true }) }
			}, { rootId: state.rootBindingId, rootPath })
		} else {
			commit('ADD_ERROR', { message: 'Попытка завершить несуществующий или уже завершённый сеанс редактирования схемы привязок' }, { root: true })
		}
	}
}

// mutations
const mutations = {
	SET_BINDINGS (state, bindings) {
		state.bindings = fromEntries(bindings.map(b => [ b.id, b ]))
	},

	SET_BINDINGS_ROOT_ID (state) {
		state.rootBindingId = Object.values(state.bindings).map(b => b.rootId).filter(r => r)[0]
	},

	UNSET_BINDINGS_ROOT_ID (state) {
		state.rootBindingId = null
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
