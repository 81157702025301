export default {
	definitions () {
		return []
	},
	addDefinitions (ids) {
		return ids
	},
	removeDefinitions () {
		return { done: true }
	},
	init ({ definitionName, value }) {
		let item = { name: definitionName, value, txIds: [] }
		return { _: item }
	},
	neighbors ({ sourceId, targetName }) {
		let items
		switch (sourceId) {
		case 1:
			if (targetName === 'bank_card') {
				items = [ { id: 2, value: '79991234567', txIds: [ 12345678, 12345676 ] } ]
			} else if (targetName === 'phone') {
				items = [ { id: 3, value: 'ADF456H12C', txIds: [ 12345675 ] } ]
			} else {
				items = []
			}
			break
		case 2:
		case 3:
			items = []
			break
		}
		return items
	},
	txData () {
		return []
	}
}
