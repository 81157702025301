<template>
	<mdb-col v-bind="colBind" class="properties arrange my-1">
		<mdb-row class="pr-1">
			<mdb-col col="12" class="bg-white">
				<mdb-row class="text-dark text-uppercase">
					<h2 class="mx-2 my-1 mx-auto">{{ label }}</h2>
				</mdb-row>
				<mdb-row class="properties arrange group mx-2 my-2">
					<template v-for="clazz in classes">
						<mdb-col :key="'h3-' + clazz" col="12">
							<h3 class="mx-2 my-1 text-uppercase text-dark">{{ clazzLabel(clazz) }}</h3>
						</mdb-col>
						<draggable :key="clazz" class="col-12" :list="propertiesMap[clazz]" v-bind="dragOptions" :move="onMove" @change="onChange({ role, clazz }, $event)" :componentData="{ role, clazz }">
							<property-render v-for="(property, index) in propertiesMap[clazz]" :key="index" :property="property" class="mx-1 my-2"></property-render>
						</draggable>
					</template>
				</mdb-row>
			</mdb-col>
		</mdb-row>
	</mdb-col>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Draggable from 'vuedraggable'
import { fromEntries } from '@/helpers/javascript'
import PropertyRender from '@/components/common/PropertyRender.vue'

export default {
	name: 'ArrangeProperties',
	components: { Draggable, PropertyRender },
	props: {
		role: {
			type: String,
			required: true
		},
		col: [String, Object]
	},
  data () {
    return {
      editing: false
    }
  },
	computed: {
		label () {
      switch (this.role) {
        case 'Filter': return 'Search'
        case 'Projection': return 'Columns'
        case 'Graph': return 'Graph'
        default: return ''
			}
		},
		classes () {
			return [ 'none', 'base', 'aux' ]
		},
		all () {
			return Object.values(this.allProps)
		},
    properties () {
      switch (this.role) {
        case 'Filter': return this.filterProps
        case 'Projection': return this.projectionProps
        case 'Graph': return this.graphProps
        default: return { base: [], aux: [] }
      }
    },
		propertiesMap () {
			return fromEntries(this.classes.map(clazz => {
				let items
				if (clazz === 'none') {
					items = Object.entries(this.allProps).filter(f => !this.properties.base.concat(this.properties.aux).includes(f[0])).map(f => f[1])
				} else {
					items = this.properties[clazz].map(f => this.allProps[f])
				}
				items = items.map(f => Object.assign({}, f, { role: this.role, clazz }))
				return [ clazz, items ]
			}))
		},
		dragOptions () {
			return {
				animation: 0,
				disabled: this.editing,
				draggable: '.property-wrapper',
				group: 'properties',
				ghostClass: 'ghost',
				tag: 'div'
			}
		},
		colBind () {
			if (!this.col) {
				return {}
			} else if (this.col.constructor === Object) {
				return this.col
			} else {
				return { col: this.col }
			}
		},
		...mapState('schema', [ 'entityDefinitions' ]),
    ...mapState('properties', [ 'allProps', 'filterProps', 'projectionProps', 'graphProps' ])
	},
	methods: {
		onMove ({ relatedContext, draggedContext }) {
			if (!draggedContext.element) {
				console.error('Dragged context has no element! See dragged context contents:', draggedContext)
				return false
			} else {
				let relatedElement = relatedContext.element
				let draggedElement = draggedContext.element
				return relatedContext.component.componentData.role === draggedElement.role &&
					(draggedElement.role !== 'Projection' || ((draggedElement.path !== this.$constants.rootPath || draggedContext.index !== 0) &&
						(!relatedElement || relatedElement.path !== this.$constants.rootPath || draggedContext.futureIndex !== 0))
					)
			}
		},
		onChange ({ role, clazz }, event) {
			let eventData
			if ('moved' in event) {
				eventData = event.moved
			} else if ('added' in event) {
				eventData = event.added
			} else {
				return
			}
			let newIndex
			if (clazz === 'none') {
				if ('moved' in event) {
					return
				} else {
					newIndex = null
				}
			} else if (clazz === 'base') {
				newIndex = eventData.newIndex
			} else if (clazz === 'aux') {
				newIndex = -eventData.newIndex - 1
			} else {
				return
			}
			this.replaceProperty({ name: eventData.element.name, role, newIndex })
		},
		clazzLabel (clazz) {
			switch (clazz) {
			case 'none': return 'Unused'
			case 'base': return 'Main'
			case 'aux': return 'Extra'
			}
		},
		...mapActions('properties', [ 'replaceProperty' ])
	}
}
</script>

<style scoped>
.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}
.properties.arrange.group {
	background-color: rgba(0,0,0,.05);
	border: 2px solid #dee2e6;
}
</style>
