import { x, y } from 'd3-force/src/simulation'
import { quadtree } from 'd3-quadtree'
import constant from 'd3-force/src/constant'
import { jiggle } from './index'

export default function () {
	var nodes
	var node
	var alpha
	var strength = constant(-30)
	var strengths
	var distanceMin2 = 1
	var distanceMax2 = Infinity
	var theta2 = 0.81

	function force (_) {
		var i
		var n = nodes.length
		var tree = quadtree(nodes, x, y).visitAfter(accumulate)
		for (alpha = _, i = 0; i < n; ++i) {
			node = nodes[i]
			tree.visit(apply)
		}
	}

	function initialize () {
		if (!nodes) return
		var i
		var n = nodes.length
		var node
		strengths = new Array(n)
		for (i = 0; i < n; ++i) {
			node = nodes[i]
			strengths[node.index] = +strength(node, i, nodes)
		}
	}

	function accumulate (quad) {
		var strength = 0
		var q
		var c
		var weight = 0
		var x
		var y
		var i

		// For internal nodes, accumulate forces from child quadrants.
		if (quad.length) {
			for (x = y = i = 0; i < 4; ++i) {
				if ((q = quad[i]) && (c = Math.abs(q.value))) {
					strength += q.value
					weight += c
					x += c * q.x
					y += c * q.y
				}
			}
			quad.x = x / weight
			quad.y = y / weight
		} else {
			// For leaf nodes, accumulate forces from coincident quadrants.
			q = quad
			q.x = q.data.x
			q.y = q.data.y
			while (q) {
				strength += strengths[q.data.index]
				q = q.next
			}
		}
		quad.value = strength
	}

	function apply (quad, x1, _, x2) {
		if (!quad.value) return true

		var x = quad.x - node.x
		var y = quad.y - node.y
		var w = x2 - x1
		var l = x * x + y * y

		// Apply the Barnes-Hut approximation if possible.
		// Limit forces for very close nodes; randomize direction if coincident.
		if (w * w / theta2 < l) {
			if (l < distanceMax2) {
				if (x === 0) {
					x = jiggle()
					l += x * x
				}
				if (y === 0) {
					y = jiggle()
					l += y * y
				}
				if (l < distanceMin2) l = Math.sqrt(distanceMin2 * l)
				node.vx += x * quad.value * alpha / l
				node.vy += y * quad.value * alpha / l
			}
			return true
		} else {
			// Otherwise, process points directly.
			if (quad.length || l >= distanceMax2) return
		}

		// Limit forces for very close nodes; randomize direction if coincident.
		if (quad.data !== node || quad.next) {
			if (x === 0) {
				x = jiggle()
				l += x * x
			}
			if (y === 0) {
				y = jiggle()
				l += y * y
			}
			if (l < distanceMin2) l = Math.sqrt(distanceMin2 * l)
		}
		while (quad) {
			if (quad.data !== node) {
				w = strengths[quad.data.index] * alpha / l
				node.vx += x * w
				node.vy += y * w
			}
			quad = quad.next
		}
	}

	force.initialize = function (_) {
		nodes = _.filter((n) => !n.hostId)
		initialize()
	}

	force.strength = function (_) {
		if (arguments.length) {
			strength = typeof _ === 'function' ? _ : constant(+_)
			initialize()
			return force
		} else {
			return strength
		}
	}

	force.distanceMin = function (_) {
		if (arguments.length) {
			distanceMin2 = _ * _
			return force
		} else {
			return Math.sqrt(distanceMin2)
		}
	}

	force.distanceMax = function (_) {
		if (arguments.length) {
			distanceMax2 = _ * _
			return force
		} else {
			return Math.sqrt(distanceMax2)
		}
	}

	force.theta = function (_) {
		if (arguments.length) {
			theta2 = _ * _
			return force
		} else {
			return Math.sqrt(theta2)
		}
	}

	return force
}
