<template>
  <boolean-filter v-if="isBoolean" :kind="kind" :name="name"/>
  <match-filter v-else-if="isMatch" :kind="kind" :name="name" :disabled="disabled" :label="label" v-model="filterValue"/>
  <range-filter v-else-if="isRange" :type="entity && entity.type" :kind="kind" :name="name" :disabled="disabled" :label="label" v-model="filterValue"/>
</template>

<script>
import { isBooleanType, isMatchType, isRangeType } from '@/helpers/common'

import BooleanFilter from './BooleanFilter'
import MatchFilter from './MatchFilter'
import RangeFilter from './RangeFilter'
import {mapActions, mapState} from "vuex";

export default {
  name: 'FilterGroup',
  components: { BooleanFilter, MatchFilter, RangeFilter },
  props: {
    name: String,
    kind: String,
    aux: Boolean
  },
  computed: {
    isMatch() {
      return isMatchType(this.entity && this.entity.type)
    },
    isBoolean() {
      return isBooleanType(this.entity && this.entity.type)
    },
    isRange() {
      return isRangeType(this.entity && this.entity.type)
    },
    entity() {
      if (this.kind === 'property') {
        return this.entityDefinitions[this.property && this.property.definitionName]
      } else if (this.kind === 'entity') {
        if (this.name === this.entityFilterName) {
          return this.entityDefinitions[this.name]
        } else {
          console.error('Тип объекта в фильтре [0] не соответствует ожидаемому [1]', this.name, this.entityFilterName)
          return null
        }
      } else {
        console.error('Неизвестный вид фильтра', this.kind)
        return null
      }
    },
    property() {
      if (this.kind === 'property') {
        return this.allProps[this.name]
      } else {
        return null
      }
    },
    enabled () {
      return this.filterAux || !this.aux
    },
    disabled() {
      return !this.enabled
    },
    label() {
      return (this.property && this.property.name) || (this.entity && this.entity.label)
    },
    filterValue: {
      get () {
        if (this.property) {
          let filterValue = this.propertiesFilterValues[this.name]
          if (!filterValue) {
            if (this.missingFilterValuesSet) {
              console.error('Не найден источник данных для фильтра', this.property)
            }
            return null
          } else {
            return filterValue
          }
        } else if (this.entity) {
          return this.entityFilterValue
        } else {
          console.error('Не определён источник данных для фильтра')
          return null
        }
      },
      set (newFilterValue) {
        if (this.property) {
          this.setPropertyFilterValue(Object.assign(newFilterValue, { name: this.name }))
        } else if (this.entity) {
          this.setEntityFilterValue(Object.assign(newFilterValue, { name: this.name }))
        } else {
          console.error('Не найден контейнер данных для фильтра при попытке обновить значение', this.name)
        }
      }
    },
    ...mapState('monitoring', [ 'filterAux', 'missingFilterValuesSet' ]),
    ...mapState('monitoring/filterValues', [ 'entityFilterName', 'entityFilterValue', 'propertiesFilterValues' ]),
    ...mapState('properties', [ 'allProps' ]),
    ...mapState('schema', [ 'entityDefinitions' ])
  },
  methods: {
    ...mapActions('monitoring/filterValues', [ 'setEntityFilterValue', 'setPropertyFilterValue' ])
  }
}
</script>

<style>

</style>
