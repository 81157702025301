import Vue from 'vue'
import { fromEntries } from "@/helpers/javascript"
import { toFilterValue, toQuery } from "@/helpers/monitoring"

// initial state
const state = {
    entityFilterName: null,
    entityFilterValue: null,
    ruleFilterValue: null,
    propertiesFilterValues: {}
}

// getters
const getters = {
    propertiesFilterQuery (state) {
        return fromEntries(Object.entries(state.propertiesFilterValues)
            .filter(e =>
                e[1].value || e[1].isNull
                || e[1].from && (e[1].from.value || e[1].from.isNull)
                || e[1].to && (e[1].to.value || e[1].to.isNull)
            ).map(e => [e[0], toQuery(e[1])]))
    },
    entityFilterQuery (state) {
        let filterName = state.entityFilterName;
        let filterValue = state.entityFilterValue;
        if (filterName && filterValue) {
            return { [filterName]: toQuery(filterValue) }
        } else {
            return {}
        }
    }
}

// actions
const actions = {
    setPropertyFilterValue ({ commit, rootState }, { name, value = null, isNull = false, from = null, to = null }) {
        let property = rootState.properties.allProps[name]
        if (!property) {
            console.error('Попытка установить значение для неопределённого свойства:', { name, value, isNull, from, to })
        } else {
            let entityDefinition = rootState.schema.entityDefinitions[property.definitionName]
            if (!entityDefinition) {
                console.error('Попытка установить значение неопределённого типа:',
                    { name, definitionName: property.definitionName, value, isNull, from, to })
            } else {
                let path = property.path
                let v = toFilterValue(entityDefinition, name, value, isNull, from, to)
                if (v) {
                    commit('SET_PROPERTY_FILTER_VALUE', { name, value: Object.assign(v, { path }) })
                }
            }
        }
    },
    setEntityFilterValue ({ commit, rootState }, { name, value = null, isNull = false, from = null, to = null }) {
        let entityDefinition = rootState.schema.entityDefinitions[name]
        if (!entityDefinition) {
            console.error('Попытка установить значение для неопределённого типа объекта:', { name, value, isNull, from, to })
        } else {
            let v = toFilterValue(entityDefinition, name, value, isNull, from, to)
            if (v) {
                commit('SET_ENTITY_FILTER_VALUE', { name, value: v })
            }
        }
    }
}

// mutations
const mutations = {
    SET_PROPERTY_FILTER_VALUE (state, { name, value }) {
        Vue.set(state.propertiesFilterValues, name, value)
    },
    SET_RULE_FILTER_VALUE (state, value) {
        state.ruleFilterValue = value
    },
    SET_ENTITY_FILTER_VALUE (state, { name, value }) {
        state.entityFilterName = name
        state.entityFilterValue = value
    },
    DROP_PROPERTY_FILTER_VALUE (state, name) {
        Vue.delete(state.propertiesFilterValues, name)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
