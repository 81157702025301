import constant from 'd3-force/src/constant'
import { jiggle } from './index'

function index (d) {
	return d.index
}

function find (nodeById, nodeId) {
	let node = nodeById.get(nodeId)
	if (!node) throw new Error('missing: ' + nodeId)
	return node
}

export default function (links) {
	var id = index
	var strength = defaultStrength
	var strengths
	var distance = constant(30)
	var distances
	var nodes
	var count
	var bias
	var iterations = 1

	if (links == null) links = []

	function defaultStrength (link) {
		return 1 / Math.min(count[link.source.index], count[link.target.index])
	}

	function force (alpha) {
		for (let k = 0, n = links.length; k < iterations; ++k) {
			for (let i = 0, link, source, target, x, y, l, b; i < n; ++i) {
				link = links[i]
				if (!link.targetHost) {
					source = link.sourceHost || link.source
					target = link.target
					x = target.x + target.vx - source.x - source.vx || jiggle()
					y = target.y + target.vy - source.y - source.vy || jiggle()
					l = Math.sqrt(x * x + y * y)
					l = (l - distances[i]) / l * alpha * strengths[i]
					x *= l
					y *= l
					target.vx -= x * (b = bias[i])
					target.vy -= y * b
					source.vx += x * (b = 1 - b)
					source.vy += y * b
				}
			}
		}
	}

	function initialize () {
		if (!nodes) return
		var i
		var n = nodes.length
		var m = links.length
		var nodeById = new Map(nodes.map((d, i) => [id(d, i, nodes), d]))
		var link

		for (i = 0, count = new Array(n); i < m; ++i) {
			link = links[i]
			link.index = i
			link.source = find(nodeById, link.sourceId)
			if (link.source.hostId) { link.sourceHost = find(nodeById, link.source.hostId) }
			link.target = find(nodeById, link.targetId)
			if (link.target.hostId) { link.targetHost = find(nodeById, link.target.hostId) }
			count[link.source.index] = (count[link.source.index] || 0) + 1
			count[link.target.index] = (count[link.target.index] || 0) + 1
		}

		for (i = 0, bias = new Array(m); i < m; ++i) {
			link = links[i]
			bias[i] = count[link.source.index] / (count[link.source.index] + count[link.target.index])
		}

		strengths = new Array(m)
		initializeStrength()
		distances = new Array(m)
		initializeDistance()
	}

	function initializeStrength () {
		if (!nodes) return
		for (var i = 0, n = links.length; i < n; ++i) {
			strengths[i] = +strength(links[i], i, links)
		}
	}

	function initializeDistance () {
		if (!nodes) return
		for (var i = 0, n = links.length; i < n; ++i) {
			distances[i] = +distance(links[i], i, links)
		}
	}

	force.initialize = function (_) {
		nodes = _
		initialize()
	}

	force.links = function (_) {
		if (arguments.length) {
			links = _
			initialize()
			return force
		} else {
			return links
		}
	}

	force.id = function (_) {
		if (arguments.length) {
			id = _
			return force
		} else {
			return id
		}
	}

	force.iterations = function (_) {
		if (arguments.length) {
			iterations = +_
			return force
		} else {
			return iterations
		}
	}

	force.strength = function (_) {
		if (arguments.length) {
			strength = typeof _ === 'function' ? _ : constant(+_)
			initializeStrength()
			return force
		} else {
			return strength
		}
	}

	force.distance = function (_) {
		if (arguments.length) {
			distance = typeof _ === 'function' ? _ : constant(+_)
			initializeDistance()
			return force
		} else {
			return distance
		}
	}

	return force
}
