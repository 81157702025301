<template>
	<mdb-row>
		<edit-table ref="table" class="mr-lg-2 pb-1 lists-table" :columns="columns" :data="data" @pages="setIds" @update="addEntity" @delete="removeEntity" @sync="fetchListsEntities">
			<template v-slot:pre-fieldset>
				<mdb-col>
					<mdb-select :btn-save="false" :options="listSelectOptions(listFilter)" @change="listFilter = $event"></mdb-select>
				</mdb-col>
				<mdb-col>
					<mdb-select :btn-save="false" :options="definitionSelectOptions(definitionFilter)" @change="definitionFilter = $event"></mdb-select>
				</mdb-col>
			</template>
			<th slot="pre-th">
				<mdb-input :key="updatedKey" v-if="ids.length" type="checkbox" id="selected-all" name="selected" :value="allSelected" @change="selectAll($event)"></mdb-input>
			</th>
			<th slot="post-th" class="py-0">
				<mdb-btn @click.native.prevent="removeSelected" color="danger" size="sm" rounded>Remove selected</mdb-btn>
			</th>
			<th slot="pre-th-foot"></th>
      <template v-slot:pre-td="{ row }">
        <td>
          <mdb-input :key="updatedKey" v-if="!isNew(row)" type="checkbox" :id="'selected-' + id(row)"
                     name="selected" :value="isSelected(row)" @change="select(row, $event)"/>
        </td>
      </template>
      <template v-slot:__listId="{ field, row, index, value }">
        <mdb-select :btn-save="false" :options="listOptions(value)"
                    @change="change({ field, row, index, value: $event })"/>
      </template>
      <template v-slot:__definitionName="{ field, row, index, value }">
        <mdb-select :btn-save="false" :options="definitionOptions(value)"
                    @change="change({ field, row, index, value: $event })"/>
      </template>
			<template v-slot:__added="{ value }">
        <div>{{ formatDate(value) }}</div>
      </template>
			<template v-slot:post-table>
				<hr/>
				<mdb-row>
					<mdb-col>
						<mdb-file-input multiple btnColor="primary" :disabled="!uploadList || !uploadDefinition || !uploadReason" @getValue="handleUpload($event)"></mdb-file-input>
					</mdb-col>
				</mdb-row>
				<mdb-row>
					<mdb-col>
						<mdb-select :btn-save="false" :options="listSelectOptions(uploadList)" @change="uploadList = $event"></mdb-select>
					</mdb-col>
					<mdb-col>
						<mdb-select :btn-save="false" :options="definitionSelectOptions(uploadDefinition)" @change="uploadDefinition = $event"></mdb-select>
					</mdb-col>
					<mdb-col>
						<mdb-input :value="uploadReason" @change="uploadReason = $event" :placeholder="$t('lists.reason-label', locale)"></mdb-input>
					</mdb-col>
				</mdb-row>
				<mdb-row>
					<mdb-col col="3">
						<mdb-select v-if="listSelection" :btn-save="false" :options="listSelectionOptions" @change="selectList"></mdb-select>
						<mdb-input v-else v-model.lazy="newList"></mdb-input>
					</mdb-col>
					<mdb-col class="text-left" col="0_17">
						<mdb-icon v-if="!selectedList && !listSelection" class="c-pointer" icon="caret-down" @click.native="listSelection = true"></mdb-icon>
					</mdb-col>
					<mdb-col class="text-left" col="2" md="2" lg="1_5" xl="1">
						<mdb-btn @click="add" color="success" size="sm" rounded>{{ $t('common.add', locale) }}</mdb-btn>
					</mdb-col>
					<mdb-col class="text-left" col="2" md="2" lg="1_5" xl="1">
						<mdb-btn @click="remove" color="danger" size="sm" rounded>{{ $t('common.remove', locale) }}</mdb-btn>
					</mdb-col>
				</mdb-row>
			</template>
		</edit-table>
	</mdb-row>
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'

import { mdbBtn, mdbFileInput, mdbIcon, mdbInput, mdbSelect } from 'mdbvue'
import { arrayEquals, formatValue } from '@/helpers/javascript'
import EditTable from '@/components/common/EditTable'

export default {
	name: 'ListsIndex',
	components: { mdbBtn, mdbFileInput, mdbIcon, mdbInput, mdbSelect, EditTable },
	data () {
		return {
			selected: {},
			ids: [],
			listFilter: null,
			definitionFilter: null,
			uploadList: null,
			uploadDefinition: null,
			uploadReason: null,
			uploadFiles: [],
			selectedList: null,
			listSelection: true,
			newList: null,
			updatedKey: null
		}
	},
	computed: {
		columns () {
			return [
				{
					label: this.$t('lists.list-label', this.locale),
					field: 'listId'
				},
				{
					label: this.$t('lists.definition-label', this.locale),
					field: 'definitionName'
				},
				{
					label: this.$t('lists.value-label', this.locale),
					field: 'value'
				},
				{
					label: this.$t('lists.reason-label', this.locale),
					field: 'reason'
				},
				{
					label: this.$t('lists.added-label', this.locale),
					field: 'added',
					readonly: true
				}
			]
		},
		listSelectionOptions () {
			return Object.values(this.lists).map(l => ({ value: l.name, text: l.name, selected: l.name === this.selectedList }))
				.concat({ value: null, text: this.$t('common.new', this.locale), selected: !this.selectedList })
		},
		data () {
			return this.listsEntities.filter(e => (!this.definitionFilter || e.definitionName === this.definitionFilter) && (!this.listFilter || e.listName === this.listFilter))
				.map(e => Object.assign({}, e, { key: this.id(e) }))
		},
		allSelected () {
			return !this.ids || arrayEquals(Object.entries(this.selected).filter(e => e[1]).map(e => e[0]), this.ids)
		},
		listableDefinitions () {
			return Object.values(this.entityDefinitions).filter(e => e.type === 'String')
		},
		...mapState([ 'locale' ]),
		...mapState('lists', [ 'lists', 'listsEntities' ]),
		...mapState('schema', [ 'entityDefinitions' ])
	},
	async mounted () {
		await this.fetchListsEntities()
		this.resetUpdatedKey()
	},
	watch: {
		lists () {
			this.fetchListsEntities()
		},
		$route () {
			this.fetchListsEntities()
		}
	},
	methods: {
		change ({ field, row, index, value }) {
			this.$refs.table.change({ field, row, index, value })
		},
		listOptions (value) {
			return [{ text: this.$t('lists.list-select', this.locale), value: null, disabled: true, selected: !value }]
				.concat(Object.values(this.lists).map(l => ({ value: l.name, text: l.name, selected: l.name === value })))
		},
		listSelectOptions (value) {
			return this.listOptions(value).concat({ value: null, text: this.$t('common.reset', this.locale), selected: !value })
		},
		definitionOptions (value) {
			return [{ text: this.$t('lists.definition-select', this.locale), value: null, disabled: true, selected: !value }]
				.concat(this.listableDefinitions.map(d => ({ value: d.name, text: d.name, selected: d.name === value })))
		},
		definitionSelectOptions (value) {
			return this.definitionOptions(value).concat({ value: null, text: this.$t('common.reset', this.locale), selected: !value })
		},
		formatDate (value) {
			return value === '-' ? value : formatValue('Timestamp', value)
		},
		isNew (row) {
			return this.$refs.table && this.$refs.table.isNew(row)
		},
		id (row) {
			let { id, listName } = (row.row || row)
			return id + ',' + listName
		},
		isSelected (row) {
			return this.selected[this.id(row)]
		},
		select (row, value) {
			Vue.set(this.selected, this.id(row), value)
			this.resetUpdatedKey()
		},
		setIds (pages, activePage) {
			if (pages[activePage]) {
				let ids = pages[activePage].map(r => r.row.key)
				if (!arrayEquals(ids, this.ids)) {
					this.ids = ids
				}
			}
		},
		selectAll (value) {
			if (value !== this.allSelected) {
				this.ids.forEach(id => {
					Vue.set(this.selected, id, value)
				})
			}
			this.resetUpdatedKey()
		},
		removeSelected () {
			Object.entries(this.selected).filter(e => e[1]).map(e => {
				let [ , id, listName ] = /^(\d+),(\d+)$/.exec(e[0])
				this.removeEntity(this.listsEntities.filter(e => e.id === id && e.listName === listName)[0])
			})
		},
		handleUpload (fileList) {
			let files = [ ...fileList ]
			this.upload({ definitionName: this.uploadDefinition, listId: this.uploadList, reason: this.uploadReason, files })
		},
		resetUpdatedKey () {
			this.updatedKey = Math.floor(Math.random() * 100000000)
		},
		selectList (value) {
			this.selectedList = value
			if (!value) {
				this.listSelection = false
			}
		},
		add () {
			this.addList({ name: this.newList, after: (list) => { this.selectedList = list.name; this.listSelection = true; this.newList = null } })
		},
		remove () {
			this.removeList({ name: this.selectedList, after: () => { this.selectedList = null } })
		},
		...mapActions('lists', [ 'fetchListsEntities', 'addList', 'removeList', 'addEntity', 'removeEntity', 'upload' ])
	}
}
</script>

<style scoped>
.lists-table {
	margin-top: 2rem;
}
>>> input.form-control {
	margin-bottom: 0;
}
.zz {
	margin-top: -0.5rem;
}
</style>
