<template>
    <mdb-row class="mb-md-1">
		<mdb-col col="12">
			<img src="/logo.png" height="135" :title="title"/>
		</mdb-col>
	</mdb-row>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: 'TheBrand',
  computed: {
    title () {
      return 'frontendVersion: ' + this.frontendVersion + ', backendVersion: ' + this.backendVersion
    },
    ...mapState([ 'backendVersion', 'frontendVersion' ])
  }
}
</script>

<style scoped>

</style>
