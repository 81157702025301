import { fromEntries, intKeys, intKeyedEntries, subtract } from '@/helpers/javascript'
import jiggleFun from 'd3-force/src/jiggle'

export function jiggle () {
	return jiggleFun(Math.random)
}

export function hubId ({ hostId, definitionId }) {
	return hostId + ',' + definitionId
}

export function neighbors (hostId, { links }) {
	return Object.assign({}, links[hostId], fromEntries(intKeyedEntries(links).filter(e => hostId in e[1])
		.map(e => [ e[0], e[1][hostId] ])))
}

export function isOpened ({ id, hostId, definitionId }, { opened }) {
	return opened[id || hubId({ hostId, definitionId })]
}

export function subgraphByNodeId (id, { links }) {
	let currentNodes = intKeys(neighbors(id, { links }))
	let allNodes = [ ...currentNodes ]
	while (currentNodes.length) {
		currentNodes = subtract(currentNodes.map(n => intKeys(neighbors(n, { links }))).flat(), [ id, ...allNodes ])
		allNodes = [ ...allNodes, ...currentNodes ]
	}
	return [ id, ...allNodes ]
}

export { default as fixHubNodesForce } from './fixHubNodesForce'
export { default as fixEntityNodesAfterHavingAppearedForce } from './fixEntityNodesAfterHavingAppearedForce'
export { default as ignoreHubNodesCollideForce } from './ignoreHubNodesCollideForce'
export { default as ignoreHubNodesLinkForce } from './ignoreHubNodesLinkForce'
export { default as ignoreHubNodesManyBodyForce } from './ignoreHubNodesManyBodyForce'
export { default as ignoreHubNodesRadialForce } from './ignoreHubNodesRadialForce'
