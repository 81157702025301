<template>
    <div class="error text-dark">
		<span>{{ msg }}</span>
		<span @click="$emit('delete')">x</span>
	</div>
</template>

<script>
export default {
	name: 'ErrorRender',
	props: {
		error: {
			type: [Object, Error],
			required: true
		}
	},
	computed: {
		msg () {
			let data = this.error.response && this.error.response.data
			if (data) {
				return data.type + '(' + data.code + '): ' + data.message
			} else {
				return this.error.toString()
			}
		}
	}
}
</script>

<style scoped>
span {
	cursor: pointer;
}
</style>
