const all = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11 ]

export default {
	getRules () {
		return [
			{ id: 1, name: 'fraud amount', code: '+100 IF tx.amount = 1245.56' },
			{ id: 2, name: 'more fraud amount', code: '100 IF tx.amount > 1245.56 + 100' },
			{ id: 3, name: 'ok', code: '-100 IF tx.amount < 1245.56 - 100' },
			{ id: 4, name: 'resident', code: '-100 IF tx.issuer_country = \'ru\'' },
			{ id: 5, name: 'president', code: '-100 IF tx.card_token_plus_a = \'ADF456H12Ca\'' },
			{ id: 6, name: 'black phones (including this) count = 2', code: '-100 IF tx.black_phones = 2' },
			{ id: 7, name: 'black phones (not including this) count = 1', code: '-100 IF tx.black_distinct_phones = 1' },
			{ id: 8, name: 'white phones (including this) count = 1', code: '-100 IF tx.white_phones = 1' },
			{ id: 9, name: 'card blacklisted', code: '+100 IF tx.auth_code is not null and tx.src_card in [\'ADF456H12C\', \'ADF456H12Ca\', \'ADF456H12Cb\']' },
			{ id: 10, name: 'card whitelisted', code: '-100 IF tx.auth_code = 321 and tx.src_card not in [\'ADF456H12Ca\', \'ADF456H12Cb\']' },
			{ id: 11, name: 'card blacklisted [2]', code: '+100 IF tx.auth_code is not null and tx.src_card in "black"' },
			{ id: 12, name: 'card whitelisted [2]', code: '-100 IF tx.auth_code = 321 and tx.src_card in "white" and tx.src_card not in "black"' }
		]
	},
	updateRule (rule) {
		return rule
	},
	deleteRule () {
		return { ok: true }
	},
	getRulesets () {
		return [
			{
				id: 1,
				name: 'Basic',
				rules: all
			}]
	},
	createRuleset (ruleset) {
		return ruleset
	},
	deleteRuleset () {
		return { ok: true }
	},
	include ({ value }) {
		if (all.includes(value)) {
			return all
		} else {
			return all.concat(value)
		}
	},
	replace () {
		return all
	},
	getFields () {
		return [
			{ id: 3, type: 'Entity', name: 'amount', code: 'tx.amount' },
			{ id: 4, type: 'Entity', name: 'issuer_country', code: 'tx.src_card.issuer.country' },
			{ id: 5, type: 'Function', name: 'card_token_plus_a', code: 'tx.src_card || \'a\'' },
			{ id: 6, type: 'Aggregate', name: 'black_phones', code: 'count(distinct x.dst_phone) WHERE x.dst_phone in "black" FOR x:tx GIVEN BY x.src_card = tx.src_card' },
			{ id: 7, type: 'Aggregate', name: 'black_distinct_phones', code: 'count(distinct x.dst_phone) WHERE x.dst_phone in "black" and x.dst_phone != tx.dst_phone FOR x:tx GIVEN BY x.src_card = tx.src_card' },
			{ id: 8, type: 'Aggregate', name: 'white_phones', code: 'count(distinct x.dst_phone) WHERE x.dst_phone in "white" FOR x:tx GIVEN BY x.src_card = tx.src_card' }
		]
	},
	updateField (field) {
		return field
	},
	deleteField () {
		return { ok: true }
	}
}
