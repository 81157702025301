<template>
	<td @click="click" class="border-primary py-2 fs-1" :class="{ 'active': isActive, 'denied': isDenied }">
		<span v-if="isBoolean" :class="valueClass">
			<mdb-icon v-if="value" icon="check-circle"></mdb-icon>
			<mdb-icon v-else icon="times-circle"></mdb-icon>
		</span>
		<span v-else :class="valueClass">{{formattedValue}}</span>
	</td>
</template>

<script>
import { mdbIcon } from 'mdbvue'
import { mapState } from 'vuex'
import { formatValue } from '@/helpers/javascript'

export default {
	name: 'MonitoringCell',
	components: { mdbIcon },
	props: {
		property: {
			type: Object,
			default: () => {}
		},
    id: {
      default: null
    },
		value: {
			default: null
		},
		isDenied: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		type () {
			return (this.entityDefinitions[this.property.definitionName] || {}).type
		},
		isBoolean () {
			return this.type === 'Boolean'
		},
		isActive () {
			return this.type === 'String' || this.property.path === this.$constants.rootPath
		},
		valueClass () {
			if (this.isActive) {
				return 'c-pointer'
			} else {
        return ''
      }
		},
		formattedValue () {
			return formatValue(this.type, this.value)
		},
		...mapState('schema', [ 'entityDefinitions' ])
	},
	methods: {
		click () {
			if (this.isActive) {
				this.$emit('show-details', this)
			}
		}
	}
}
</script>

<style scoped>
td {
	border-width: 1px;
}
td.active {
	font-weight: bold;
}
td.denied {
	color: red;
}
</style>
