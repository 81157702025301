export default {
	get () {
		return { role: 'superadministrator', login: 'mock', userId: 1 }
	},
	logIn () {
		return { ok: true }
	},
	logOut () {
		return { ok: true }
	}
}
