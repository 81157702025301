<template>
	<mdb-row class="justify-content-center">
		<template v-if="bindingsEditMode">
			<edit-form v-for="(binding, id) in bindings" :key="id" :col="col" :entity="binding" :properties="[ 'path', 'address' ]"
                 :submit="$t('common.update', locale)" :reset="$t('common.delete', locale)" @submit="updateBinding" @reset="deleteBinding(binding)">
        <template v-slot:_path="{ entity }">
          <path-input key="path" v-model="entity.path"></path-input>
        </template>
			</edit-form>
			<edit-form :key="newBindingKey" :col="col" :entity="newBinding" :properties="[ 'path', 'address' ]"
                 :submit="$t('common.save', locale)" @submit="saveBinding" @reset.stop>
        <template v-slot:_path="{ entity }">
          <path-input key="path" v-model="entity.path"></path-input>
        </template>
				<template v-slot:reset>&#8203;</template>
			</edit-form>
      <mdb-col col="12"/>
			<mdb-col v-bind="col">
				<mdb-btn @click="commitBindingTransaction($constants)">{{ $t('bindings.commit', locale) }}</mdb-btn>
			</mdb-col>
			<mdb-col v-bind="col">
				<mdb-btn @click="rollbackBindingTransaction($constants)">{{ $t('bindings.rollback', locale) }}</mdb-btn>
			</mdb-col>
		</template>
		<template v-else>
			<mdb-col v-bind="col" class="bg-white mx-2 my-1 pb-2 text-body" v-for="binding in nonEmptyBindings" :key="binding.id">
				<span>{{ printBinding(binding) }}</span>
			</mdb-col>
      <mdb-col col="12"/>
			<mdb-col v-bind="col">
				<mdb-btn @click="beginBindingTransaction">{{ $t('bindings.edit', locale) }}</mdb-btn>
			</mdb-col>
		</template>
	</mdb-row>
</template>

<script>
import { mdbBtn } from 'mdbvue'
import { mapState, mapGetters, mapActions } from 'vuex'

import EditForm from '@/components/common/EditForm.vue'
import PathInput from '@/components/common/PathInput.vue'

export default {
	name: 'BindingsIndex',
	components: { EditForm, PathInput, mdbBtn },
	data () {
		return {
			newBindingKey: null
		}
	},
	async created () {
		this.resetNewBindingKey()
		await this.fetchBindings()
	},
	computed: {
		col () {
			return { col: '12', sm: '6', md: '4', lg: '3' }
		},
    editableBindings () {
      return Object.values(this.bindings).filter(b => b.id !== b.rootId)
    },
    nonEmptyBindings () {
      return Object.values(this.bindings).filter(b => b.address)
    },
    newBinding () {
      return { path: this.$constants.rootPath, rootId: this.rootBindingId }
    },
		...mapState([ 'locale' ]),
		...mapState('bindings', [ 'bindings', 'rootBindingId' ]),
		...mapGetters('bindings', [ 'bindingsEditMode' ])
	},
	watch: {
		async $route () {
			this.resetNewBindingKey()
			await this.fetchBindings()
		}
	},
	methods: {
		async saveBinding (binding) {
			await this.updateBinding(binding)
			this.resetNewBindingKey()
		},
		resetNewBindingKey () {
			this.newBindingKey = (new Date()).getTime()
		},
		printBinding (binding) {
			return binding.address.split(/\./g).reduceRight(
				(accumulator, currentValue) => ({ [ currentValue ]: accumulator }),
				binding.path
			)
		},
		...mapActions('bindings', [ 'fetchBindings', 'updateBinding', 'deleteBinding', 'beginBindingTransaction', 'commitBindingTransaction', 'rollbackBindingTransaction' ])
	}
}
</script>

<style scoped>

</style>
