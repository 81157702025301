<template>
	<mdb-col class="px-2 py-3" v-bind="colBind">
		<router-link :to="to" class="menu-link d-block h-100 w-100">
			<mdb-btn class="font-weight-bold" block color="primary" :waves="false" :class="format">
				<slot></slot>
			</mdb-btn>
		</router-link>
	</mdb-col>
</template>

<script>
import { mdbBtn } from 'mdbvue'

export default {
	name: 'MenuItem',
	components: { mdbBtn },
	props: {
		href: String,
		name: Boolean,
		col: [String, Object],
		format: [Array, String]
	},
	computed: {
		to () {
			if (this.name) {
				return { name: this.href }
			} else {
				return { path: this.href || '/' }
			}
		},
		colBind () {
			if (!this.col) {
				return {}
			} else if (this.col.constructor === Object) {
				return this.col
			} else {
				return { col: this.col }
			}
		}
	}
}
</script>

<style scoped>

</style>
