<template>
	<mdb-row>
		<mdb-col col="12">
			<mdb-row ref="filters">
				<g-filter :filter="filter" :value="value" @autosize="autosize" @flush="flush" @bomb="$refs.graph.animate(false)"></g-filter>
			</mdb-row>
			<mdb-row>
				<mdb-col lg="10_5" class="overflow-hidden" ref="layout">
					<mdb-row>
						<graph-render ref="graph" :key="updatedKey" :data="data" @node-click="nodeClick" @link-click="linkClick" @menu="menu" @zoom-out="zoomOut" @zoom-in="zoomIn" @nodes="setNodesCoordinates"></graph-render>
					</mdb-row>
				</mdb-col>
				<mdb-col lg="1_5" ref="side-col" class="mdb-color darken-3">
          <graph-definitions/>
					<graph-properties/>
				</mdb-col>
			</mdb-row>
		</mdb-col>
		<tx-data-popup ref="tx-popup" @show-details="showDetails"></tx-data-popup>
		<node-details-popup ref="node-popup"></node-details-popup>
	</mdb-row>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import { hubId, isOpened, neighbors } from '@/helpers/graph'
import { intKeyedEntries } from '@/helpers/javascript'
import NodeDetailsPopup from '@/components/common/NodeDetailsPopup'
import TxDataPopup from '@/components/common/TxDataPopup'
import GFilter from './GFilter'
import GraphDefinitions from './GraphDefinitions'
import GraphProperties from './GraphProperties'
import GraphRender from './GraphRender'

export default {
	name: 'GraphIndex',
	components: {
		NodeDetailsPopup,
		TxDataPopup,
		GraphProperties,
		GFilter,
		GraphDefinitions,
		GraphRender
	},
	props: [ 'filter', 'value' ],
	data () {
		return {
			updatedKey: null
		}
	},
	async mounted () {
    await this.$nextTick()
    if (!Object.keys(this.graphEntities).length) {
      this.autosize()
    }
		window.addEventListener('resize', this.onResize.bind(this))
	},
	beforeDestroy () {
		window.removeEventListener('resize', this.onResize.bind(this))
	},
	computed: {
		data () {
			if (this.opened) { /* a hack to add this.opened as a reactive dependency */ }
			let nodes = Object.values(this.graphEntities).map(this.entityNode)
			let links = intKeyedEntries(this.links).filter(l => l[0] !== this.selectedNodeId).map(l => intKeyedEntries(l[1])
          .filter(e => e[0] !== this.selectedNodeId).map(e => this.entityEntityLink(l[0], e[0], e[1]))).flat()
			if (this.selectedNodeId !== null) {
				let selectedNode = this.graphEntities[this.selectedNodeId]
				nodes = nodes.concat(intKeyedEntries(this.usedDefinitions).filter(d => d[0] !== selectedNode.definitionId)
            .map(d => this.hubNode(this.selectedNodeId, d[0], d[1].name)))
				links = links.concat(intKeyedEntries(this.usedDefinitions).filter(d => d[0] !== selectedNode.definitionId)
            .map(d => this.hubLink(this.selectedNodeId, d[0])))
				links = links.concat(intKeyedEntries(neighbors(this.selectedNodeId, this))
            .map(e => this.hubEntityLink(this.selectedNodeId, e[0], e[1])))
			}
			return { nodes, links }
		},
		...mapState('graph', [ 'zoom', 'graphEntities', 'links', 'opened', 'selectedNodeId', 'selectedLinkId', 'frameSize' ]),
    ...mapGetters('graph', [ 'usedDefinitions' ])
	},
	methods: {
		getColor (definitionId) {
			return this.usedDefinitions[definitionId] && this.usedDefinitions[definitionId].color
		},
		link (sourceId, targetId, txIds) {
			return { id: sourceId + '-' + targetId, sourceId, targetId, txIds }
		},
		hubNode (hostId, definitionId) {
			return {
        id: hubId({ hostId, definitionId }),
        name: ' ',
        typeId: definitionId,
        _color: this.getColor(definitionId),
        hostId,
        _cssClass: 'hub'
      }
		},
		entityNode (entity) {
			return {
        id: entity.nodeId,
        name: entity.value,
        typeId: entity.definitionId,
        _color: this.getColor(entity.definitionId)
      }
		},
		hubLink (hostId, definitionId) {
			return this.link(hostId, hubId({ hostId, definitionId }))
		},
		entityEntityLink (host1Id, host2Id, txIds) {
			return this.link(host1Id, host2Id, txIds)
		},
		hubEntityLink (hubHostId, hostId, txIds) {
			return this.link(hubId({ hostId: hubHostId, definitionId: this.graphEntities[hostId].definitionId }), hostId, txIds)
		},
		nodeClick (node) {
			if (node.hostId) {
				this.clickHub(node)
			} else {
				this.clickEntity(node)
			}
		},
		clickHub (hub) {
			if (isOpened(hub, this)) {
				this.closeHub(hub)
			} else {
				this.openHub(hub)
			}
		},
		clickEntity (entity) {
			this.toggleNodeSelection(entity)
		},
		menu (entity) {
			if (!entity.hostId) {
        let type = entity.typeId in this.usedDefinitions && this.usedDefinitions[entity.typeId].name
        let id = entity.id in this.graphEntities && this.graphEntities[entity.id].id
				this.$refs['node-popup'].open({
					kind: 'entity',
					type,
					id,
					value: entity.name
				})
			}
		},
		linkClick (link) {
			if (link.txIds) {
				this.$refs['tx-popup'].open({ link })
			}
		},
		toggleNodeSelection (entity) {
			if (this.selectedNodeId && this.selectedNodeId === entity.id) {
				this.deselectNode()
			} else {
				this.selectNode(entity)
			}
		},
		autosize () {
			this.SET_ZOOM()
			this.SET_OFFSET()
			this.SET_MARGIN()
			this.onResize()
			this.SET_SIZE(Object.assign({}, this.frameSize))
			this.resetUpdatedKey()
		},
		onResize () {
      let totalHeight = this.$root.$children[0].$children[0].viewHeight
      let filtersHeight = this.$refs.filters.$el.clientHeight
			let height = totalHeight - filtersHeight
      let totalWidth = this.$el.clientWidth;
      let sideColWidth = this.$refs['side-col'].$el.clientWidth;
      let width = totalWidth > sideColWidth ? totalWidth - sideColWidth : totalWidth
			this.$refs.layout.$el.style['max-height'] = height + 'px'
			this.SET_FRAME_SIZE({ w: width, h: height })
		},
		zoomOut () {
			this.SET_ZOOM(Math.max(0.5, this.zoom - 0.1))
		},
		zoomIn () {
			this.SET_ZOOM(Math.min(2, this.zoom + 0.1))
		},
		flush () {
			this.$refs.graph.flush()
		},
		showDetails ({ id, property, value }) {
			this.$refs['node-popup'].open({ kind: 'property', type: property.name, id, value })
		},
		resetUpdatedKey () {
			this.updatedKey = Math.floor(Math.random() * 100000000)
		},
		...mapActions('graph', [ 'openHub', 'closeHub', 'selectNode', 'deselectNode', 'setNodesCoordinates' ]),
		...mapMutations('graph', [ 'SET_ZOOM', 'SET_MARGIN', 'SET_OFFSET', 'SET_FRAME_SIZE', 'SET_SIZE' ])
	}
}
</script>

<style scoped>
>>> .md-form {
	margin-top: 0;
	margin-bottom: 0;
}
>>> input.select-dropdown {
	margin-bottom: 0 !important;
}
>>> path.link {
	stroke: black;
}
>>> .node.hub {
	fill: black;
}
>>> .node.hub.selected {
	fill: yellow;
}
</style>
