<template>
	<mdb-row class="mt-4">
		<arrange-properties :col="arrangeCol" role="Filter"></arrange-properties>
		<arrange-properties :col="arrangeCol" role="Projection"></arrange-properties>
		<!-- <arrange :col="col" role="Graph"></arrange> -->
		<edit-properties :col="editCol" class="overflow-xl-hidden"></edit-properties>
	</mdb-row>
</template>

<script>
import ArrangeProperties from '@/components/properties/ArrangeProperties.vue'
import EditProperties from '@/components/properties/EditProperties.vue'

export default {
	name: 'PropertiesIndex',
	components: { ArrangeProperties, EditProperties },
	computed: {
		arrangeCol () {
			return { col: '6', xl: '3' }
		},
		editCol () {
			return { col: '12', xl: '6' }
		}
	}
}
</script>
