<template>
	<mdb-row>
		<mdb-col class="text-left">
			<mdb-row>
				<mdb-col md="1">
					<label>{{ $t('profile.login-label', locale) }}</label>
				</mdb-col>
				<mdb-col md="1">
					<input type="text" name="login" v-model="login"/>
				</mdb-col>
			</mdb-row>
		</mdb-col>
		<div class="w-100 d-none d-md-block"></div>
		<mdb-col class="text-left">
			<mdb-row>
				<mdb-col md="1">
					<label>{{ $t('profile.password-label', locale) }}</label>
				</mdb-col>
				<mdb-col md="1">
					<input type="password" name="password" v-model="password"/>
				</mdb-col>
			</mdb-row>
		</mdb-col>
		<div class="w-100 d-none d-md-block"></div>
		<mdb-col class="text-left">
			<input type="submit" @click.prevent="logIn" />
		</mdb-col>
	</mdb-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'TheLogin',
	data () {
		return {
			login: null,
			password: null
		}
	},
	computed: {
		...mapState([ 'locale' ])
	},
	methods: {
		logIn () {
			return this.$store.dispatch('logIn', { login: this.login, password: this.password })
		}
	}
}
</script>

<style scoped>

</style>
