<template>
	<mdb-row class="group mdb-color darken-3 my-2">
		<mdb-col col="12">Unused:</mdb-col>
		<draggable class="col-12" :list="noneProperties" v-bind="dragOptions" :move="onMove" @change="onChange('none', $event)">
			<property-render v-for="(property, index) in noneProperties" :key="index" :property="property" class="mx-1 my-2"></property-render>
		</draggable>
		<mdb-col col="12">Base:</mdb-col>
		<draggable class="col-12" :list="baseProperties" v-bind="dragOptions" :move="onMove" @change="onChange('base', $event)">
			<property-render v-for="(property, index) in baseProperties" :key="index" :property="property" class="mx-1 my-2"></property-render>
		</draggable>
		<mdb-col col="12">Extra:</mdb-col>
		<draggable class="col-12" :list="auxProperties" v-bind="dragOptions" :move="onMove" @change="onChange('aux', $event)">
			<property-render v-for="(property, index) in auxProperties" :key="index" :property="property" class="mx-1 my-2"></property-render>
		</draggable>
	</mdb-row>
</template>

<script>
import Draggable from 'vuedraggable'
import PropertyRender from '@/components/common/PropertyRender.vue'
import { mapState, mapActions } from 'vuex'

export default {
	name: 'GraphProperties',
	components: { Draggable, PropertyRender },
  data () {
    return {
      editing: false
    }
  },
	computed: {
		baseProperties () {
			return this.graphProps.base.map((g) => this.allProps[g])
		},
		auxProperties () {
			return this.graphProps.aux.map((g) => this.allProps[g])
		},
		noneProperties () {
			return Object.entries(this.allProps).filter(f => !this.graphProps.base.concat(this.graphProps.aux).includes(f[0])).map(f => f[1])
		},
		dragOptions () {
			return {
				animation: 0,
				disabled: this.editing,
				draggable: '.property-wrapper',
				group: 'properties',
				ghostClass: 'ghost',
				tag: 'div'
			}
		},
    ...mapState('properties', [ 'allProps', 'graphProps' ])
	},
	methods: {
		onMove ({ relatedContext, draggedContext }) {
			if (!draggedContext.element) {
				console.error('Warning! Dragged context has no element! See dragged context contents:', draggedContext)
				return false
			} else {
				let relatedElement = relatedContext.element
				let draggedElement = draggedContext.element
				return (draggedElement.path !== this.$constants.rootPath || draggedContext.index !== 0) &&
					(!relatedElement || relatedElement.path !== this.$constants.rootPath || draggedContext.futureIndex !== 0)
			}
		},
		onChange (clazz, event) {
			let eventData
			if ('moved' in event) {
				eventData = event.moved
			} else if ('added' in event) {
				eventData = event.added
			} else {
				return
			}
			let newIndex
			if (clazz === 'none') {
				if ('moved' in event) {
					return
				} else {
					newIndex = null
				}
			} else if (clazz === 'base') {
				newIndex = eventData.newIndex
			} else if (clazz === 'aux') {
				newIndex = -eventData.newIndex - 1
			} else {
				return
			}
			this.replaceProperty({ name: eventData.element.name, role: 'Graph', newIndex })
		},
		...mapActions('properties', [ 'replaceProperty' ])
	}
}
</script>

<style scoped>

</style>
