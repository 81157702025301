import { api } from '@/helpers/core'

export default {
	getVersion () {
		return api.get('/settings/version/')
	},
	getEntities () {
		return api.get('/settings/schema/')
	},
	addEntity (entity) {
		return api.post('/settings/schema/', entity)
	},
	updateEntity (entity) {
		return api.patch('/settings/schema/' + entity.name, entity)
	},
	deleteEntity (entity) {
		return api.delete('/settings/schema/' + entity.name)
	},
	getRelations () {
		return api.get('/settings/schema/relations/')
	},
	addRelation (relation) {
		return api.post('/settings/schema/relations/', relation)
	},
	updateRelation (relation) {
		return api.patch('/settings/schema/relations/' + relation.sourceDefinitionName + '/' + relation.name, relation)
	},
	deleteRelation (relation) {
		return api.delete('/settings/schema/relations/' + relation.sourceDefinitionName + '/' + relation.name)
	}
}
