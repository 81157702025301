import constant from 'd3-force/src/constant'

export default function (radius, x, y) {
	var nodes
	var strength = constant(0.1)
	var strengths
	var radiuses

	if (typeof radius !== 'function') radius = constant(+radius)
	if (x == null) x = 0
	if (y == null) y = 0

	function force (alpha) {
		var n = nodes.length
		switch (n) {
		case 0:
			break
		case 1:
			if (!nodes[0].fx) nodes[0].fx = x
			if (!nodes[0].fy) nodes[0].fy = y
			break
		default:
			for (var i = 0; i < n; ++i) {
				var node = nodes[i]
				var dx = node.x - x || 1e-6
				var dy = node.y - y || 1e-6
				var r = Math.sqrt(dx * dx + dy * dy)
				var k = (radiuses[i] - r) * strengths[i] * alpha / r
				node.vx += dx * k
				node.vy += dy * k
			}
		}
	}

	function initialize () {
		if (!nodes) return
		var i
		var n = nodes.length
		strengths = new Array(n)
		radiuses = new Array(n)
		for (i = 0; i < n; ++i) {
			radiuses[i] = +radius(nodes[i], i, nodes)
			strengths[i] = isNaN(radiuses[i]) ? 0 : +strength(nodes[i], i, nodes)
		}
	}

	force.initialize = function (_) {
		nodes = _.filter((n) => !n.hostId)
		initialize()
	}

	force.strength = function (_) {
		if (arguments.length) {
			strength = typeof _ === 'function' ? _ : constant(+_)
			initialize()
			return force
		} else {
			return strength
		}
	}

	force.radius = function (_) {
		if (arguments.length) {
			radius = typeof _ === 'function' ? _ : constant(+_)
			initialize()
			return force
		} else {
			return radius
		}
	}

	force.x = function (_) {
		if (arguments.length) {
			x = +_
			return force
		} else {
			return x
		}
	}

	force.y = function (_) {
		if (arguments.length) {
			y = +_
			return force
		} else {
			return y
		}
	}

	return force
}
