<template>
	<mdb-col v-if="this.$store.getters.authorized" class="d-inline-block w-auto">
		<mdb-row class="align-middle">
			<mdb-col>
				<span>{{ this.$store.state.user.login }}</span>
			</mdb-col>
		</mdb-row>
		<mdb-row class="align-middle">
			<mdb-col>
				<a href="#" @click.prevent="logOut">{{ $t('profile.log-out', locale) }}</a>
			</mdb-col>
		</mdb-row>
	</mdb-col>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'TheProfile',
	computed: {
		...mapState([ 'locale' ])
	},
	methods: {
		logOut () {
			return this.$store.dispatch('logOut')
		}
	}
}
</script>

<style scoped>

</style>
