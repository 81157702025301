export default {
	getBindings () {
		return []
	},
	updateBinding (binding) {
		return binding
	},
	deleteBinding () {
		return { ok: true }
	},
	beginBindingTransaction () {
		return []
	},
	commitBindingTransaction () {
		return []
	},
	rollbackBindingTransaction () {
		return []
	}
}
