import { api } from '@/helpers/core'

export default {
	get () {
		return api.get('/settings/properties/')
	},
	replace ({ name, property, value }) {
		return api.post('/settings/properties/replace', { name, property, value })
	},
	update (property) {
		return api.post('/settings/properties/', property)
	},
	delete (property) {
		return api.post('/settings/properties/delete', property)
	}
}
