<template>
	<tbl bordered class="border-dark">
		<tbl-body>
			<entity-list v-for="list in lists" :key="list.name" :name="list.name" :initialized="initialized" @add="add" @remove="remove" @update-reason="updateReason"></entity-list>
		</tbl-body>
	</tbl>
</template>

<script>
import { Tbl, TblBody } from 'mdbvue'
import { mapState, mapActions } from 'vuex'
import EntityList from '@/components/common/EntityList'

export default {
	name: 'EntityLists',
	components: { Tbl, TblBody, EntityList },
	props: {
		id: {
			required: true
		}
	},
	data () {
		return {
			initialized: false
		}
	},
	async created () {
		await this.fetchEntityLists({ id: this.id, after: () => { this.initialized = true } })
	},
	computed: {
		entity () {
			return this.entities[this.id]
		},
		...mapState('lists', [ 'entities', 'lists' ])
	},
	methods: {
		add (listName, reason) {
			this.addToList({ id: this.id, listName, reason })
		},
		remove (listName) {
			this.removeFromList({ id: this.id, listName })
		},
		updateReason (listName, reason) {
			this.addToList({ id: this.id, listName, reason })
		},
		...mapActions('lists', [ 'fetchEntityLists', 'addToList', 'removeFromList' ])
	}
}
</script>

<style scoped>

</style>
