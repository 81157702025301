<template>
    <tbl bordered class="border-dark">
		<!-- <tbl-head>
			<tr>
				<th></th>
			</tr>
		</tbl-head> -->
		<tbl-body>
			<tr v-for="(rule, index) in rules" :key="index" :class="{ fired: rule.fired }">
				<td class="py-2 text-left fs-1 font-weight-bold">{{ rule.name }}</td>
				<td class="py-2 text-left fs-1 font-weight-bold">{{ rule.action }}</td>
			</tr>
		</tbl-body>
	</tbl>
</template>

<script>
import { Tbl, TblBody } from 'mdbvue'
import { mapState, mapActions } from 'vuex'

export default {
	name: 'TxRules',
	components: { Tbl, TblBody },
	async created () {
		if (this.txRules[this.txId] === undefined) {
			await this.fetchTxRules(this.txId)
		}
	},
	props: {
		firedOnly: Boolean
	},
	computed: {
		rules () {
			return (this.txRules[this.txId] || []).filter(r => !this.firedOnly || r.fired)
		},
		...mapState('monitoring', [ 'txRules', 'txId' ])
	},
	methods: {
		...mapActions('monitoring', [ 'fetchTxRules' ])
	}
}
</script>

<style scoped>
tr {
	background-color: lightgreen;
	color: darkgreen;
}
tr.fired {
	background-color: #ff8976;
	color: darkred;
}
td {
	border-color: black;
	border-width: 2px;
}
</style>
