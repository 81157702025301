<template>
	<input ref="target" :value="path" :disabled="disabled" @change="emitValue($event.target.value)" />
</template>

<script>

export default {
	name: 'PathInput',
	props: {
		disabled: {
			type: Boolean,
			default: false
		},
		value: {
			type: String,
      required: true
		}
	},
	computed: {
    rootPath () {
      return this.$constants.rootPath
    },
    path () {
      return (this.value === this.rootPath || !this.value) ? '' : this.value.replace(new RegExp('^' + this.rootPath + '\\.'), '')
    },
	},
  methods: {
    emitValue (value) {
      this.$emit('input', value === '' ? this.rootPath : this.rootPath + '.' + value)
    },
    reset () {
      this.$refs['target'].reset()
    }
  }
}
</script>

<style scoped>

</style>
