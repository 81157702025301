<template>
	<mdb-row>
		<mdb-col col="12">
			<mdb-row class="border border-white pl-4 pb-2">
        <filter-group v-for="prop in filterProps.base" :ref="filterRefId(prop)" :key="prop" kind="property" :name="prop"/>
				<mdb-col col="12"></mdb-col>
        <filter-group v-show="filterAux" v-for="prop in filterProps.aux" :ref="filterRefId(prop)" :key="prop" kind="property" :name="prop" aux/>
        <filter-group v-if="entityFilterName" ref="entity-filter" key="entity" kind="entity" :name="entityFilterName" aux/>
				<mdb-col col="12" v-if="filterAux"><mdb-select class="bg-white" search :options="rulesOptions" :btn-save="true" @change="SET_RULE_FILTER_VALUE"></mdb-select></mdb-col>
			</mdb-row>
			<mdb-icon class="position-absolute right-top text-white c-pointer" :icon="showFilterAuxIcon" @click.native="TOGGLE_FILTER_AUX"></mdb-icon>
			<mdb-icon v-if="isExportable" class="position-absolute right-middle text-white c-pointer export" icon="cloud-download-alt" @click.native="exportData(SET_EXPORT_DOWNLOAD_URL)"></mdb-icon>
			<mdb-icon class="position-absolute right-bottom text-white c-pointer" icon="search" @click.native="fetchData"></mdb-icon>
		</mdb-col>
		<mdb-col col="12" class="px-0 pt-3">
			<monitoring-table @show-details="showDetails" :rows="rows" :base-projection="baseProjection" :aux-projection="auxProjection" :projection-aux="projectionAux"></monitoring-table>
			<mdb-icon class="position-absolute left-top text-primary c-pointer" :icon="showProjectionAuxIcon" @click.native="TOGGLE_PROJECTION_AUX"></mdb-icon>
		</mdb-col>
		<node-details-popup ref="popup"></node-details-popup>
		<a v-if="exportDownloadUrl" :href="baseUrl + exportDownloadUrl" ref="exportDownload" download target="_blank"></a>
	</mdb-row>
</template>

<script>
import { mdbIcon, mdbSelect } from 'mdbvue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import NodeDetailsPopup from '@/components/common/NodeDetailsPopup'
import FilterGroup from './FilterGroup'
import MonitoringTable from './MonitoringTable.vue'

export default {
	name: 'MonitoringIndex',
	components: { mdbIcon, mdbSelect, NodeDetailsPopup, FilterGroup, MonitoringTable },
  props: {
    kind: String,
    type: String,
    value: String
  },
	computed: {
		baseUrl () {
			return process.env.VUE_APP_API_URL
		},
		showFilterAuxIcon () {
			if (this.filterAux) {
				return 'caret-square-left'
			} else {
				return 'caret-square-right'
			}
		},
		showProjectionAuxIcon () {
			if (this.projectionAux) {
				return 'caret-square-left'
			} else {
				return 'caret-square-right'
			}
		},
    rulesOptions () {
			return [{ text: this.$t('monitoring.fired-rule-filter', this.locale), value: null, disabled: true, selected: !this.ruleFilterId }]
				.concat(Object.values(this.rules).map(r => ({ text: r.name, value: r.name, selected: r.name === this.ruleFilterId })))
				.concat({ text: this.$t('monitoring.reset-rule-filter', this.locale), value: null })
		},
		...mapState([ 'locale' ]),
		...mapState('monitoring', [ 'filterAux', 'projectionAux', 'rows', 'exportDownloadUrl' ]),
    ...mapState('monitoring/filterValues', [ 'entityFilterName' ]),
		...mapState('schema', [ 'entityDefinitions' ]),
		...mapState('rules', [ 'rules' ]),
    ...mapState('properties', [ 'allProps', 'filterProps', 'projectionProps' ]),
		...mapGetters('monitoring', [ 'baseProjection', 'auxProjection', 'isExportable' ]),
	},
	async mounted () {
    await this.$nextTick()
    await this.init()
    await this.fetchRules()
	},
	watch: {
		async filterProps () {
			await this.init()
		},
    async projectionProps () {
      await this.init()
    },
		async entityDefinitions () {
			await this.init()
		},
		async $route () {
			await this.init()
		},
		async exportDownloadUrl (value) {
			if (value !== null) {
				await this.$nextTick()
        this.$refs.exportDownload.click()
        this.SET_EXPORT_DOWNLOAD_URL(null)
			}
		}
	},
	methods: {
    async init () {
      await this.$nextTick()
      await this.setMissingFilterValues()
      let kind, type, value
      if (this.kind && this.type && this.value) {
        kind = this.kind
        type = this.type
        value = this.value
      } else {
        let query = this.$route.query;
        if (Object.keys(query).length > 0) {
          kind = query.kind
          type = query.type
          value = query.value
        }
      }
      if (kind && type && value) {
        let filter
        let payload = Object.assign(value && { value } || { isNull: true }, { name: type })
        if (kind === 'property') {
          filter = this.$refs[this.filterRefId(type)]
          filter = filter && filter[0]
          if (filter) {
            this.setPropertyFilterValue(payload)
          }
        } else {
          await this.setEntityFilterValue(payload)
          filter = this.$refs['entity-filter']
        }
        if (filter && filter.aux && !this.filterAux) {
          this.TOGGLE_FILTER_AUX()
        }
        await this.fetchData()
      }
    },
		showDetails ({ id, property, value }) {
			this.$refs.popup.open({ kind: 'property', type: property.name, id, value })
		},
		filterRefId (property) {
			return 'filter-' + property
		},
    forceDownload (url) {
			const anchor = document.createElement('a')
			anchor.href = url
			anchor.setAttribute('download', 'export.xlsx')
			// anchor.setAttribute('')
		},
		...mapActions('monitoring', [ 'setMissingFilterValues', 'fetchData', 'exportData' ]),
    ...mapActions('monitoring/filterValues', [ 'setEntityFilterValue', 'setPropertyFilterValue' ]),
		...mapActions('rules', [ 'fetchRules' ]),
		...mapMutations('monitoring', [ 'TOGGLE_FILTER_AUX', 'TOGGLE_PROJECTION_AUX', 'SET_EXPORT_DOWNLOAD_URL' ]),
    ...mapMutations('monitoring/filterValues', [ 'SET_RULE_FILTER_VALUE' ])
	}
}
</script>

<style scoped>
.export {
	margin-top: -8px;
}
</style>
