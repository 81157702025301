<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import 'bootstrap-css-only/css/bootstrap.min.css';
@import 'mdbvue/build/css/mdb.css';
@import '@/main.css';
@import '@/grid.css';
</style>
