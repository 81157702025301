import Vue from 'vue'

import call from '@/helpers/call'
import { _call, fromEntries } from '@/helpers/javascript'

// initial state
const state = {
	lists: {},
	entities: {},
	listsEntities: [],
	entityLists: {}
}

// getters
const getters = {}

// actions
const actions = {
	fetchLists ({commit}) {
		call('lists', 'get', {
			resolve(data) {
				commit('SET_LISTS', data)
			},
			reject(error) {
				commit('ADD_ERROR', error, {root: true})
			}
		})
	},

	addList ({ commit }, { name, after }) {
		call('lists', 'add', {
			resolve (data) {
				commit('SET_LIST', data)
				_call(after, data)
			},
			reject (error) {
				commit('ADD_ERROR', error, {root: true})
			}
		}, name)
	},

	removeList ({ commit }, { name, after }) {
		call('lists', 'remove', {
			resolve (data) {
				if (data.done) {
					commit('UNSET_LIST', name)
					_call(after)
				}
			},
			reject (error) {
				commit('ADD_ERROR', error, {root: true})
			}
		}, name)
	},

	fetchEntityLists ({ commit }, { id, after }) {
		call('lists', 'getLists', {
			resolve (data) {
				commit('SET_ENTITY_LISTS', data)
				_call(after)
			},
			reject (error) {
				commit('ADD_ERROR', error, {root: true})
			}
		}, id)
	},

	fetchListsEntities ({ commit, dispatch, state }) {
		commit('RESET_LIST_ENTITIES')
		Object.keys(state.lists).forEach(name => dispatch('fetchListEntities', name))
	},

	fetchListEntities ({ commit }, id) {
		call('lists', 'getEntities', {
			resolve (data) {
				data.forEach(e => commit('SET_ENTITY', e))
				commit('SET_LIST_ENTITIES', data)
			},
			reject (error) {
				commit('ADD_ERROR', error, {root: true})
			}
		}, id)
	},

	addToList ({ commit }, { id, listName, reason }) {
		call('lists', 'addToList', {
			resolve (data) {
				commit('SET_ENTITY_LIST', data)
			},
			reject (error) {
				commit('ADD_ERROR', error, {root: true})
			}
		}, { id, listName, reason })
	},

	removeFromList ({ commit }, { id, listName }) {
		call('lists', 'removeFromList', {
			resolve (data) {
				if (data.done) {
					commit('UNSET_ENTITY_LIST', { listName })
				}
			},
			reject (error) {
				commit('ADD_ERROR', error, {root: true})
			}
		}, { id, listName })
	},

	addEntity ({ commit }, { id, listId, definitionName, value, reason }) {
		call('lists', 'addEntity', {
			resolve (data) {
				if (id && data.id !== id) {
					commit('UNSET_LIST_ENTITY', { id, listId })
				}
				commit('SET_LIST_ENTITY', data)
			},
			reject (error) {
				commit('ADD_ERROR', error, {root: true})
			}
		}, { id, listId, definitionName, value, reason })
	},

	removeEntity ({ commit }, { id, listId }) {
		call('lists', 'removeEntity', {
			resolve (data) {
				if (data.done) {
					commit('UNSET_LIST_ENTITY', { id, listId })
				}
			},
			reject (error) {
				commit('ADD_ERROR', error, {root: true})
			}
		}, { id, listId })
	},

	setTxEntities ({ commit }, { rows, projection }) {
		rows.forEach(row => {
			projection.forEach((p, i) => {
				if (row[2 * i] !== null) {
					commit('SET_ENTITY', { id: row[2 * i], definitionName: p, value: row[2 * i + 1] })
				}
			})
		})
	},

	upload ({ commit }, { listId, definitionName, reason, files }) {
		call('lists', 'upload', {
			resolve (data) {
				commit('SET_LIST_ENTITIES', data)
			},
			reject (error) {
				commit('ADD_ERROR', error, {root: true})
			}
		}, { listId, definitionName, reason, files })
	}
}

// mutations
const mutations = {
	SET_LISTS (state, lists) {
		state.lists = fromEntries(lists.map(l => [ l.name, l ]))
	},
	SET_LIST (state, list) {
		Vue.set(state.lists, list.name, list)
	},
	UNSET_LIST (state, listName) {
		state.listsEntities = state.listsEntities.filter(e => e.listName !== listName)
		Vue.delete(state.lists, listName)
	},
	SET_ENTITY (state, { id, definitionName, value }) {
		if (!(id in state.entities)) {
			Vue.set(state.entities, id, { id, definitionName, value })
		}
	},
	SET_ENTITIES (state, entities) {
		state.entities = fromEntries(entities.map(e => [ e.id, { id: e.id, definitionName: e.definitionName, value: e.value } ]))
	},
	RESET_LIST_ENTITIES (state) {
		state.listsEntities = []
	},
	SET_LIST_ENTITIES (state, data) {
		state.listsEntities = state.listsEntities.concat(data)
	},
	SET_LIST_ENTITY (state, data) {
		state.listsEntities = state.listsEntities.filter(e => e.id !== data.id || e.listId !== data.listId).concat(data)
	},
	UNSET_LIST_ENTITY (state, { id, listId }) {
		state.listsEntities = state.listsEntities.filter(e => e.id !== id || e.listId !== listId)
	},
	SET_ENTITY_LISTS (state, data) {
		state.entityLists = fromEntries(data.map(e => [ e.listName, { reason: e.reason, added: e.added } ]))
	},
	SET_ENTITY_LIST (state, { listName, reason, added }) {
		Vue.set(state.entityLists, listName, { reason, added })
	},
	UNSET_ENTITY_LIST (state, { listName }) {
		Vue.delete(state.entityLists, listName)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
