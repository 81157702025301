<template>
	<mdb-col class="pb-1" xl="1_67" lg="3" md="4" sm="6" col="12">
		<label :for="kind + '-' + name + '-input'" class="filter small">{{ label }}</label>
		<null-filter class="ml-2" :disabled="disabled" v-model="isNull"/>
		<input type="text" :id="kind + '-' + name + '-input'" v-model.lazy="matchValue" :disabled="disabled || isNull" class="form-control pb-0 pt-0" />
	</mdb-col>
</template>

<script>
import NullFilter from './NullFilter'

export default {
	name: 'MatchFilter',
	components: { NullFilter },
	props: {
    kind: String,
    name: String,
    disabled: Boolean,
    label: String,
    value: Object
	},
	computed: {
		matchValue: {
			get () {
				return this.value && this.value.value
			},
			set (value) {
        this.$emit('input', { value })
			}
		},
		isNull: {
      get () {
        return this.value && this.value.isNull || false
      },
      set (isNull) {
        this.$emit('input', { isNull })
      }
		}
	}
}
</script>

<style scoped>
input {
	padding-left: 0.2rem;
	padding-right: 0.2rem;
	height: calc(1em + 0.75rem + 1px);
}
label.filter {
	display: inline-block;
}
</style>
