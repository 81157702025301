<template>
	<mdb-row class="bg-white">
		<mdb-col col="12" class="mr-2">
			<mdb-row>
				<mdb-col>
					<mdb-select :btn-save="false" :options="rulesetsOptions" @change="select"></mdb-select>
				</mdb-col>
			</mdb-row>
			<template v-if="ruleset">
				<mdb-row class="justify-content-center">
					<draggable class="col" :list="rulesetRules(ruleset)" v-bind="dragOptions" :move="onMove" @change="onMoved({ rulesetName: ruleset.name }, $event)" :componentData="{ rulesetName: ruleset.name }">
						<rule-view v-for="rule in rulesetRules(ruleset)" :key="rule.name" :rule="rule" class="mx-1 my-2" @delete="excludeRule({ rulesetName: ruleset.name, name: rule.name })"></rule-view>
					</draggable>
				</mdb-row>
				<mdb-row>
					<mdb-col>
						<mdb-select v-if="rulesOptions.length" :btn-save="false" :options="rulesOptions" @change="onChange({ rulesetName: ruleset.name, name: $event })"/>
					</mdb-col>
				</mdb-row>
				<mdb-icon color="primary" icon="times-circle" @click.native="remove" class="c-pointer position-absolute left-top"></mdb-icon>
			</template>
			<template v-else>
				<mdb-row>
					<mdb-col>
						<mdb-input label="name" @change="saveRuleset"></mdb-input>
					</mdb-col>
				</mdb-row>
			</template>
		</mdb-col>
	</mdb-row>
</template>

<script>
import Draggable from 'vuedraggable'
import { mdbIcon, mdbInput, mdbSelect } from 'mdbvue'
import { mapState, mapActions, mapMutations } from 'vuex'

import RuleView from './RuleView.vue'

export default {
	name: 'RulesetsPage',
	components: { mdbIcon, mdbInput, mdbSelect, Draggable, RuleView },
	async created () {
		await this.fetchRules({ after: this.fetchRulesets })
	},
	computed: {
		ruleset () {
			return this.rulesetName && this.rulesets[this.rulesetName]
		},
		rulesetsOptions () {
			return [{ text: this.$t('rules.rulesets.new', this.locale), value: null, selected: this.rulesetName === null }].concat(Object.values(this.rulesets).map(r => ({ text: r.name, value: r.name, selected: r.name === this.rulesetName })))
		},
		rulesOptions () {
			return Object.values(this.rules).filter(r => !this.ruleset.rules.includes(r.name)).map(r => ({ text: r.name, value: r.name }))
          .concat({ text: 'Выберите правило для добавления в набор', value: null, selected: true })
		},
		dragOptions () {
			return {
				animation: 0,
				disabled: false,
				draggable: '.rule-wrapper',
				group: 'rules',
				ghostClass: 'ghost',
				tag: 'div'
			}
		},
		...mapState([ 'locale' ]),
		...mapState('rules', [ 'rules', 'rulesets', 'rulesetName' ])
	},
	methods: {
		select (rulesetName) {
			this.SET_RULESET_NAME(rulesetName)
		},
		saveRuleset (value) {
			if (value !== '') {
				this.createRuleset({ ruleset: { name: value, rules: [] }, after: (ruleset) => this.SET_RULESET_NAME(ruleset.name) })
			}
		},
		remove () {
			if (confirm(this.$t('common.confirm-removal', this.locale))) {
				this.deleteRuleset(this.ruleset)
			}
		},
		rulesetRules (ruleset) {
			return ruleset.rules.map(name => Object.assign({}, this.rules[name], { rulesetName: ruleset.name }))
		},
		onMove ({relatedContext, draggedContext}) {
			if (!draggedContext.element) {
				console.error('Warning! Dragged context has no element! See dragged context contents:', draggedContext)
				return false
			} else {
				return relatedContext.component.componentData.rulesetName === draggedContext.element.rulesetName
			}
		},
    onMoved ({ rulesetName }, event) {
      let eventData
      if ('moved' in event) {
        eventData = event.moved
      } else if ('added' in event) {
        eventData = event.added
      } else {
        return
      }
      this.replaceRule({ rulesetName, name: eventData.element.name, value: eventData.newIndex })
    },
		onChange ({ rulesetName, name }) {
      if (name !== null) {
        this.includeRule({ rulesetName, name })
      }
		},
		excludeRule ({ rulesetName, name }) {
			this.replaceRule({ rulesetName, name, value: null })
		},
		...mapActions('rules', [ 'fetchRules', 'fetchRulesets', 'createRuleset', 'deleteRuleset', 'includeRule', 'replaceRule' ]),
		...mapMutations('rules', [ 'SET_RULESET_NAME' ])
	}
}
</script>

<style scoped>

</style>
