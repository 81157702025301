<template>
	<mdb-row>
		<mdb-col col="12">
			<mdb-row class="mx-2 justify-content-center">
				<mdb-select multiple :fixed-input-text="$t('graph.node-types.select-label', locale)" @getValue="getSelectValue" :options="options" :btn-save="false" class="bg-white"/>
			</mdb-row>
		</mdb-col>
	</mdb-row>
</template>

<script>
import MdbSelect from '@/components/common/MdbSelect.vue'
import { mapState, mapGetters, mapActions } from 'vuex'

import { subtract } from '@/helpers/javascript'

export default {
	name: 'GraphDefinitions',
	components: { MdbSelect },
	computed: {
		options () {
			return [{ text: this.$t('graph.node-types.select-label', this.locale), value: null, disabled: true, selected: true }]
				.concat(this.allDefinitions.map((d) => ({ 'text': d.label, 'value': d.name, selected: Object.values(this.usedDefinitions).includes(d.name) })))
		},
		...mapState([ 'locale' ]),
		...mapState('graph', [ 'usedDefinitions' ]),
		...mapGetters('graph', [ 'allDefinitions' ])
	},
	methods: {
    getSelectValue (value) {
			let deleted = subtract(Object.values(this.usedDefinitions), value)
			let added = subtract(value, Object.values(this.usedDefinitions)).filter(d => d !== null)
			if (deleted && deleted.length) {
				this.removeDefinitions(deleted)
			}
			if (added && added.length) {
				this.addDefinitions(added)
			}
		},
		...mapActions('graph', [ 'addDefinitions', 'removeDefinitions' ])
	}
}
</script>

<style scoped>
>>> input.select-dropdown {
	text-align: center;
}
</style>
