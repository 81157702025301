<template>
  <mdb-row>
    <mdb-col>
      <mdb-row class="justify-content-center">
        <menu-item name href="EntitiesIndex" :col="col">Entities</menu-item>
        <menu-item name href="RelationsIndex" :col="col">Relations</menu-item>
        <menu-item name href="BindingsIndex" :col="col">Bindings</menu-item>
      </mdb-row>
    </mdb-col>
    <div class="w-100"></div>
    <mdb-col class="mr-2">
      <router-view></router-view>
    </mdb-col>
  </mdb-row>
</template>

<script>
import MenuItem from '@/components/common/MenuItem'

export default {
  name: 'SchemaIndex',
  components: { MenuItem },
  computed: {
    col () {
      return { col: '12', sm: '6', md: '4', lg: '3' }
    }
  }
}
</script>
