import { render, staticRenderFns } from "./EntityLists.vue?vue&type=template&id=0284e9bd&scoped=true&"
import script from "./EntityLists.vue?vue&type=script&lang=js&"
export * from "./EntityLists.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0284e9bd",
  null
  
)

export default component.exports