import Vue from 'vue';

function SecurityProvider (router, store) {
    return new Vue({
        data: {
            router,
            store,
            from: null,
            to: null
        },
        computed: {
            user () {
                return this.store.state.user
            },
            role () {
                return this.user.role
            },
            redirect () {
                let redirect = null
                if (this.to) {
                    this.to.matched.filter(record => record.meta).forEach(record => {
                        if (redirect === null) {
                            if (this.isAuthRequiredAndFailed(record)) {
                                redirect = this.redirectWithFunctionOrDefault(
                                    record, to => ({ path: '/login',
                                        query: { redirect: encodeURIComponent(to.fullPath) }
                                    })
                                )
                            } else if (this.isRouteForbiddenByRole(record)) {
                                redirect = this.redirectWithFunctionOrDefault(
                                    record, to => ({ path: '/', query: to.query })
                                )
                            } else if (this.isRedirectObject(record)) {
                                redirect = record.meta.redirect[this.user.role] || null
                            }
                        }
                    })
                }
                if (redirect === null) {
                    return redirect
                } else {
                    return redirect(this.to)
                }
            }
        },
        watch: {
            user () {
                let redirect = this.redirect
                if (redirect !== null) {
                    this.router.push(redirect)
                }
            }
        },
        methods: {
            resolve (to, from, next) {
                this.from = from
                this.to = to
                let redirect = this.redirect
                if (redirect === null) {
                    next()
                } else {
                    next(redirect)
                }
            },
            redirectWithFunctionOrDefault (record, defaultValue) {
                if (record.meta.redirect && record.meta.redirect.constructor === Function) {
                    return record.meta.redirect
                } else {
                    return defaultValue
                }
            },
            isRedirectObject (record) {
                return record.meta.redirect && record.meta.redirect.constructor === Object
            },
            isAuthRequiredAndFailed (record) {
                return record.meta.requiresAuth && (this.user.pending || this.user.role === 'unauthorized' || this.user.role === 'guest')
            },
            isRouteForbiddenByRole (record) {
                return record.meta.requiresRole && (
                    (record.meta.requiresRole.constructor === String && record.meta.requiresRole !== this.user.role) ||
                    (record.meta.requiresRole.constructor === Array && !record.meta.requiresRole.includes(this.user.role))
                )
            }
        }
    })
}

function plugin (Vue, opts) {
    if (!opts.router) {
        throw new Error('You must supply a router instance in the options.')
    }
    if (!opts.store) {
        throw new Error('You must supply a store instance in the option')
    }
    const sp = new SecurityProvider(opts.router, opts.store)
    opts.router.beforeEach((to, from, next) => sp.resolve(to, from, next))
}

export default plugin

/*
if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(plugin)
}
*/
