<template>
	<mdb-modal v-if="modal" @close="close" size="lg">
		<mdb-modal-header class="text-dark pb-0">
			<mdb-modal-title class="font-weight-bold">{{ label }}: {{ value }}</mdb-modal-title>
		</mdb-modal-header>
		<mdb-modal-body class="pb-0">
			<tx-rules v-if="isTxId" :fired-only="firedOnly" @toggle-fired-only="toggleFiredOnly"></tx-rules>
			<entity-lists v-if="listable" :id="id"></entity-lists>
		</mdb-modal-body>
		<mdb-modal-footer class="pt-0">
			<mdb-btn-group v-if="isTxId" block class="text-left">
				<mdb-btn color="white" size="sm" class="text-dark font-weight-bold fs-0_75" v-if="firedOnly" @click="toggleFiredOnly">{{ $t('common.show', locale) }}</mdb-btn>
				<mdb-btn color="white" size="sm" class="text-dark font-weight-bold fs-0_75" v-else @click="toggleFiredOnly">{{ $t('common.hide', locale) }}</mdb-btn>
			</mdb-btn-group>
			<mdb-btn-group block class="text-right">
				<router-link v-if="filterable" :to="toFilter" target="_blank">
					<mdb-btn size="sm" :waves="false" class="text-dark font-weight-bold fs-0_75" color="info">{{ $t('node-details-popup.transactions', locale) }}</mdb-btn>
				</router-link>
				<router-link v-if="graphable" :to="toGraph" target="_blank">
					<mdb-btn size="sm" :waves="false" class="text-dark font-weight-bold fs-0_75" color="info">{{ $t('node-details-popup.graph', locale) }}</mdb-btn>
				</router-link>
			</mdb-btn-group>
		</mdb-modal-footer>
	</mdb-modal>
</template>

<script>
import { mdbBtnGroup, mdbBtn, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter } from 'mdbvue'
import { mapState, mapMutations } from 'vuex'

import TxRules from '@/components/common/TxRules'
import EntityLists from '@/components/common/EntityLists'

export default {
	name: 'NodeDetailsPopup',
	components: { TxRules, EntityLists, mdbBtnGroup, mdbBtn, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter },
	data () {
		return {
			kind: null,
      type: null,
			id: null,
			value: null,
			modal: false,
			firedOnly: true
		}
	},
	computed: {
		label () {
			return (this.property && this.property.name) || (this.entityDefinition && this.entityDefinition.label)
		},
		entityDefinition () {
			return this.entityDefinitions[this.definitionName]
		},
		definitionName () {
			if (this.kind === 'property') {
				return this.property && this.property.definitionName
			} else {
				return this.type
			}
		},
		property () {
			if (this.kind === 'property') {
				return this.allProps[this.type]
			} else {
        return null
      }
		},
		entityType () {
			return this.entityDefinition && this.entityDefinition.type
		},
		graphable () {
			return Object.values(this.usedDefinitions).includes(this.definitionName)
		},
		listable () {
			return this.entityType === 'String'
		},
		filterable () {
			return this.entityType === 'String' && !this.isTxId
		},
		isTxId () {
			return this.entityDefinition.name === this.$constants.rootPath
		},
		toFilter () {
			return { name: 'monitoring', query: { kind: this.kind, type: this.type, value: this.value } }
		},
		toGraph () {
			return { name: 'graph', params: { filter: this.definitionName, value: this.value } }
		},
		...mapState([ 'locale' ]),
		...mapState('graph', [ 'usedDefinitions' ]),
		...mapState('schema', [ 'entityDefinitions' ]),
    ...mapState('properties', [ 'allProps' ])
	},
	methods: {
		open ({ kind = 'property', type, id, value }) {
			this.kind = kind
			this.type = type
			this.id = id
			this.value = value
			this.modal = true
			if (this.isTxId) {
				this.SET_TX_ID(value)
			}
		},
		close () {
			this.modal = false
			this.firedOnly = true
			this.SET_TX_ID(null)
		},
		toggleFiredOnly () {
			this.firedOnly = !this.firedOnly
		},
		...mapMutations('monitoring', [ 'SET_TX_ID' ])
	}
}
</script>

<style scoped>

</style>
