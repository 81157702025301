<template>
	<mdb-col class="text-left pl-0">
		<input type="text" :value="filterValue" @change="SET_FILTER_VALUE($event.target.value)"/>
		<mdb-icon class="text-white c-pointer my-auto" icon="search" @click.native="submit"></mdb-icon>
		<mdb-select :btn-save="false" :options="options" @change="select" class="d-inline-block bg-white pl-1 pb-0 mb-1"></mdb-select>
    <mdb-icon class="text-primary c-pointer my-auto" icon="bomb" @click.native="$emit('bomb')"/>
	</mdb-col>
</template>

<script>
import { mdbIcon, mdbSelect } from 'mdbvue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
	name: 'GFilter',
	props: [ 'filter', 'value' ],
	components: { mdbIcon, mdbSelect },
	computed: {
		definition () {
			return this.filterId && this.usedDefinitions && this.usedDefinitions[this.filterId]
		},
		options () {
			return [{ text: this.$t('graph.node-types.filter-label', this.locale), value: null, disabled: true, selected: !this.definition }]
				.concat(Object.values(this.usedDefinitions).map((d) => ({ 'text': d.label, 'value': d.id, selected: this.definition && (this.definition.id === d.id) })))
		},
		...mapState([ 'locale' ]),
		...mapState('graph', [ 'graphEntities', 'filterId', 'filterValue' ]),
		...mapGetters('graph', [ 'usedDefinitions' ])
	},
	async mounted () {
    await this.$nextTick()
    await this.applyFilter()
	},
	watch: {
		usedDefinitions () {
			if (!Object.keys(this.graphEntities).length) {
        this.$nextTick(this.applyFilter)
			}
		},
		$route () {
			if (!Object.keys(this.graphEntities).length) {
        this.$nextTick(this.applyFilter)
			}
		}
	},
	methods: {
		select (value) {
			this.SET_FILTER_ID(value)
		},
		async submit () {
      if (this.filterId && this.filterValue) {
        await this.init(this.clear)
      }
		},
		clear () {
			this.$emit('flush')
			this.$emit('autosize')
		},
		async applyFilter () {
      if (this.filter && this.value) {
        let filterType = Object.values(this.usedDefinitions).findLast(d => d.name === this.filter)
        if (filterType) {
          this.SET_FILTER_ID(filterType.id)
          this.SET_FILTER_VALUE(this.value)
          await this.submit()
        }
      }
		},
		...mapActions('graph', [ 'init' ]),
		...mapMutations('graph', [ 'SET_FILTER_ID', 'SET_FILTER_VALUE' ])
	}
}
</script>

<style scoped>
>>> input.select-dropdown {
	height: 34px !important;
}
</style>
