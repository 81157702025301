import { api } from '@/helpers/core'

export default {
	definitions () {
		return api.get('/graph/definitions/')
	},
	addDefinitions (ids) {
		return api.post('/graph/definitions/', ids)
	},
	removeDefinitions (ids) {
		return api.post('/graph/definitions/remove', ids)
	},
	init ({ definitionName, value }) {
		return api.get('/graph/items/' + encodeURIComponent(definitionName) + '/' + encodeURIComponent(value) + '/')
	},
	neighbors ({ sourceId, targetName }) {
		return api.post('/graph/items/', { sourceId, targetName })
	},
	txData ({ txIds, properties }) {
		return api.post('/graph/tx_data/', { txIds, projection: properties.map((p) => p.path) })
	}
}
