<template>
	<mdb-row>
		<mdb-col>
			<mdb-row class="justify-content-center">
				<menu-item name href="RulesetsPage" :col="col">Terminals</menu-item>
				<menu-item name href="RulesPage" :col="col">Rules</menu-item>
				<menu-item name href="RulesFields" :col="col">Functions</menu-item>
			</mdb-row>
		</mdb-col>
		<div class="w-100"></div>
		<mdb-col class="mr-2">
			<router-view></router-view>
		</mdb-col>
	</mdb-row>
</template>

<script>
import MenuItem from '@/components/common/MenuItem'

export default {
	name: 'RulesIndex',
	components: { MenuItem },
	computed: {
		col () {
			return { col: '12', sm: '6', md: '4', lg: '3' }
		}
	}
}
</script>
