import Vue from 'vue'

import call from '@/helpers/call'

import { _call, fromEntries } from '@/helpers/javascript'

// initial state
const state = {
	entityDefinitions: {},
	relationDefinitions: {}
}

// getters
const getters = {}

// actions
const actions = {
	fetchEntities ({ commit }, { after } = {}) {
		call('schema', 'getEntities', {
			resolve(data) {
				commit('SET_ENTITY_DEFINITIONS', data)
				_call(after)
			},
			reject(error) {
				commit('ADD_ERROR', error, {root: true})
			}
		})
	},

	createEntity ({ commit }, entity) {
		call('schema', 'addEntity', {
			resolve (data) {
				commit('SET_ENTITY_DEFINITION', data)
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, entity)
	},

	updateEntity ({ commit }, entity) {
		call('schema', 'updateEntity', {
			resolve (data) {
				commit('SET_ENTITY_DEFINITION', data)
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, entity)
	},

	deleteEntity ({ commit }, entity) {
		call('schema', 'deleteEntity', {
			resolve () {
				commit('DELETE_ENTITY_DEFINITION', entity)
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, entity)
	}
}

// mutations
const mutations = {
	SET_ENTITY_DEFINITIONS (state, entityDefinitions) {
		state.entityDefinitions = fromEntries(entityDefinitions.map(e => [ e.name, e ]))
	},
	SET_ENTITY_DEFINITION (state, entityDefinition) {
		Vue.set(state.entityDefinitions, entityDefinition.name, entityDefinition)
	},
	DELETE_ENTITY_DEFINITION (state, entityDefinition) {
		Vue.delete(state.entityDefinitions, entityDefinition.name)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
