<template>
	<tr :class="{ 'selected': isSelected } ">
		<monitoring-cell v-for="(property, index) in baseProjection" @show-details="showDetails" :key="property.name"
                     :id="getId(index)" :value="getValue(index)" :property="property" :is-denied="isDenied"/>
		<monitoring-cell v-show="projectionAux" v-for="(property, index) in auxProjection" @show-details="showDetails"
                     :key="property.name" :id="getId(index)" :value="getValue(index + baseProjection.length)"
                     :property="property" :is-denied="isDenied"/>
	</tr>
</template>

<script>
import { mapState } from 'vuex'

import MonitoringCell from './MonitoringCell.vue'

export default {
	name: 'MonitoringRow',
	components: { MonitoringCell },
	props: {
		baseProjection: {
			type: Array,
			required: true
		},
		auxProjection: {
			type: Array,
			required: true
		},
		projectionAux: {
			type: Boolean,
			required: true
		},
		row: {
			type: Array,
			required: true
		}
	},
	computed: {
		showDetails () {
			return this.$listeners['show-details']
		},
		id () {
			return this.row[1]
		},
		isSelected () {
			return this.id === this.txId
		},
		status () {
			return this.row[this.row.length - 1]
		},
		isDenied () {
			return this.status === 'Deny'
		},
		...mapState('monitoring', [ 'txId' ])
	},
	methods: {
		getId (index) {
			return this.row[2 * index]
		},
		getValue (index) {
			return this.row[2 * index + 1]
		}
	}
}
</script>

<style scoped>
tr.selected {
	background-color: #ffc9bf;
}
</style>
