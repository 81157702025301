<template>
  <mdb-col class="pb-1" xl="1_67" lg="3" md="4" sm="6" col="12" v-show="!disabled">
  </mdb-col>
</template>

<script>
export default {
  name: 'BooleanFilter',
  props: {
    disabled: Boolean
  },
  mounted () {
    alert('Ошибка: использование нереализованного компонента monitoring/BooleanFilter')
  }
}
</script>

<style scoped>

</style>
