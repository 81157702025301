<template>
	<div class="dataTables_wrapper" :style="wrapperStyle">
		<!-- Entries input and search -->
		<mdb-row>
			<mdb-col sm="8" md="9">
				<mdb-row>
					<mdb-col sm="12" md="5">
						<datatable-select v-if="pagination" @getValue="updateEntries" :options="options" class="mr-3"/>
					</mdb-col>
					<slot name="pre-fieldset"></slot>
					<mdb-col col="1">
						<mdb-btn @click="updateData" v-if="refresh" size="sm" class="mb-3" outline="primary" >
							<mdb-icon  icon="sync" />
						</mdb-btn>
					</mdb-col>
				</mdb-row>
			</mdb-col>
			<mdb-col sm="4" md="3" v-if="searching">
				<datatable-search @getValue="updateSearch" />
			</mdb-col>
		</mdb-row>
		<!-- Entries input and search -->

		<slot name="pre-table"></slot>

		<!-- Main table -->
		<tbl v-if="!scrollY" v-bind="tableProps" sm datatable :key="componentKey">
			<tbl-head :color="headerColor" :textWhite="headerWhite">
				<tr>
					<slot name="pre-th" :rows="pages[activePage]"></slot>
					<th
							v-for="column in columns"
							:key="column.field"
							class="th-sm sorting"
							v-on:click="sort(column.field, column.sort)"
					>
						{{column.label}} <i v-if="sorting" class="fas fa-sort float-right"></i>
					</th>
					<slot name="post-th" :rows="pages[activePage]"></slot>
				</tr>
			</tbl-head>
			<tbl-body>
				<tr v-for="row in pages[activePage]" :key="row.key">
					<slot name="pre-td" :row="row"></slot>
					<td v-for="(value, index) in row" :key="index">
						<slot :name="'_' + columns[index].field" :field="columns[index].field" :row="row" :index="index" :value="value">
							<div v-html="value"></div>
						</slot>
					</td>
					<slot name="post-td" :row="row"></slot>
				</tr>
				<tr v-if="!pages.length && !$slots['last-row']">
					<td :colspan="columns.length">No matching records found</td>
				</tr>
				<slot name="last-row"></slot>
			</tbl-body>
			<tbl-head v-if="tfoot" tag="tfoot">
				<tr>
					<slot name="pre-th-foot"></slot>
					<th
							v-for="column in columns"
							:key="column.field+'_foot'"
							class="th-sm sorting"
					>
						{{column.label}}
					</th>
					<slot name="post-th-foot"></slot>
				</tr>
			</tbl-head>
		</tbl>
		<!-- Main table -->

		<!-- ScrollY table -->
		<div v-if="scrollY" class="dataTables_scroll" :key="componentKey">
			<div v-if="scrollY" class="dataTables_scrollHead" style="padding-right: 15px">
				<div class="dataTables_scrollHeadInner">
					<tbl v-bind="tableProps" sm datatable>
						<tbl-head :color="headerColor" :textWhite="headerWhite">
							<tr>
								<slot name="pre-th"></slot>
								<th
										v-for="column in columns"
										:key="column.field"
										class="th-sm sorting"
										v-on:click="sort(column.field, column.sort)"
								>
									{{column.label}} <i v-if="sorting" class="fas fa-sort float-right"></i>
								</th>
								<slot name="post-th"></slot>
							</tr>
						</tbl-head>
					</tbl>
				</div>
			</div>
			<tbl v-bind="tableProps" sm datatable>
				<tbl-body>
					<tr v-for="row in pages[activePage]" :key="row.key">
						<slot name="pre-td" :row="row"></slot>
						<td v-for="(value, index) in row" :key="index">
							<slot :name="'_' + columns[index].field" :field="columns[index].field" :row="row" :index="index" :value="value">
								<div v-html="value"></div>
							</slot>
						</td>
						<slot name="post-td" :row="row"></slot>
					</tr>
					<tr v-if="!pages.length && !$slots['last-row']">
						<td :colspan="columns.length">No matching records found</td>
					</tr>
					<slot name="last-row"></slot>
				</tbl-body>
			</tbl>
			<div class="dataTables_scrollFoot" style="padding-right: 15px">
				<div class="dataTables_scrollFootInner">
					<tbl v-bind="tableProps" sm datatable>
						<tbl-head v-if="tfoot" tag="tfoot">
							<tr>
								<slot name="pre-th-foot"></slot>
								<th
										v-for="column in columns"
										:key="column.field+'_foot'"
										class="th-sm sorting"
								>
									{{column.label}}
								</th>
								<slot name="post-th-foot"></slot>
							</tr>
						</tbl-head>
					</tbl>
				</div>
			</div>
		</div>
		<!-- ScrollY table -->

		<!-- Labels, filter and pagination -->
		<div v-if="pagination" class="row">
			<div class="col-sm-12 col-md-5">
				<div class="dataTables_info" role="status" aria-live="polite">
					Showing {{activePage > 0 ? activePage * entries : activePage + 1}} to {{pages.length-1 > activePage ? pages[activePage].length*(activePage + 1) : filteredRows.length}} of {{filteredRows.length}} entries
				</div>
			</div>
			<div v-if="filter" class="pl-3">
				<label>Filter {{filter}}:</label>
				<mdb-select v-if="this.filterOptions.length!==0" class="mt-0 w-50 d-inline-block" @getValue="updateSelect" :options="filterOptions" />
			</div>
			<div class="col-sm-12 col-md-7">
				<div class="dataTables_paginate float-right">
					<pagination id="pagination">
						<page-item
								v-if="pages.length > display"
								v-on:click.native="changePage(0)"
								:disabled="activePage === 0"
						>
							<mdb-icon v-if="arrows"  icon="angle-double-left" />
							<p v-else class="pagination">{{start}}</p>
						</page-item>
						<page-item
								v-on:click.native="changePage(activePage-1)"
								:disabled="activePage === 0"
						>
							<mdb-icon v-if="arrows"  icon="angle-left" />
							<p v-else class="pagination">{{previous}}</p>
						</page-item>
						<page-item
								v-for="(page, index) in visiblePages"
								:key="index"
								v-on:click.native="changePage(pages.indexOf(visiblePages[index]))"
								:active="activePage === pages.indexOf(visiblePages[index])"
						>
							{{pages.indexOf(visiblePages[index]) + 1}}
						</page-item>
						<page-item
								v-on:click.native="changePage(activePage + 1)"
								:disabled="activePage === pages.length - 1"
						>
							<mdb-icon v-if="arrows"  icon="angle-right" />
							<p v-else class="pagination">{{next}}</p>
						</page-item>
						<page-item
								v-if="pages.length > display"
								v-on:click.native="changePage(pages.length - 1)"
								:disabled="activePage === pages.length - 1"
						>
							<mdb-icon v-if="arrows"  icon="angle-double-right" />
							<p v-else class="pagination">{{end}}</p>
						</page-item>
					</pagination>
				</div>
			</div>
		</div>
		<!-- Labels, filter and pagination -->

		<slot name="post-table"></slot>
	</div>
</template>

<script>
import { Tbl, TblHead, TblBody, Pagination, PageItem, mdbSelect, mdbIcon, mdbRow, mdbCol, mdbBtn } from 'mdbvue'
import DatatableSearch from 'mdbvue/src/components/pro/Tables/DatatableSearch'
import DatatableSelect from 'mdbvue/src/components/pro/Tables/DatatableSelect'

export default {
	name: 'DataTable',
	props: {
		data: {
			type: [Object, String],
			default: () => ({
				columns: [],
				rows: []
			})
		},
		autoWidth: {
			type: Boolean,
			default: false
		},
		bordered: {
			type: Boolean,
			default: false
		},
		borderless: {
			type: Boolean,
			default: false
		},
		dark: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		},
		headerColor: {
			type: String
		},
		headerWhite: {
			type: Boolean,
			default: false
		},
		hover: {
			type: Boolean,
			default: false
		},
		materialInputs: {
			type: Boolean,
			default: false
		},
		maxWidth: {
			type: String
		},
		maxHeight: {
			type: String
		},
		order: {
			type: Array
		},
		pagination: {
			type: Boolean,
			default: true
		},
		responsive: {
			type: Boolean,
			default: false
		},
		responsiveSm: {
			type: Boolean,
			default: false
		},
		responsiveMd: {
			type: Boolean,
			default: false
		},
		responsiveLg: {
			type: Boolean,
			default: false
		},
		responsiveXl: {
			type: Boolean,
			default: false
		},
		scrollY: {
			type: Boolean,
			default: false
		},
		searching: {
			type: Boolean,
			default: true
		},
		sorting: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: false
		},
		start: {
			type: String,
			default: 'Start'
		},
		end: {
			type: String,
			default: 'End'
		},
		next: {
			type: String,
			default: 'Next'
		},
		previous: {
			type: String,
			default: 'Previous'
		},
		filter: {
			type: String,
			default: ''
		},
		arrows: {
			type: Boolean,
			default: false
		},
		display: {
			type: Number,
			default: 5
		},
		defaultRow: {
			type: String,
			default: '-'
		},
		defaultCol: {
			type: String,
			default: 'undefined'
		},
		tfoot: {
			type: Boolean,
			default: true
		},
		reactive: {
			type: Boolean,
			default: false
		},
		refresh: {
			type: Boolean,
			default: false
		},
		time: {
			type: Number,
			default: 5000
		},
		nullsLast: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			updatedKey: null,
			reactiveFlag: false,
			recentSort: null,
			interval: null,
			options: [ { value: 10, text: 10, selected: true }, { value: 25, text: 25 }, { value: 50, text: 50 }, { value: 100, text: 100 } ],
			entries: 10,
			pages: [],
			activePage: 0,
			search: '',
			select: '',
			filteredArray: [],
			filterOptions: [],
			tableProps: {
				autoWidth: this.autoWidth,
				bordered: this.bordered,
				borderless: this.borderless,
				dark: this.dark,
				fixed: this.fixed,
				hover: this.hover,
				responsive: this.responsive,
				responsiveSm: this.responsiveSm,
				responsiveMd: this.responsiveMd,
				responsiveLg: this.responsiveLg,
				responsiveXl: this.responsiveXl,
				striped: this.striped,
				dtScrollY: this.scrollY,
				maxHeight: this.maxHeight
			},
			wrapperStyle: {
				maxWidth: this.maxWidth ? this.maxWidth : '100%',
				margin: '0 auto'
			},
			rows: [],
			columns: []
		}
	},
	components: {
		DatatableSearch,
		DatatableSelect,
		Tbl,
		TblHead,
		TblBody,
		Pagination,
		PageItem,
		mdbSelect,
		mdbIcon,
		mdbRow,
		mdbCol,
		mdbBtn
	},
	computed: {
		rowsDisplay () {
			return this.formatRows()
		},
		// filter objects by parameters match
		filteredRows () {
			let rows = this.rowsDisplay
			if (this.filter) {
				rows = this.selectedRows
			}
			return rows.filter(row => {
				return row.filter(value => value
					.toString()
					.toLowerCase()
					.match(this.search.toLowerCase()))
					.length > 0
			})
		},
		selectedRows () {
			return this.rowsDisplay.filter(row => {
				return row.filter(value => value
					.toString()
					.toLowerCase() === this.select.toLowerCase())
					.length > 0
			})
		},
		visiblePages () {
			let start = this.activePage - Math.floor(this.display / 2) > 0 ? this.activePage - Math.floor(this.display / 2) : 0
			let end = start + this.display < this.pages.length ? start + this.display : this.pages.length
			if (end - start < this.display && end - this.display >= 0) {
				start = end - this.display
			}
			return this.pages.slice(start, end)
		},
		componentKey () {
			return this.updatedKey
		}
	},
	methods: {
		setRows (rows) {
			this.rows = rows.map(r => Object.assign({}, r))
		},
		setColumns (columns) {
			this.columns = columns.map(c => Object.assign({}, c))
		},
    setSort () {
      if (this.sorting) {
        this.columns.filter(c => c.sort).forEach(c => this.sort(c.field, c.sort))
      }
    },
		changePage (index) {
			this.activePage = index
		},
		sort (field, sort) {
			this.recentSort = {field, sort}
			if (this.sorting) {
				sort === 'asc'
					? this.rows.sort((a, b) => ((!a[field] && this.nullsLast) || (!b[field] && !this.nullsLast) || (a[field] && b[field] && a[field] > b[field])) ? 1 : -1)
					: this.rows.sort((a, b) => ((!a[field] && this.nullsLast) || (!b[field] && !this.nullsLast) || (a[field] && b[field] && a[field] > b[field])) ? -1 : 1)
				this.columns[this.columns.findIndex(column => column.field === field)].sort = sort === 'asc' ? 'desc' : 'asc'
        this.$emit('sort', { field, sort: sort === 'desc' ? sort : 'asc' })
			}
		},
		updateEntries (value) {
			this.entries = value
		},
		updateSearch (value) {
			this.search = this.escapeRegExp(value)
			this.activePage = 0
		},
		updateSelect (value) {
			this.select = value
		},
		setDefaultColumns () {
			this.columns.forEach((col, i) => {
				if (!col) {
					this.columns[i] = {
						label: this.defaultCol,
						field: this.defaultCol.concat(i),
						sort: 'asc'
					}
				}
			})
		},
		formatRows () {
			this.setDefaultColumns()
			let arrRows = []
			let headers = this.columns.map(col => col.field)
			this.rows.map(row => {
				let newRow = []
				headers.forEach(header => {
					let content = row[header] || this.defaultRow
					newRow.push(content)
				})
				newRow.row = row
				newRow.key = row.key || row.id
				arrRows.push(newRow)
			})
			return arrRows
		},
		filterArray () {
			if (this.filter) {
				this.rows.map(row => {
					if (this.filteredArray.indexOf(row[this.filter]) === -1) {
						this.filteredArray.push(row[this.filter])
					}
				})
				this.filteredArray.sort()
				this.filteredArray = this.filteredArray.filter((elem, index, self) => {
					return index === self.indexOf(elem)
				})

				const existingOptions = this.filterOptions.map(option => option.value)
				this.filteredArray.forEach(option => {
					if (existingOptions.indexOf(option) === -1) {
						this.filterOptions.push({value: option, text: option})
					}
				})
			}
		},
		escapeRegExp (string) {
			return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
		},
		fetchData () {
			fetch(this.data)
				.then(res => res.json())
				.then(json => {
					this.columns = json.columns
					this.rows = json.rows
					this.$emit('fields', this.columns)
				})
				.then(() => {
					if (this.recentSort) {
						this.sort(this.recentSort.field, this.recentSort.sort)
					}
				})
				.catch(err => console.error(err))
		},
		updateData () {
			if (typeof this.data === 'string') {
				this.fetchData()
				this.reactiveFlag = true
				this.updatedKey = Math.floor(Math.random() * 100000000)
			} else {
				this.$emit('sync')
			}
		}
	},
	beforeMount () {
		this.filterArray()
	},
	async mounted () {
		// bind data or download form API
		if (typeof this.data === 'string') {
			await this.fetchData()
		} else {
			this.setColumns(this.data.columns)
			this.setRows(this.data.rows)
      this.setSort()
		}

		// reactivness in data table
		if (this.reactive) {
			this.interval = setInterval(this.updateData, this.time)
		}
		// findout rows amount, and slice it into array (split into pages)
		const pagesAmount = Math.ceil(this.filteredRows.length / this.entries)
		this.pages = []
		if (this.pagination) {
			for (let i = 1; i <= pagesAmount; i++) {
				const pageEndIndex = i * this.entries
				this.pages.push(this.filteredRows.slice(pageEndIndex - this.entries, pageEndIndex))
			}
		} else {
			this.pages.push(this.filteredRows)
		}
		this.activePage = 0

		// initial sorting
		if (this.order) {
			this.sort(this.columns[this.order[0]].field, this.order[1])
		}

		this.$emit('pages', this.pages, this.activePage)
		this.$emit('fields', this.columns)
	},
	beforeDestroy () {
		if (this.reactive) {
			window.clearInterval(this.interval)
		}
	},
	watch: {
		data (value) {
			if (value.columns) {
				this.setRows(value.rows)
        this.setColumns(value.columns)
        this.setSort()
			}
		},
		rows () {
			this.filterArray()
		},
		entries () {
			// do the split every entry change (changing entries amount)
			const pagesAmount = Math.ceil(this.filteredRows.length / this.entries)
			this.pages = []
			for (let i = 1; i <= pagesAmount; i++) {
				const pageEndIndex = i * this.entries
				this.pages.push(this.filteredRows.slice(pageEndIndex - this.entries, pageEndIndex))
			}
			this.activePage = this.activePage < this.pages.length ? this.activePage : this.pages.length - 1

			this.$emit('pages', this.pages, this.activePage)
		},
		filteredRows () {
			// do the split on every change in rows (searching)
			const pagesAmount = Math.ceil(this.filteredRows.length / this.entries)
			this.pages = []
			if (this.pagination) {
				for (let i = 1; i <= pagesAmount; i++) {
					const pageEndIndex = i * this.entries
					this.pages.push(this.filteredRows.slice(pageEndIndex - this.entries, pageEndIndex))
				}
			} else {
				this.pages.push(this.filteredRows)
			}

			// update activePage if not caused by data reload
			if (this.reactiveFlag === false) {
				this.activePage = 0
			}

			this.$emit('pages', this.pages, this.activePage)
		}
	}
}
</script>

<style scoped>

</style>

<style>
.pagination {
	margin-bottom: 0
}
.refresh-button {
	width: 4rem;
	height: 2rem;
}
.dataTables-scrollBody {
	display: block;
	overflow-y: auto;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

.dataTables-scrollBody td,
.dataTables-scrollBody th {
	white-space: nowrap;
}
</style>
