<template>
	<svg xmlns="http://www.w3.org/2000/svg" ref="svg" class="net-svg" :width="size.w" :height="size.h"
       @mousedown.prevent="dragstart" @touchstart.prevent="dragstart" @mousemove="move" @touchmove="move"
       @wheel.ctrl.prevent="wheel"
       @mouseup.prevent="dragend" @touchend.passive="dragend" @mouseleave.passive="dragend">
		<g class="links" id="l-links">
			<path v-for="link in d3.links" :key="link.id" :d="linkPath(link)" :id="link.id" v-bind="linkAttrs(link)" :class="linkClass(link.id)" :style="linkStyle(link)"
				@mousedown.prevent @touchstart.prevent @click.prevent="$emit('link-click', link)" @touchend.prevent="$emit('link-click', link)"></path>
		</g>
		<g>
			<circle :cx="windowCenter.x" :cy="windowCenter.y" :r="hubSize / 4 + 1"></circle>
			<circle :cx="center.x" :cy="center.y" :r="hubSize / 4 - 1" style="fill: red"></circle>
		</g>
		<g class="nodes" id="l-nodes">
      <graph-node v-for="(node, nodeKey) in d3.nodes" :key="node.id" :entity-size="entitySize" :hub-size="hubSize"
                  :node-sym="nodeSym" v-bind="node" @dragstart="dragstart($event, nodeKey)" @menu="$emit('menu', d3.nodes[nodeKey])"/>
		</g>
		<g class="labels" id="node-labels">
			<text v-for="node in d3.nodes" :key="node.id" class="node-label" :class="node._cssClass || ''" :font-size="fontSize"
            :stroke-width="fontSize / 8" :x="node.x + (nodeSize(node) / 2) + (fontSize / 2)" :y="node.y + labelOffset.y">
        {{ node.name }}
			</text>
		</g>
	</svg>
</template>

<script>
import { forceSimulation } from 'd3-force'
import { fixEntityNodesAfterHavingAppearedForce, fixHubNodesForce, ignoreHubNodesCollideForce,
  ignoreHubNodesLinkForce, ignoreHubNodesManyBodyForce, ignoreHubNodesRadialForce } from '@/helpers/graph'
import { mapState, mapMutations } from 'vuex'
import { arrayEquals } from '@/helpers/javascript'
import saveImage from '@/helpers/saveImage'
import svgExport from '@/helpers/svgExport'
import GraphNode from './GraphNode'

export default {
	name: 'GraphRender',
  components: { GraphNode },
	props: {
    data: {
      nodes: Array,
      links: Array,
    },
		linksSelected: {
			type: Object,
			default: () => ({})
		},
		nodeSym: String
	},
	data () {
		return {
      d3: {
        nodes: [],
        links: []
      },
			isDragged: false,
			isDragging: false,
			draggingElement: null,
			simulation: null,
			mouseOffset: { x: 0, y: 0 },

			force: 500,
			baseLinkWidth: 6,
			baseHubSize: 10,
			baseEntitySize: 50,
			baseFontSize: 16
		}
	},
	created () {
    this.buildD3(this.data)
	},
	async mounted () {
		await this.$nextTick()
    this.animate()
	},
	computed: {
		entitySize () {
			return this.baseEntitySize * this.zoom
		},
		hubSize () {
			return this.baseHubSize * this.zoom
		},
		fontSize () {
			return this.baseFontSize * this.zoom
		},
    linkWidth() {
      return this.baseLinkWidth * this.zoom
    },
		forceCenter () {
			let selectedNode = this.d3.nodes.filter((n) => n.id === this.selectedNodeId)[0]
			if (selectedNode) {
				return { x: selectedNode.fx, y: selectedNode.fy }
			} else {
				return this.windowCenter
			}
		},
		windowCenter () {
			return { x: this.frameSize.w / 2 - this.margin.l, y: this.frameSize.h / 2 - this.margin.t }
		},
		center () {
			return {
				x: this.size.w / 2 + this.offset.x,
				y: this.size.h / 2 + this.offset.y
			}
		},
		labelOffset () {
			return {
				x: (this.entitySize / 2) + (this.fontSize / 2),
				y: this.fontSize / 2
			}
		},
		nodeSvgEl () {
			return svgExport.svgElFromString(this.nodeSym)
		},
		nodeSvg () {
			return svgExport.toObject(this.nodeSvgEl)
		},
		radialForce () {
			return { radius: Math.min(this.frameSize.w, this.frameSize.h) / 2, strength: 0.9 }
		},
		...mapState('graph', [ 'size', 'frameSize', 'zoom', 'margin', 'offset', 'opened', 'selectedNodeId', 'graphEntities' ]),
		...mapState('schema', [ 'entityDefinitions' ])
	},
	watch: {
    data (newValue) {
      if (this.buildD3(newValue)) {
        this.reset()
      }
    },
		frameSize (newValue, oldValue) {
			if (newValue.w !== oldValue.w || newValue.h !== oldValue.h) {
				this.resize()
			}
		},
		margin (newValue) {
			if (newValue.t || newValue.t === 0) {
				this.$el.style['margin-top'] = newValue.t + 'px'
			}
			if (newValue.l || newValue.l === 0) {
				this.$el.style['margin-left'] = newValue.l + 'px'
			}
		},
		zoom (newValue, oldValue) {
			if (newValue !== oldValue) {
				this.resize(newValue / oldValue)
			}
		}
	},
	methods: {
    nodeSize (node, side) {
			let size = node._size
			if (side) size = node['_' + side] || size
			return size || (node.hostId ? this.hubSize : this.entitySize)
		},
		linkClass (linkId) {
			let cssClass = ['link']
			if (linkId in this.linksSelected) {
				cssClass.push('selected')
			}
			return cssClass
		},
		linkPath (link) {
			let d = {
				M: [link.source.x || 0, link.source.y || 0],
				X: [link.target.x || 0, link.target.y || 0]
			}
			return 'M ' + d.M.join(' ') + ' L' + d.X.join(' ')
		},
    linkStyle (link) {
			let style = {}
			if (link._color) style.stroke = link._color
			return style
		},
		linkAttrs (link) {
			let attrs = link._svgAttrs || {}
			attrs['stroke-width'] = attrs['stroke-width'] || this.linkWidth
			return attrs
		},
    buildD3 (newValue) {
      // let oldValue = this.simulation && { nodes: this.simulation.nodes(), links: this.simulation.force('link').links() }
      let oldValue = this.d3
      if (oldValue && arrayEquals(newValue.nodes.map(n => n.id), oldValue.nodes.map(n => n.id))
          && arrayEquals(newValue.links.map(l => l.id), oldValue.links.map(l => l.id))) {
        return false
      }
      let nodes = newValue.nodes.map(node => {
        let oldNode = oldValue && oldValue.nodes.findLast(n => n.id === node.id)
        // initialize node coords
        if (oldNode) {
          this.$set(node, 'x', oldNode.x)
          this.$set(node, 'vx', oldNode.vx)
          this.$set(node, 'fx', oldNode.fx)
          this.$set(node, 'y', oldNode.y)
          this.$set(node, 'vy', oldNode.vy)
          this.$set(node, 'fy', oldNode.fy)
        }
        if (!node.x && node.x !== 0) {
          this.$set(node, 'x', this.forceCenter.x)
        }
        if (!node.y && node.y !== 0) {
          this.$set(node, 'y', this.forceCenter.y)
        }
        // node default name, allow string 0 as name
        if (!node.name && node.name !== '0') {
          this.$set(node, 'name', 'node ' + node.nodeId)
        }
        return node
      })
      let links = newValue.links.map(link => {
        let oldLink = oldValue && oldValue.links.findLast(l => l.id === link.id)
        if (oldLink) {
          this.$set(link, 'source', oldLink.source)
          this.$set(link, 'target', oldLink.target)
        }
        // return Object.assign(link, oldLink || {})
        return link
      })
      this.d3 = {nodes, links}
      return true
    },
		// -- Animation
		simulate (nodes, links) {
			let vm = this
			let sim = forceSimulation().stop().alpha(0.5).alphaMin(0.1).nodes(nodes).velocityDecay(0.3)
			let alphaMin = sim.alphaMin()

			sim.force('radial', ignoreHubNodesRadialForce(vm.radialForce.radius, vm.windowCenter.x, vm.windowCenter.y).strength(vm.radialForce.strength))
			sim.force('charge', ignoreHubNodesManyBodyForce().strength(-vm.force))
			sim.force('link', ignoreHubNodesLinkForce(links).id((d) => d.id).distance(4 * vm.entitySize))
			sim.force('fixHubs', fixHubNodesForce(vm.entitySize, vm.hubSize))
			sim.force('entitiesCollide', ignoreHubNodesCollideForce(vm.entitySize))
			sim.force('fixEntities', fixEntityNodesAfterHavingAppearedForce(alphaMin * 4.5, vm.exposeNodes))
			return sim
		},
		exposeNodes (nodes) {
			let vm = this
			vm.$emit('nodes', nodes)
		},
		animate (bomb) {
			if (this.simulation) this.simulation.stop()
      if (bomb) {
        this.d3.nodes.forEach(node => {
          this.$delete(node, 'x')
          this.$delete(node, 'y')
          this.$delete(node, 'fx')
          this.$delete(node, 'fy')
        })
      }
			this.simulation = this.simulate(this.d3.nodes, this.d3.links)
			this.simulation.restart()
		},
		reset () {
			this.animate()
      this.d3 = { nodes: this.simulation.nodes(), links: this.simulation.force('link').links() }
		},
		setMouseOffset (event, node) {
			let x = 0
			let y = 0
			if (event) {
				let pos = this.clientPos(event)
				x = pos.x
				y = pos.y
				if (node) {
					x = (x) ? x - node.x : node.x
					y = (y) ? y - node.y : node.y
				}
			}
			this.mouseOffset = { x, y }
		},
		clientPos (event) {
			let x = (event.touches) ? event.touches[0].clientX : event.clientX
			let y = (event.touches) ? event.touches[0].clientY : event.clientY
			x = x || 0
			y = y || 0
			return { x, y }
		},
		move (event) {
			let vm = this
			if (vm.isDragging) {
				let pos = vm.clientPos(event)
				if (vm.draggingElement !== null) {
					if (!vm.d3.nodes[vm.draggingElement].hostId) {
						vm.simulation.restart()
						vm.simulation.alpha(0.5)
						vm.d3.nodes[vm.draggingElement].fx = pos.x - vm.mouseOffset.x
						vm.d3.nodes[vm.draggingElement].fy = pos.y - vm.mouseOffset.y
					}
					vm.isDragged = true
				} else {
					let newMl = vm.margin.l + pos.x - vm.mouseOffset.x
					let newMt = vm.margin.t + pos.y - vm.mouseOffset.y
					let deltaX
					let deltaY
					if (newMl > 0) {
						deltaX = newMl
						newMl = 0
					} else {
						deltaX = Math.min(vm.size.w + newMl - vm.frameSize.w, 0)
					}
					if (newMt > 0) {
						deltaY = newMt
						newMt = 0
					} else {
						deltaY = Math.min(vm.size.h + newMt - vm.frameSize.h, 0)
					}
          vm.SET_SIZE({ w: vm.size.w + Math.abs(deltaX), h: vm.size.h + Math.abs(deltaY) })
					vm.SET_OFFSET({ x: vm.offset.x + deltaX / 2, y: vm.offset.y + deltaY / 2 })
					vm.SET_MARGIN({ l: newMl, t: newMt })
					vm.translate(deltaX > 0 ? (x) => x + deltaX : null, deltaY > 0 ? (y) => y + deltaY : null)
					vm.setMouseOffset(event)
				}
			}
		},
		dragstart (event, nodeKey = null) {
			if (event.which === 1) {
				this.isDragging = true
				this.draggingElement = nodeKey
				this.setMouseOffset(event, this.d3.nodes[nodeKey])
			}
		},
		dragend (event) {
			if (event.which === 1 && this.isDragging) {
				this.isDragging = false
				if (this.isDragged) {
					this.isDragged = false
				} else {
					let node = this.d3.nodes[this.draggingElement]
					if (node) {
						this.$emit('node-click', node)
					}
				}
				this.draggingElement = null
			}
		},
		translate (x = null, y = null) {
			this.d3.nodes.forEach(node => {
				if (x !== null) {
					if (node.x || node.x === 0) {
						this.$set(node, 'x', x(node.x))
					}
					if (node.fx || node.fx === 0) {
						this.$set(node, 'fx', x(node.fx))
					}
				}
				if (y !== null) {
					if (node.y || node.y === 0) {
						this.$set(node, 'y', y(node.y))
					}
					if (node.fy || node.fy === 0) {
						this.$set(node, 'fy', y(node.fy))
					}
				}
			})
		},
		wheel (event) {
			if (event.deltaY > 0) {
				this.$emit('zoom-out')
			} else {
				this.$emit('zoom-in')
			}
		},
		// -- Render helpers
		screenShot (name, bgColor, toSVG, svgAllCss) {
			let args = []
			args = [ toSVG, bgColor, svgAllCss ]
			if (toSVG) name = name || 'export.svg'

			this.innerScreenShot((err, url) => {
				if (!err) {
					if (!toSVG) saveImage.save(url, name)
					else saveImage.download(url, name)
				}
				this.$emit('screen-shot', err)
			}, ...args)
		},
		innerScreenShot (cb, toSvg, background, allCss) {
			let svg = svgExport.export(this.$refs.svg, allCss)
			if (!toSvg) {
				if (!background) background = this.searchBackground()
				let canvas = svgExport.makeCanvas(this.size.w, this.size.h, background)
				svgExport.svgToImg(svg, canvas, (err, img) => {
					if (err) cb(err)
					else cb(null, img)
				})
			} else {
				cb(null, svgExport.save(svg))
			}
		},
		searchBackground () {
			let vm = this
			while (vm.$parent) {
				let style = window.getComputedStyle(vm.$el)
				let background = style.getPropertyValue('background-color')
				let rgb = background.replace(/[^\d,]/g, '').split(',')
				let sum = rgb.reduce((a, b) => a + b, 0)
				if (sum > 0) return background
				vm = vm.$parent
			}
			return 'white'
		},
		resize (zoom = 1) {
			let oldWindowCenter = this.windowCenter
			let oldSize = this.size

			let leftPadding = Math.max(oldWindowCenter.x * zoom, this.frameSize.w / 2)
			let topPadding = Math.max(oldWindowCenter.y * zoom, this.frameSize.h / 2)
			let rightPadding = Math.max(oldSize.w - oldWindowCenter.x * zoom, this.frameSize.w / 2)
			let bottomPadding = Math.max(oldSize.h - oldWindowCenter.y * zoom, this.frameSize.h / 2)

			let newSize = { w: leftPadding + rightPadding, h: topPadding + bottomPadding }
			this.SET_MARGIN({ l: this.frameSize.w / 2 - leftPadding, t: this.frameSize.h / 2 - topPadding })
			this.SET_OFFSET({
				x: this.windowCenter.x + (this.center.x - oldWindowCenter.x) * zoom - newSize.w / 2,
				y: this.windowCenter.y + (this.center.y - oldWindowCenter.y) * zoom - newSize.h / 2
			})
			this.translate(
				(x) => this.windowCenter.x + (x - oldWindowCenter.x) * zoom,
				(y) => this.windowCenter.y + (y - oldWindowCenter.y) * zoom
			)
			this.SET_SIZE(newSize)
			this.animate()
		},
		flush () {
			this.d3.nodes.forEach((n) => { n.x = n.y = n.fx = n.fy = n.vx = n.vy = 0 })
		},
		...mapMutations('graph', [ 'SET_MARGIN', 'SET_OFFSET', 'SET_SIZE' ])
	}
}
</script>

<style scoped>
svg.net-svg {
	background: white;
}
</style>
