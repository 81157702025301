<template>
  <mdb-row class="justify-content-center bg-white">
    <edit-table :columns="columns" :data="data" @update="update" @delete="deleteEntity" @sync="fetchEntities"></edit-table>
  </mdb-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import EditTable from '@/components/common/EditTable'

export default {
  name: 'EntitiesIndex',
  components: { EditTable },
  async mounted () {
    await this.fetchEntities()
  },
  computed: {
    columns () {
      return [
        {
          label: 'Name',
          field: 'name',
          id: true,
          sort: 'asc'
        },
        {
          label: 'Type',
          field: 'type'
        },
        {
          label: 'Label',
          field: 'label'
        },
        {
          label: 'Icon',
          field: 'icon'
        },
        {
          label: 'Color',
          field: 'color'
        }
      ]
    },
    data () {
      return Object.values(this.entityDefinitions)
    },
    ...mapState('schema', [ 'entityDefinitions' ])
  },
  methods: {
    update (entity) {
      if (this.isNew(entity)) {
        this.createEntity(entity)
      } else {
        this.updateEntity(entity)
      }
    },
    isNew (entity) {
      console.log({ entity })
      return false
    },
    ...mapActions('schema', [ 'fetchEntities', 'createEntity', 'updateEntity', 'deleteEntity' ])
  },
  watch: {
    async $route () {
      await this.fetchEntities()
    }
  }
}
</script>

<style scoped>

</style>
