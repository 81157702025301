export default function (hostSize, hubSize) {
	let nodes = []
	let l_ = (hostSize + hubSize) / 2
	let alphas = {}
	function f () {
		if (nodes) {
			let hostId = nodes.filter((n) => n.hostId).map((n) => n.hostId)[0]
			if (hostId) {
				let host = nodes.filter((n) => n.id === hostId)[0]
				let hostX = host.fx || host.x
				let hostY = host.fy || host.y
				let hubs = nodes.filter((n) => n.hostId === hostId)
				if (Object.keys(alphas).length !== hubs.length) {
					let delta = 2 * Math.PI / hubs.length
					hubs.forEach((hub, i) => {
						alphas[hub.id] = delta * i + Math.PI / 6.
					})
				}
				hubs.forEach((hub) => {
					hub.x = hostX + l_ * Math.cos(alphas[hub.id])
					hub.y = hostY - l_ * Math.sin(alphas[hub.id])
				})
			}
		}
	}
	f.initialize = function (_) { nodes = _ }
	return f
}
