import Vue from 'vue'

import { isOpened, neighbors, subgraphByNodeId } from '@/helpers/graph'
import { _call, fromEntries, intKeyedEntries, intKeys } from '@/helpers/javascript'

import call from '@/helpers/call'

// initial state
const state = {
	usedDefinitions: {},
	graphEntities: {},
	links: {},
	opened: {},
	filterId: null,
	filterValue: null,
	selectedNodeId: null,
	selectedLinkId: null,
	rows: [],
	zoom: 1,
	margin: { l: 0, t: 0 },
	offset: { x: 0, y: 0 },
	size: { w: 500, h: 500 },
	frameSize: { w: 500, h: 500 }
}

const getNodeId = (state, id) => {
	let existingNode = intKeyedEntries(state.graphEntities).findLast(e => e[1].id === id)
	if (existingNode) {
		return existingNode[0]
	} else {
		return Object.keys(state.graphEntities).length ? Math.max(...intKeys(state.graphEntities)) + 1 : 1
	}
}

// getters
const getters = {
	usedDefinitions (state, getters, rootState) {
		return fromEntries(intKeyedEntries(state.usedDefinitions)
			.filter(d => d[1] in rootState.schema.entityDefinitions)
			.map(d => [ d[0], Object.assign(
				{}, rootState.schema.entityDefinitions[d[1]], { id: d[0] }) ])) || {}
	},
	allDefinitions (state, getters, rootState) {
		return Object.values(rootState.schema.entityDefinitions).filter(d => d.type === 'String')
	}
}

// actions
const actions = {
	fetchDefinitions ({ commit }) {
		call('graph', 'definitions', {
			resolve (data) {
				commit('SET_DEFINITIONS', data)
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		})
	},
	addDefinitions ({ commit }, ids) {
		if (ids) {
			call('graph', 'addDefinitions', {
				resolve(data) {
					commit('ADD_DEFINITIONS', data)
				},
				reject(error) {
					commit('ADD_ERROR', error, {root: true})
				}
			}, ids)
		}
	},
	removeDefinitions ({ commit, state }, ids) {
		if (ids) {
			call('graph', 'removeDefinitions', {
				resolve(data) {
					let deleted = Object.values(state.graphEntities).filter(d => data.includes(state.usedDefinitions[d.definitionId])).map(d => d.nodeId)
					deleted.forEach(id => commit('REMOVE_ENTITY', id))
					commit('REMOVE_DEFINITIONS', data)
				},
				reject(error) {
					commit('ADD_ERROR', error, {root: true})
				}
			}, ids)
		}
	},
	init ({ commit, dispatch, state }, clear) {
		let definitionName = state.filterId && state.usedDefinitions[state.filterId]
		call('graph', 'init', {
			resolve (data) {
				if (data._) {
					commit('CLEAR_GRAPH')
					_call(clear)
					dispatch('addEntity', Object.assign(data._, { definitionName }))
				} else {
					alert('Ничего не найдено')
				}
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, { definitionName, value: state.filterValue })
	},
	openHub ({ commit, dispatch, state }, hub) {
		let sourceNodeId = hub.hostId
		let sourceNode = state.graphEntities[sourceNodeId]
		let sourceId = sourceNode && sourceNode.id
		let targetName =  state.usedDefinitions[hub.typeId]
		if (sourceId && targetName) {
			call('graph', 'neighbors', {
				resolve(data) {
					data.forEach(item => {
						dispatch('addLink', { hub, item })
					})
					commit('SET_HUB_STATUS', {hub, status: true})
				},
				reject(error) {
					commit('ADD_ERROR', error, {root: true})
				}
			}, {sourceId, targetName})
		}
	},
	addLink ({ commit, state }, { hub, item }) {
		let id = item.id
		let definitionName = state.usedDefinitions[hub.typeId]
		let value = item.value
		let nodeId = getNodeId(state, id)
		commit('lists/SET_ENTITY', { id, definitionName, value }, { root: true })
		commit('ADD_ENTITY', { id, nodeId, definitionName, value })
		commit('ADD_LINK', {host1Id: hub.hostId, host2Id: nodeId, txIds: item.txIds})
	},
	addEntity ({ commit, state }, { id, definitionName, value }) {
		let nodeId = getNodeId(state, id)
		commit('lists/SET_ENTITY', { id, definitionName, value }, { root: true })
		commit('ADD_ENTITY', { id, nodeId, definitionName, value })
	},
	closeHub ({ commit, state }, hub) {
		let host = state.graphEntities[hub.hostId]
		let ids = intKeys(neighbors(hub.hostId, state)).filter(n => state.graphEntities[n].definitionId === hub.typeId
			&& !isOpened({ hostId: n, definitionId: host.definitionId }, state))
		ids.forEach(id => {
			commit('REMOVE_LINK', { host1Id: hub.hostId, host2Id: id })
			if (!Object.keys(neighbors(id, state)).length) { // других связей у данного соседа не осталось
				commit('REMOVE_ENTITY', id)
			}
		})
		ids.forEach(id => {
			let subgraph = subgraphByNodeId(id, state)
			if (!subgraph.includes(parseInt(hub.hostId))) {
				subgraph.forEach(n => commit('REMOVE_ENTITY', n))
			}
		})
		commit('SET_HUB_STATUS', { hub, status: false })
	},
	selectNode ({ commit }, entity) {
		commit('SET_SELECTED_NODE_ID', entity.id)
	},
	deselectNode ({ commit }) {
		commit('SET_SELECTED_NODE_ID', null)
	},
	setNodesCoordinates ({ commit }, nodes) {
		nodes.forEach(node => commit('SET_ENTITY_COORDINATES', node))
	},
	selectLink ({ commit }, link) {
		commit('SET_SELECTED_LINK_ID', link.id)
	},
	deselectLink ({ commit }) {
		commit('SET_SELECTED_LINK_ID', null)
	},
	txData ({ commit }, { txIds, properties }) {
		call('graph', 'txData', {
			resolve (data) {
				commit('SET_TX_DATA', data)
			},
			reject (error) {
				commit('ADD_ERROR', error, { root: true })
			}
		}, { txIds, properties })
	}
}

// mutations
const mutations = {
	SET_ZOOM (state, zoom = 1) {
		state.zoom = zoom
	},
	SET_MARGIN (state, { l = 0, t = 0 } = {}) {
		state.margin = { l, t }
	},
	SET_OFFSET (state, { x = 0, y = 0 } = {}) {
		state.offset = { x, y }
	},
	SET_SIZE (state, { w, h }) {
		state.size = { w, h }
	},
	SET_FRAME_SIZE (state, { w, h }) {
		state.frameSize = { w, h }
	},
	SET_DEFINITIONS (state, definitions) {
		state.usedDefinitions = fromEntries([ ...Array(definitions.length).keys() ].map(i => [ i + 1, definitions[i] ]))
	},
	ADD_DEFINITIONS (state, definitions) {
		const i0 = Object.keys(state.usedDefinitions).length ? Math.max(...intKeys(state.usedDefinitions)) + 1 : 1;
		[ ...Array(definitions.length).keys() ].forEach(i => {
			Vue.set(state.usedDefinitions, i0 + i + 1, definitions[i])
		})
	},
	REMOVE_DEFINITIONS (state, definitions) {
		state.usedDefinitions = fromEntries(intKeyedEntries(state.usedDefinitions).filter(x => !definitions.includes(x[1])))
	},
	ADD_ENTITY (state, { id, nodeId, definitionName, value }) {
		if (!(nodeId in state.graphEntities)) {
			let definitionId = intKeys(state.usedDefinitions).findLast(i => state.usedDefinitions[i] === definitionName)
			if (definitionId) {
				Vue.set(state.graphEntities, nodeId, { id, nodeId, definitionId, value })
			} else {
				console.error('Used definition not found', { name: definitionName })
			}
		} else {
			console.error('Trying to add entity with already existing nodeId',
				{ new: { id, nodeId, definitionName, value }, old: state.graphEntities[nodeId] })
		}
	},
	REMOVE_ENTITY (state, hostId) {
		if (state.selectedNodeId === hostId) {
			state.selectedNodeId = null
		}
		state.links = fromEntries(intKeyedEntries(state.links).filter(e => e[0] !== hostId)
			.map(e => [ e[0], fromEntries(intKeyedEntries(e[1]).filter(l => l[0] !== hostId)) ]))
		state.opened = fromEntries(Object.entries(state.opened).filter(o => !(new RegExp('^' + hostId + ',\\d+$')).test(o[0])))
		state.graphEntities = fromEntries(intKeyedEntries(state.graphEntities).filter(e => e[0] !== hostId))
	},
	SET_ENTITY_COORDINATES (state, { id, x, vx, fx, y, vy, fy }) {
		if (state.graphEntities[id]) {
			Vue.set(state.graphEntities, id, Object.assign({}, state.graphEntities[id], { x, vx, fx, y, vy, fy }))
		} else {
			console.error('Trying to set coordinates for non-existent entity node:', { id, x, vx, fx, y, vy, fy })
		}
	},
	ADD_LINK (state, { host1Id, host2Id, txIds }) {
		let lesser = Math.min(host1Id, host2Id)
		let greater = Math.max(host1Id, host2Id)
		Vue.set(state.links, lesser, Object.assign({}, state.links[lesser] || {}, { [greater]: txIds }))
	},
	REMOVE_LINK (state, { host1Id, host2Id }) {
		let lesser = Math.min(host1Id, host2Id)
		let greater = Math.max(host1Id, host2Id)
		let links = state.links[lesser] || {}
		delete links[greater]
		Vue.set(state.links, lesser, links)
	},
	SET_HUB_STATUS (state, { hub, status }) {
		Vue.set(state.opened, hub.id, status)
	},
	CLEAR_GRAPH (state) {
		state.graphEntities = {}
		state.links = {}
		state.opened = {}
		state.selectedNodeId = null
		state.selectedLinkId = null
	},
	SET_FILTER_ID (state, definitionId = null) {
		state.filterId = definitionId
	},
	SET_FILTER_VALUE (state, value) {
		state.filterValue = value
	},
	SET_SELECTED_NODE_ID (state, nodeId) {
		state.selectedNodeId = nodeId
	},
	SET_SELECTED_LINK_ID (state, linkId) {
		state.selectedLinkId = linkId
	},
	SET_TX_DATA (state, data) {
		state.rows = data
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
