import axios from 'axios'

export const api = axios.create({
	baseURL: process.env.VUE_APP_API_URL + '/api',
	withCredentials: true
})

export const auth = axios.create({
	baseURL: process.env.VUE_APP_API_URL + '/auth',
	withCredentials: true
})
